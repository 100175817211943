import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';
import { Tier } from '../Tier';

export class NewBillingFeatureGate extends FeatureGate {
  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.NEW_BILLING;
  }

  protected getFullyRolledOutTiers(): ReadonlySet<Tier> {
    return new Set([]);
  }
}

export function useNewBillingFeatureGate() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.NEW_BILLING] ?? defaultGate;
}
