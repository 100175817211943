// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAllNotificationsReadStateMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateAllNotificationsReadStateMutation = { __typename?: 'RootMutation', websiteNotifications: { __typename?: 'WebsiteNotificationMutation', updateAllWebsiteNotificationReadStateAsRead: boolean } };


export const UpdateAllNotificationsReadStateDocument = gql`
    mutation UpdateAllNotificationsReadState {
  websiteNotifications {
    updateAllWebsiteNotificationReadStateAsRead
  }
}
    `;
export function useUpdateAllNotificationsReadStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAllNotificationsReadStateMutation, UpdateAllNotificationsReadStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAllNotificationsReadStateMutation, UpdateAllNotificationsReadStateMutationVariables>(UpdateAllNotificationsReadStateDocument, options);
      }
export type UpdateAllNotificationsReadStateMutationHookResult = ReturnType<typeof useUpdateAllNotificationsReadStateMutation>;
export type UpdateAllNotificationsReadStateMutationResult = Apollo.MutationResult<UpdateAllNotificationsReadStateMutation>;
export type UpdateAllNotificationsReadStateMutationOptions = Apollo.BaseMutationOptions<UpdateAllNotificationsReadStateMutation, UpdateAllNotificationsReadStateMutationVariables>;