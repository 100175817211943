import { useState, useEffect, FormEvent } from 'react';

import { SecondFactorMethod } from '~/graphql/types.generated';
import { Form, getFormFieldData } from '~/ui/components/form/Form';
import { FormButton } from '~/ui/components/form/FormButton';
import { FormError } from '~/ui/components/form/FormError';
import { FormGroup } from '~/ui/components/form/FormGroup';
import { FormStates } from '~/ui/components/form/FormStates';
import { Input } from '~/ui/components/form/Input';
import { LABEL, CALLOUT, A } from '~/ui/components/text';

import { SMSDevice } from './SMSDevice';
import { PartialUserSecondFactorDevice } from './types';

type RootFormProps = {
  nestedForm?: false | never;
  onSubmit: (otp: string) => Promise<FormStates | void>;
  errorMessage?: never;
};

type NestedFormProps = {
  nestedForm: true;
  onSubmit?: never;
  errorMessage: string;
};

type Props = {
  secondFactorDevices?: PartialUserSecondFactorDevice[];
  sendSMSOTPAsync: (deviceId: string) => Promise<void>;
  title?: string;
  description?: string;
  buttonTitle?: string;
  SMSAutoSendDisabled?: boolean;
} & (RootFormProps | NestedFormProps);

export function OneTimePasswordForm({
  nestedForm = false,
  onSubmit,
  errorMessage,
  secondFactorDevices,
  sendSMSOTPAsync,
  SMSAutoSendDisabled = false,
  description,
  buttonTitle,
}: Props) {
  const [formState, setFormState] = useState(FormStates.IDLE);
  const [inputError, setInputError] = useState('');
  const [formError, setFormError] = useState('');
  const [isEnteringRecoveryCode, setIsEnteringRecoveryCode] = useState(false);

  const primarySMSDevice = secondFactorDevices?.find(
    (secondFactorDevice) =>
      secondFactorDevice.method === SecondFactorMethod.Sms && secondFactorDevice.isPrimary
  );
  const hasAuthenticatorSecondFactorDevice = secondFactorDevices?.some(
    (secondFactorDevice) => secondFactorDevice.method === SecondFactorMethod.Authenticator
  );
  const SMSDevices = secondFactorDevices?.filter(
    (secondFactorDevice) => secondFactorDevice.method === SecondFactorMethod.Sms
  );

  useEffect(function didMount() {
    if (primarySMSDevice?.id && !SMSAutoSendDisabled) {
      void sendSMSOTPAsync(primarySMSDevice.id);
    }
  }, []);

  async function _onSubmitAsync(event: FormEvent) {
    if (nestedForm || !onSubmit) {
      return;
    }

    const { otp } = getFormFieldData(event);

    setInputError('');
    setFormError('');

    if (otp.trim().length === 0) {
      setInputError('You must provide a value for the one-time password.');
      return;
    }

    try {
      setFormState(FormStates.LOADING);
      const result = await onSubmit(otp);

      if (result) {
        setFormState(result);
      }
    } catch (error) {
      setFormState(FormStates.IDLE);
      setFormError((error as Error).message);
    }
  }

  function handleToggleRecovery() {
    setIsEnteringRecoveryCode((val) => !val);
  }

  const content = (
    <>
      <CALLOUT>
        {formatDescription(
          isEnteringRecoveryCode,
          description,
          primarySMSDevice,
          hasAuthenticatorSecondFactorDevice
        )}
      </CALLOUT>
      <FormGroup
        htmlFor="otp"
        title={isEnteringRecoveryCode ? 'Recovery code' : 'One-time password'}>
        <Input
          key="otp"
          id="otp"
          autoComplete="one-time-code"
          error={inputError || errorMessage}
          autoFocus
          inputMode={isEnteringRecoveryCode ? undefined : 'numeric'}
        />
      </FormGroup>
      <FormError error={formError} />
      {!nestedForm && (
        <FormButton block status={formState}>
          {buttonTitle ?? 'Verify'}
        </FormButton>
      )}
      {SMSDevices && SMSDevices?.length > 0 && (
        <div>
          <LABEL>SMS numbers</LABEL>
          {SMSDevices?.map((device) => (
            <SMSDevice
              key={device.id}
              SMSDevice={device}
              sendSMSOTPAsync={sendSMSOTPAsync}
              sentCode={primarySMSDevice?.id === device.id}
            />
          ))}
        </div>
      )}
      {isEnteringRecoveryCode ? (
        <CALLOUT>
          Got your 2FA device?{' '}
          <A isStyled onClick={handleToggleRecovery}>
            Enter your one-time password.
          </A>
        </CALLOUT>
      ) : (
        <CALLOUT>
          Lost access to your 2FA device?{' '}
          <A isStyled onClick={handleToggleRecovery}>
            Enter a recovery code.
          </A>
        </CALLOUT>
      )}
    </>
  );

  return nestedForm ? (
    <div className="flex flex-col gap-4">{content}</div>
  ) : (
    <Form
      className="gap-4"
      onSubmit={_onSubmitAsync}
      disabled={formState !== FormStates.IDLE}
      variant="flat">
      {content}
    </Form>
  );
}

function formatDescription(
  isEnteringRecoveryCode: boolean,
  description?: string,
  primarySMSDevice?: PartialUserSecondFactorDevice,
  hasAuthenticatorSecondFactorDevice?: boolean
) {
  if (description) {
    return description;
  }

  if (isEnteringRecoveryCode) {
    return 'Enter your recovery code from when you set up two-factor authentication.';
  }

  if (primarySMSDevice) {
    return `Check your SMS messages for your one-time password. It may take a few moments to arrive. ${
      hasAuthenticatorSecondFactorDevice
        ? 'You may also use a one-time password from your authenticator app.'
        : ''
    }`;
  }
  return 'Open your two-factor authentication app to view your one-time password.';
}
