import invariant from 'invariant';
import cookies from 'js-cookie';

type StorageBackend = {
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
  setItem: (key: string, value: string, options?: any) => void;
};

export default class StorageHandler {
  _storageBackend: StorageBackend | null = null;
  _namespace: string;
  _keySeparator: string;
  _initialObject: Record<string, string | undefined>;

  constructor(
    namespace: string,
    storageBackend: string,
    keySeparator: string = '.',
    initialObject: Record<string, string | undefined> = {}
  ) {
    this._namespace = namespace;
    this._keySeparator = keySeparator;
    this._initialObject = initialObject;

    invariant(
      !(process.env.DEPLOYMENT_ENVIRONMENT === 'staging' && !namespace.startsWith('staging.')),
      'Cookies on staging must be prefixed with "staging.", to prevent overwriting production cookies'
    );

    try {
      switch (storageBackend) {
        case 'localstorage':
          this._storageBackend = window.localStorage;
          break;
        case 'cookie':
          this._storageBackend = new CookieStorage();
          break;
        default:
          this._storageBackend = new DummyStorage();
          break;
      }
    } catch {
      this._storageBackend = new DummyStorage();
    }
  }

  getItem(key: string) {
    try {
      return (
        this.storageBackend.getItem(this._getKey(key)) ?? this._initialObject[this._getKey(key)]
      );
    } catch {
      this._failover();
      return this.storageBackend.getItem(this._getKey(key));
    }
  }

  removeItem(key: string) {
    try {
      this.storageBackend.removeItem(this._getKey(key));
    } catch {
      this._failover();
      this.storageBackend.removeItem(this._getKey(key));
    }
  }

  setItem(key: string, value: string, options?: object) {
    try {
      this.storageBackend.setItem(this._getKey(key), value, options);
    } catch {
      this._failover();
      this.storageBackend.setItem(this._getKey(key), value, options);
    }
  }

  _getKey(key: string) {
    const prefix = `${this._namespace}${this._keySeparator}`;
    return key.startsWith(prefix) ? key : `${prefix}${key}`;
  }

  _failover() {
    if (this.storageBackend instanceof DummyStorage) {
      console.warn('DummyStorage: ignore failover');
    } else if (this.storageBackend instanceof CookieStorage) {
      console.warn('CookieStorage: failing over DummyStorage');
      this.storageBackend = new DummyStorage();
    } else {
      console.warn('LocalStorage: failing over CookieStorage');
      this.storageBackend = new CookieStorage();
    }
  }

  get storageBackend(): StorageBackend {
    if (this._storageBackend) {
      return this._storageBackend;
    }

    let storageBackend: StorageBackend;
    if (typeof window !== 'undefined') {
      storageBackend = window.localStorage || new CookieStorage();
    } else {
      storageBackend = new DummyStorage();
    }

    this._storageBackend = storageBackend;
    return this._storageBackend;
  }

  set storageBackend(storageBackend: StorageBackend) {
    this._storageBackend = storageBackend;
  }
}

class CookieStorage implements StorageBackend {
  getDomain() {
    const hostname = location.hostname;
    const domainRegex = /^((pr-\d+|staging)\.expo\.|expo\.)(test|dev)$/;

    if (domainRegex.test(hostname)) {
      // Store cookies from staging.expo.dev and website PR preview deployments on the top-level
      // expo.dev domain, otherwise they are not accessible by other sub-domains (e.g.
      // staging-snack.expo.dev).
      return hostname.replace(/^(pr-\d+|staging)\./, '');
    }

    return undefined;
  }

  getItem(key: string) {
    return cookies.get(key) ?? null;
  }

  removeItem(key: string) {
    const domain = this.getDomain();

    cookies.remove(key, {
      ...(domain ? { domain } : {}),
    });

    // We used to set cookies without a domain name. Browsers sometimes look at the domain-less cookie instead of the newer one, therefore we must remove the cookie without a domain name as well
    cookies.remove(key);
  }

  setItem(key: string, value: string, options?: object) {
    const domain = this.getDomain();

    const sameSiteConfig: cookies.CookieAttributes = {
      sameSite: 'lax',
      secure: process.env.DEPLOYMENT_ENVIRONMENT === 'development' ? undefined : true,
    };

    return cookies.set(key, value, {
      ...(domain ? { domain } : {}),
      ...sameSiteConfig,
      ...options,
    });
  }
}

class DummyStorage implements StorageBackend {
  _storage: Record<string, string> = {};

  getItem(key: string) {
    return this._storage[key];
  }

  removeItem(key: string) {
    delete this._storage[key];
  }

  setItem(key: string, value: string) {
    this._storage[key] = value;
  }
}
