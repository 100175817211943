// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type UserAccountsData_SsoUser_Fragment = { __typename?: 'SSOUser', username: string, id: string, profilePhoto: string, firstName?: string | null, lastName?: string | null, accounts: Array<{ __typename?: 'Account', id: string, name: string, ownerUserActor?: { __typename?: 'SSOUser', id: string, profilePhoto: string, fullName?: string | null } | { __typename?: 'User', id: string, profilePhoto: string, fullName?: string | null } | null }> };

export type UserAccountsData_User_Fragment = { __typename?: 'User', username: string, id: string, profilePhoto: string, firstName?: string | null, lastName?: string | null, accounts: Array<{ __typename?: 'Account', id: string, name: string, ownerUserActor?: { __typename?: 'SSOUser', id: string, profilePhoto: string, fullName?: string | null } | { __typename?: 'User', id: string, profilePhoto: string, fullName?: string | null } | null }> };

export type UserAccountsDataFragment = UserAccountsData_SsoUser_Fragment | UserAccountsData_User_Fragment;

export const UserAccountsDataFragmentDoc = gql`
    fragment UserAccountsData on UserActor {
  username
  id
  profilePhoto
  firstName
  lastName
  accounts {
    id
    name
    ownerUserActor {
      id
      profilePhoto
      fullName
    }
  }
}
    `;