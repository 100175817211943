import { useRouter } from 'next/compat/router';

import { useCurrentUserQuery } from '~/graphql/queries/CurrentUserQuery.query.generated';
import { PageProps } from '~/scenes/_app/helpers';

import { BarebonesNavigation } from './BarebonesNavigation';
import { LoggedInNavigation } from './LoggedInNavigation';
import { LoggedOutNavigation } from './LoggedOutNavigation';

type Props = {
  currentUser: PageProps['currentUser'];
  currentDateAsString: string;
  pathname?: string;
  isServerSideRendered?: boolean;
};

export function Navigation({
  currentUser: _currentUser,
  currentDateAsString,
  pathname: _pathname,
  isServerSideRendered,
}: Props) {
  const router = useRouter();
  const pathname = _pathname ?? router?.pathname ?? '';

  const { data, loading } = useCurrentUserQuery({ skip: isServerSideRendered });
  const currentUser = _currentUser ?? data?.meUserActor;

  if (['/onboarding/hello'].includes(pathname)) {
    return null;
  }

  if (isServerSideRendered && !currentUser) {
    return <LoggedOutNavigation currentDateAsString={currentDateAsString} />;
  }

  if (!isServerSideRendered && !currentUser) {
    // note(Juwan): prevent the logged out navigation from flashing on the screen
    // show a bare bones navigation while loading
    return loading ? (
      <BarebonesNavigation />
    ) : (
      <LoggedOutNavigation currentDateAsString={currentDateAsString} />
    );
  }

  return (
    <LoggedInNavigation currentUser={currentUser!} currentDateAsString={currentDateAsString} />
  );
}
