import { mergeClasses, Themes, useTheme } from '@expo/styleguide';
import Head from 'next/head';
import { PropsWithChildren } from 'react';

import * as Metatags from '~/common/metatags';
import { NewsletterMetadata, PostMetadata } from '~/common/metatags';
import { getAssetUrl } from '~/common/utilities';
import { useColorScheme } from '~/providers/useColorScheme';
import { ProfileUser } from '~/scenes/ProfileScene/types';
import { PopoverFeedback } from '~/ui/components/PopoverFeedback';
import { TooltipProvider } from '~/ui/components/Tooltip/TooltipProvider';

const FAVICONS_PATH = '/static/favicons';

const shouldNotIndexDeployment = ['staging', 'pull-request'].includes(
  process.env.DEPLOYMENT_ENVIRONMENT ?? ''
);

type Props = PropsWithChildren<{
  meta: {
    name: keyof typeof Metatags.Copy;
    changelogMetadata?: PostMetadata;
    newsletterMetadata?: NewsletterMetadata;
    pageName?: string;
    user?: ProfileUser;
    project?: Metatags.ProjectMeta;
  };
  disableGraphQL?: boolean;
  className?: string;
}>;

export default function Document({ meta, disableGraphQL, children, className }: Props) {
  const { themeName } = useTheme();
  const colorScheme = useColorScheme();

  const metaResource = Metatags.Copy[meta.name];
  const ContextMetatags = metaResource ? metaResource(meta ?? {}) : Metatags.Copy.default();

  const shouldNotIndexPage = (
    [
      'dashboard',
      'settings',
      'accountSettings',
      'insights',
      'invitation',
      'build',
      'builds',
      'billing',
      'registerDevice',
      'createOrganization',
      'graphiql',
      'internal',
      'profileindex',
      'profilesnacks',
      'project',
      'error',
    ] as Metatags.PagesWithMetatags[]
  ).includes(meta.name as Metatags.PagesWithMetatags);

  const metaThemeColor = getMetaThemeColor(themeName);

  return (
    <div className={mergeClasses('min-h-dvh bg-default', className)}>
      {/* All contents must be inline. next/head doesn't register custom React components. */}
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="sourceApp" content="mobileWeb" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        {ContextMetatags.title}
        {ContextMetatags.twitterTitle}
        {ContextMetatags.facebookTitle}
        {ContextMetatags.description}
        {ContextMetatags.twitterDescription}
        {ContextMetatags.facebookDescription}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@expo" />
        {metaThemeColor}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Expo" />
        <link
          rel="icon"
          type="image/png"
          href={getAssetUrl(`${FAVICONS_PATH}/favicon-${colorScheme}-48x48.png`)}
        />
        <link
          rel="icon"
          href={getAssetUrl(`${FAVICONS_PATH}/favicon-${colorScheme}-32x32.png`)}
          sizes="32x32"
          type="image/png"
        />
        <link
          rel="icon"
          href={getAssetUrl(`${FAVICONS_PATH}/favicon-${colorScheme}-16x16.png`)}
          sizes="16x16"
          type="image/png"
        />
        {ContextMetatags.appIcon}
        {ContextMetatags.image}
        {ContextMetatags.imageURL}
        {ContextMetatags.imageSecureURL}
        {ContextMetatags.twitterImage}
        {ContextMetatags.msImage}
        {ContextMetatags?.author}
        {ContextMetatags?.date}
        {shouldNotIndexDeployment && <meta name="robots" content="noindex, nofollow" />}
        {!shouldNotIndexDeployment && shouldNotIndexPage && (
          <meta name="robots" content="noindex" />
        )}
      </Head>
      {!disableGraphQL && <PopoverFeedback />}
      <TooltipProvider>{children}</TooltipProvider>
    </div>
  );
}

export function getMetaThemeColor(themeName: Themes) {
  if (themeName === 'dark') {
    return <meta name="theme-color" content="#151718" />;
  } else if (themeName === 'light') {
    return <meta name="theme-color" content="#fff" />;
  } else if (themeName === 'auto') {
    return (
      <>
        <meta name="theme-color" media="(prefers-color-scheme: light)" content="#fff" />
        <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#151718" />
      </>
    );
  } else {
    return null;
  }
}
