import { SnackLogo } from '@expo/styleguide';
import { BranchIcon } from '@expo/styleguide-icons/custom/BranchIcon';
import { BuildIcon } from '@expo/styleguide-icons/custom/BuildIcon';
import { CredentialIcon } from '@expo/styleguide-icons/custom/CredentialIcon';
import { EasSubmitIcon } from '@expo/styleguide-icons/custom/EasSubmitIcon';
import { GithubIcon } from '@expo/styleguide-icons/custom/GithubIcon';
import { ReceiptIcon } from '@expo/styleguide-icons/custom/ReceiptIcon';
import { Smartphone01Icon } from '@expo/styleguide-icons/custom/Smartphone01Icon';
import { Smartphone02Icon } from '@expo/styleguide-icons/custom/Smartphone02Icon';
import { AlignHorizontalCentre02Icon } from '@expo/styleguide-icons/outline/AlignHorizontalCentre02Icon';
import { ArrowLeftIcon } from '@expo/styleguide-icons/outline/ArrowLeftIcon';
import { Bell03Icon } from '@expo/styleguide-icons/outline/Bell03Icon';
import { BracketsXIcon } from '@expo/styleguide-icons/outline/BracketsXIcon';
import { Cloud01Icon } from '@expo/styleguide-icons/outline/Cloud01Icon';
import { Coins01Icon } from '@expo/styleguide-icons/outline/Coins01Icon';
import { CreditCard02Icon } from '@expo/styleguide-icons/outline/CreditCard02Icon';
import { Cube02Icon } from '@expo/styleguide-icons/outline/Cube02Icon';
import { DataIcon } from '@expo/styleguide-icons/outline/DataIcon';
import { Dataflow01Icon } from '@expo/styleguide-icons/outline/Dataflow01Icon';
import { Dataflow03Icon } from '@expo/styleguide-icons/outline/Dataflow03Icon';
import { FileSearch02Icon } from '@expo/styleguide-icons/outline/FileSearch02Icon';
import { FolderIcon } from '@expo/styleguide-icons/outline/FolderIcon';
import { Grid01Icon } from '@expo/styleguide-icons/outline/Grid01Icon';
import { Key01Icon } from '@expo/styleguide-icons/outline/Key01Icon';
import { LayersTwo02Icon } from '@expo/styleguide-icons/outline/LayersTwo02Icon';
import { LayoutAlt02Icon } from '@expo/styleguide-icons/outline/LayoutAlt02Icon';
import { LineChartUp02Icon } from '@expo/styleguide-icons/outline/LineChartUp02Icon';
import { ListIcon } from '@expo/styleguide-icons/outline/ListIcon';
import { MessageXSquareIcon } from '@expo/styleguide-icons/outline/MessageXSquareIcon';
import { NotificationBoxIcon } from '@expo/styleguide-icons/outline/NotificationBoxIcon';
import { PencilLineIcon } from '@expo/styleguide-icons/outline/PencilLineIcon';
import { Settings02Icon } from '@expo/styleguide-icons/outline/Settings02Icon';
import { Settings04Icon } from '@expo/styleguide-icons/outline/Settings04Icon';
import { Users01Icon } from '@expo/styleguide-icons/outline/Users01Icon';
import { ZapFastIcon } from '@expo/styleguide-icons/outline/ZapFastIcon';
import { ZapSquareIcon } from '@expo/styleguide-icons/outline/ZapSquareIcon';
import { useRouter } from 'next/compat/router';
import { useContext } from 'react';

import { useEnableNotificationsUsageUI } from '~/common/gating/gates/EnableNotificationsUsageUI';
import { useEnvironmentVariablesFeatureGate } from '~/common/gating/gates/EnvironmentVariablesFeatureGate';
import { useNewBillingFeatureGate } from '~/common/gating/gates/NewBillingFeatureGate';
import { useRuntimesConceptFeatureGate } from '~/common/gating/gates/RuntimesConceptFeatureGate';
import { useServerlessDeploymentsFeatureGate } from '~/common/gating/gates/ServerlessDeploymentsFeatureGate';
import { useWorkflowsFeatureGate } from '~/common/gating/gates/WorkflowsFeatureGate';
import {
  getProjectURL,
  isDeveloperOrImplicitOwner,
  isTeamAccount,
  isUserAccount,
} from '~/common/helpers';
import { SidebarCollapsiblesContext } from '~/providers/SidebarCollapsiblesContext';
import { useAccountIsPlan } from '~/providers/useAccountIsPlan';
import { LoggedInProps } from '~/scenes/_app/helpers';

import { SidebarCollapsible } from './SidebarCollapsible';
import { SidebarLink } from './SidebarLink';
import { SidebarSectionHeader } from './SidebarSectionHeader';
import {
  getPageName,
  isBillingPageDisabled,
  isEnvironmentVariablesPageDisabled,
  SidebarItem,
} from './helpers';
import { A, CALLOUT, HEADLINE } from '../text';

type Props = Pick<LoggedInProps, 'currentUser' | 'accountName'> & {
  selectedSidebarItem?: SidebarItem | null;
  onClick?: () => void;
  projectName: string;
  isHostingConfigured?: boolean | null;
};

export function SidebarLinks({
  currentUser,
  accountName,
  projectName,
  selectedSidebarItem,
  onClick,
  isHostingConfigured,
}: Props) {
  const newBillingFeatureGate = useNewBillingFeatureGate();
  const isNewBillingEnabled = newBillingFeatureGate.isEnabled();

  const urlAccountName = encodeURIComponent(accountName);
  const projectBaseUrl = getProjectURL(accountName, projectName);
  const account = currentUser?.accounts?.find((account) => account.name === accountName);

  const noPermissionData = !accountName || !currentUser?.username;
  const isPersonalAccountOfSSOUser = account?.ownerUserActor?.__typename === 'SSOUser';
  const router = useRouter();
  const deploymentId = router?.query.deploymentId;
  const isHostingDetailsMenu = Boolean(deploymentId);

  const areBillingItemsDisabled = isBillingPageDisabled(currentUser, accountName);
  const areEnvironmentVariablesDisabled = isEnvironmentVariablesPageDisabled(
    currentUser,
    accountName,
    noPermissionData
  );

  const {
    isAccountSettingsOpen,
    setIsAccountSettingsOpen,
    isProjectSettingsOpen,
    setIsProjectSettingsOpen,
    isHostingOpen,
    setIsHostingOpen,
    isUpdatesOpen,
    setIsUpdatesOpen,
    isBuildsOpen,
    setIsBuildsOpen,
  } = useContext(SidebarCollapsiblesContext);
  const accountIsEnterprise = useAccountIsPlan(accountName, 'Enterprise');
  const isEnvironmentVariablesEnabled = useEnvironmentVariablesFeatureGate().isEnabled();
  const shouldShowHosting = useServerlessDeploymentsFeatureGate().isEnabled();
  const shouldShowRuntimes = useRuntimesConceptFeatureGate().isEnabled();
  const shouldShowWorkflows = useWorkflowsFeatureGate().isEnabled();

  const generalSection = (
    <>
      {projectName && !isHostingDetailsMenu && (
        <SidebarLink
          title="Back to Dashboard"
          Icon={ArrowLeftIcon}
          href={`/accounts/${urlAccountName}`}
          isSelected={selectedSidebarItem === SidebarItem.HOME}
          onClick={onClick}
        />
      )}
      {projectName && isHostingDetailsMenu && (
        <SidebarLink
          title="Back to Deployments"
          Icon={ArrowLeftIcon}
          href={`${projectBaseUrl}/hosting/deployments`}
          isSelected={selectedSidebarItem === SidebarItem.HOME}
          onClick={onClick}
        />
      )}
      {!projectName && (
        <SidebarLink
          title="Dashboard"
          Icon={LayoutAlt02Icon}
          href={`/accounts/${urlAccountName}`}
          isSelected={selectedSidebarItem === SidebarItem.HOME}
          onClick={onClick}
        />
      )}
      {!projectName && (
        <SidebarLink
          title="Projects"
          Icon={FolderIcon}
          href={`/accounts/${urlAccountName}/projects`}
          isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_PROJECTS}
          onClick={onClick}
        />
      )}
      {!projectName && isUserAccount(accountName ?? '', currentUser) && (
        <SidebarLink
          title="Snacks"
          Icon={SnackLogo}
          href={`/accounts/${urlAccountName}/snacks`}
          isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SNACKS}
          onClick={onClick}
        />
      )}
      {!projectName && (
        <SidebarLink
          title="Usage"
          href={`/accounts/${urlAccountName}/settings/usage`}
          Icon={LineChartUp02Icon}
          isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_USAGE}
          onClick={onClick}
        />
      )}
    </>
  );

  const isPushNotificationsSidebarLinkEnabled = useEnableNotificationsUsageUI().isEnabled();

  const projectSection = (
    <>
      {projectName && !isHostingDetailsMenu && (
        <>
          <SidebarSectionHeader title="Project" />
          <SidebarLink
            title="Overview"
            href={projectBaseUrl}
            Icon={Grid01Icon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_OVERVIEW}
            onClick={onClick}
          />
          {shouldShowWorkflows ? (
            <SidebarLink
              title="Workflows"
              href={`${projectBaseUrl}/workflows`}
              Icon={Dataflow01Icon}
              isSelected={selectedSidebarItem === SidebarItem.WORKFLOWS}
              onClick={onClick}
            />
          ) : null}
          <SidebarCollapsible
            title="Builds"
            Icon={BuildIcon}
            defaultOpen={isBuildsOpen}
            onOpenChange={setIsBuildsOpen}>
            <SidebarLink
              title="All builds"
              href={`${projectBaseUrl}/builds`}
              Icon={BuildIcon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_BUILDS}
              onClick={onClick}
            />
            <SidebarLink
              title="Development builds"
              href={`${projectBaseUrl}/development-builds`}
              Icon={Smartphone01Icon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_DEVELOPMENT_BUILDS}
              onClick={onClick}
            />
            <SidebarLink
              title="Submissions"
              href={`${projectBaseUrl}/submissions`}
              Icon={EasSubmitIcon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_SUBMISSIONS}
              onClick={onClick}
            />
          </SidebarCollapsible>
          {shouldShowHosting ? (
            <SidebarCollapsible
              title="Hosting"
              Icon={Cloud01Icon}
              defaultOpen={isHostingOpen}
              onOpenChange={setIsHostingOpen}>
              <SidebarLink
                title="Overview"
                href={`${projectBaseUrl}/hosting`}
                Icon={Grid01Icon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING}
                onClick={onClick}
              />
              <SidebarLink
                title="Deployments"
                href={`${projectBaseUrl}/hosting/deployments`}
                Icon={ZapSquareIcon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING_DEPLOYMENTS}
                onClick={onClick}
                disabled={!isHostingConfigured}
                disabledHint={<SetupHostingHint section="Deployments" />}
              />
              <SidebarLink
                title="Metrics"
                href={`${projectBaseUrl}/hosting/metrics`}
                Icon={ZapFastIcon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING_METRICS}
                onClick={onClick}
                disabled={!isHostingConfigured}
                disabledHint={<SetupHostingHint section="Metrics" />}
              />
              <SidebarLink
                title="Crashes"
                href={`${projectBaseUrl}/hosting/crashes`}
                Icon={MessageXSquareIcon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING_CRASHES}
                onClick={onClick}
                disabled={!isHostingConfigured}
                disabledHint={<SetupHostingHint section="Crashes" />}
              />
              <SidebarLink
                title="Requests"
                href={`${projectBaseUrl}/hosting/requests`}
                Icon={ListIcon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING_REQUESTS}
                onClick={onClick}
                disabled={!isHostingConfigured}
                disabledHint={<SetupHostingHint section="Requests" />}
              />
              <SidebarLink
                title="Settings"
                href={`${projectBaseUrl}/hosting/settings`}
                Icon={Settings02Icon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING_SETTINGS}
                onClick={onClick}
                disabled={!isHostingConfigured}
                disabledHint={<SetupHostingHint section="Settings" />}
              />
            </SidebarCollapsible>
          ) : null}
          <SidebarCollapsible
            title="Updates"
            Icon={LayersTwo02Icon}
            defaultOpen={isUpdatesOpen}
            onOpenChange={setIsUpdatesOpen}>
            <SidebarLink
              title="Deployments"
              href={`${projectBaseUrl}/deployments`}
              Icon={Dataflow03Icon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_DEPLOYMENTS}
              onClick={onClick}
            />
            {shouldShowRuntimes ? (
              <SidebarLink
                title="Runtimes"
                href={`${projectBaseUrl}/runtimes`}
                Icon={AlignHorizontalCentre02Icon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_RUNTIMES}
                onClick={onClick}
              />
            ) : null}
            <SidebarLink
              title="Channels"
              href={`${projectBaseUrl}/channels`}
              Icon={Cube02Icon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_CHANNELS}
              onClick={onClick}
            />
            <SidebarLink
              title="Branches"
              href={`${projectBaseUrl}/branches`}
              Icon={BranchIcon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_BRANCHES}
              onClick={onClick}
            />
            <SidebarLink
              title="Update groups"
              href={`${projectBaseUrl}/updates`}
              Icon={LayersTwo02Icon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_UPDATES}
              onClick={onClick}
            />
          </SidebarCollapsible>
          <SidebarLink
            title="Insights"
            href={`${projectBaseUrl}/insights`}
            Icon={DataIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_INSIGHTS}
            onClick={onClick}
          />
          {isPushNotificationsSidebarLinkEnabled && (
            <SidebarLink
              title="Push notifications"
              href={`${projectBaseUrl}/push-notifications`}
              Icon={NotificationBoxIcon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_PUSH_NOTIFICATIONS}
              onClick={onClick}
            />
          )}
          <SidebarCollapsible
            title="Configuration"
            Icon={Settings04Icon}
            defaultOpen={isProjectSettingsOpen}
            onOpenChange={setIsProjectSettingsOpen}>
            <SidebarLink
              title="Credentials"
              href={`${projectBaseUrl}/credentials`}
              Icon={CredentialIcon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_CREDENTIALS}
              disabled={
                !currentUser?.isExpoAdmin &&
                (noPermissionData ||
                  !account ||
                  !isDeveloperOrImplicitOwner(account, currentUser?.username))
              }
              disabledHint="Users with Viewer role cannot access account-wide credentials. If you need access please contact your organization Admin."
              onClick={onClick}
            />
            <SidebarLink
              title="GitHub"
              href={`${projectBaseUrl}/github`}
              Icon={GithubIcon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_GITHUB}
              onClick={onClick}
            />
            {!isEnvironmentVariablesEnabled && (
              <SidebarLink
                title="Secrets"
                href={`${projectBaseUrl}/secrets`}
                Icon={Key01Icon}
                isSelected={selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_SECRETS}
                onClick={onClick}
              />
            )}
            {isEnvironmentVariablesEnabled && (
              <SidebarLink
                title="Environment variables"
                href={`${projectBaseUrl}/environment-variables`}
                Icon={BracketsXIcon}
                isSelected={
                  selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_ENVIRONMENT_VARIABLES
                }
                onClick={onClick}
                disabled={areEnvironmentVariablesDisabled}
                disabledHint="Users with Viewer role cannot access project-wide environment variables. If you need access please contact your organization Admin."
              />
            )}
            <SidebarLink
              title="Project settings"
              href={`${projectBaseUrl}/settings`}
              Icon={Settings02Icon}
              isSelected={selectedSidebarItem === SidebarItem.PROJECT_SETTINGS_OVERVIEW}
              onClick={onClick}
            />
          </SidebarCollapsible>
        </>
      )}
    </>
  );

  const hostingSection = (
    <>
      {projectName && isHostingDetailsMenu && (
        <>
          <SidebarSectionHeader title="Deployment Details" />
          <SidebarLink
            title="Overview"
            href={`${projectBaseUrl}/hosting/deployments/${deploymentId}`}
            Icon={ZapSquareIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING_DEPLOYMENTS_OVERVIEW}
            onClick={onClick}
          />
          <SidebarLink
            title="Metrics"
            href={`${projectBaseUrl}/hosting/deployments/${deploymentId}/metrics`}
            Icon={ZapFastIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING_DEPLOYMENTS_METRICS}
            onClick={onClick}
          />
          <SidebarLink
            title="Requests"
            href={`${projectBaseUrl}/hosting/deployments/${deploymentId}/requests`}
            Icon={ListIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING_DEPLOYMENTS_REQUESTS}
            onClick={onClick}
          />
          <SidebarLink
            title="Logs"
            href={`${projectBaseUrl}/hosting/deployments/${deploymentId}/logs`}
            Icon={PencilLineIcon}
            isSelected={selectedSidebarItem === SidebarItem.PROJECT_HOSTING_DEPLOYMENTS_LOGS}
            onClick={onClick}
          />
        </>
      )}
    </>
  );

  const accountSettingsSection = (
    <>
      {!projectName && (
        <SidebarCollapsible
          title={getPageName(
            isUserAccount(accountName ?? '', currentUser),
            isTeamAccount(accountName ?? '', currentUser)
          )}
          Icon={Settings02Icon}
          defaultOpen={isAccountSettingsOpen}
          onOpenChange={setIsAccountSettingsOpen}>
          <SidebarLink
            title="Overview"
            href={`/accounts/${urlAccountName}/settings`}
            Icon={Grid01Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_OVERVIEW}
            onClick={onClick}
          />
          <SidebarLink
            title="Members"
            href={`/accounts/${urlAccountName}/settings/members`}
            Icon={Users01Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_MEMBERS}
            onClick={onClick}
          />
          <SidebarLink
            title="Access tokens"
            href={`/accounts/${urlAccountName}/settings/access-tokens`}
            Icon={Coins01Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_ACCESS_TOKENS}
            onClick={onClick}
          />
          <SidebarLink
            title="Credentials"
            href={`/accounts/${urlAccountName}/settings/credentials`}
            Icon={CredentialIcon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_CREDENTIALS}
            onClick={onClick}
            disabled={
              !currentUser?.isExpoAdmin &&
              (!account ||
                !currentUser?.username ||
                !isDeveloperOrImplicitOwner(account, currentUser?.username))
            }
            disabledHint="Users with Viewer role cannot access account-wide credentials. If you need access please contact your organization Admin."
          />
          <SidebarLink
            title="Apple devices"
            href={`/accounts/${urlAccountName}/settings/apple-devices`}
            Icon={Smartphone02Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_APPLE_DEVICES}
            onClick={onClick}
          />
          {(accountIsEnterprise || currentUser.isExpoAdmin) && (
            <SidebarLink
              title="Audit logs"
              href={`/accounts/${urlAccountName}/settings/audit-logs`}
              Icon={FileSearch02Icon}
              isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_AUDIT_LOGS}
              onClick={onClick}
            />
          )}
          <SidebarLink
            title="Secrets"
            href={`/accounts/${urlAccountName}/settings/secrets`}
            Icon={Key01Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_SECRETS}
            onClick={onClick}
          />
          {isEnvironmentVariablesEnabled && (
            <SidebarLink
              title="Environment variables"
              href={`/accounts/${urlAccountName}/settings/environment-variables`}
              Icon={BracketsXIcon}
              isSelected={
                selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_ENVIRONMENT_VARIABLES
              }
              onClick={onClick}
              disabled={areEnvironmentVariablesDisabled}
              disabledHint="Users with Viewer role cannot access account-wide environment variables. If you need access please contact your organization Admin."
            />
          )}
          <SidebarLink
            title="Email notifications"
            href={`/accounts/${urlAccountName}/settings/email-notifications`}
            Icon={Bell03Icon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS}
            onClick={onClick}
          />
          <SidebarLink
            title="Billing"
            href={`/accounts/${urlAccountName}/settings/${isNewBillingEnabled ? 'new-billing' : 'billing'}`}
            Icon={CreditCard02Icon}
            isSelected={
              selectedSidebarItem ===
              (isNewBillingEnabled
                ? SidebarItem.ACCOUNT_SETTINGS_NEW_BILLING
                : SidebarItem.ACCOUNT_SETTINGS_BILLING)
            }
            onClick={onClick}
            disabled={areBillingItemsDisabled}
            disabledHint={
              isPersonalAccountOfSSOUser
                ? 'Billing is not available for SSO user personal accounts.'
                : 'Members with a Developer or Viewer role cannot access this page. Contact a member with an Owner or Admin role to gain access.'
            }
          />
          <SidebarLink
            title="Receipts"
            href={`/accounts/${urlAccountName}/settings/receipts`}
            Icon={ReceiptIcon}
            isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SETTINGS_RECEIPTS}
            onClick={onClick}
            disabled={areBillingItemsDisabled}
            disabledHint={
              isPersonalAccountOfSSOUser
                ? 'Billing is not available for SSO user personal accounts.'
                : 'Members with a Developer or Viewer role cannot access this page. Contact a member with an Owner or Admin role to gain access.'
            }
          />
        </SidebarCollapsible>
      )}
    </>
  );

  return (
    <>
      {generalSection}
      {hostingSection}
      {accountSettingsSection}
      {projectSection}
    </>
  );
}

// TODO(@kadikraman) update this to point to a docs page for public release
function SetupHostingHint({ section }: { section: string }) {
  return (
    <>
      <HEADLINE tag="span" className="block">
        Configure your hosting project
      </HEADLINE>
      <CALLOUT tag="span" className="block">
        The {section} page becomes available once you've configured your hosting project.
      </CALLOUT>
      <CALLOUT tag="span" className="block">
        See the{' '}
        <A isStyled href="https://www.notion.so/expo/Expo-Hosting-9387d590b73348418df0169f786bfdc2">
          Expo Hosting
        </A>{' '}
        docs for instructions.
      </CALLOUT>
    </>
  );
}
