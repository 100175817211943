import { LoggedInProps } from '~/scenes/_app/helpers';
import { useAccountSelectorQuery } from '~/ui/components/SidebarNavigation/queries/AccountSelector.query.generated';

import { AccountSelector } from './AccountSelector';

type Props = {
  currentUser: LoggedInProps['currentUser'];
  currentDateAsString: string;
  accountName: string;
  onSelect: (selectedName: string) => void;
};

/**
 * Used for super users to display account selector for any account.
 *
 * **NOTE:**
 * observed account is inferred from URL only, so if you visit a personal
 * account but then switch to an organization available to it, the account list
 * will degrade and only show that organization.
 */
export function AccountSelectorSuperuser({
  currentUser,
  currentDateAsString,
  accountName,
  onSelect,
}: Props) {
  const { data } = useAccountSelectorQuery({
    variables: { accountName },
  });

  if (!data) {
    return (
      <AccountSelector
        currentUser={currentUser}
        accounts={[]}
        currentDateAsString={currentDateAsString}
        disabled
      />
    );
  }

  // When inspecting a user, we can access their list of accounts by finding ownerUserActor which
  // points to their primary account. For organizations ownerUserActor is null and we can only use
  // the infomation about the organization itself.
  const account = data.account.byName;
  const ownerUserActor = account?.ownerUserActor;
  const accounts = ownerUserActor ? ownerUserActor.accounts : [account];

  return (
    <AccountSelector
      currentUser={currentUser}
      accounts={accounts}
      currentDateAsString={currentDateAsString}
      onSelect={onSelect}
    />
  );
}
