import { ParsedUrlQuery } from 'node:querystring';

import {
  FEATURE_GATE_DISABLE_PARAM,
  FEATURE_GATE_ENABLE_PARAM,
} from '~/common/gating/FeatureGateOverrides';

export function hasURLFeatureGateOverrides(query: ParsedUrlQuery) {
  return Object.keys(query).some(
    (key) => key === FEATURE_GATE_ENABLE_PARAM || key === FEATURE_GATE_DISABLE_PARAM
  );
}
