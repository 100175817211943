import { Observable, FetchResult, NextLink, Operation, Observer } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { type APIV2Client } from '~/common/api-v2-client';
import requestSudoUpgrade from '~/ui/components/authentication/UpgradeSudoForm/asyncUpgradeSudoForm';

type PendingRequest = {
  operation: Operation;
  forward: NextLink;
  observer: Observer<FetchResult>;
};

let isRefreshing = false;
let pendingRequests: PendingRequest[] = [];

export function createSudoUpgradeLink(apiV2Client: APIV2Client) {
  function processRequest(entry: PendingRequest) {
    const { operation, forward, observer } = entry;

    forward(operation).subscribe({
      next: (result) => {
        observer.next?.(result);

        if (pendingRequests.length) {
          processRequest(pendingRequests.shift()!);
        }
      },
      error: (error) => {
        observer.error?.(error);

        if (pendingRequests.length) {
          processRequest(pendingRequests.shift()!);
        }
      },
      complete: observer.complete?.bind(observer),
    });
  }

  return onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors && graphQLErrors[0].extensions.errorCode === 'SUDO_MODE_REQUIRED') {
      return new Observable((observer) => {
        const pendingReq: PendingRequest = { operation, forward, observer };

        if (isRefreshing) {
          pendingRequests.push(pendingReq);
        } else {
          isRefreshing = true;

          requestSudoUpgrade(apiV2Client)
            .then(() => {
              isRefreshing = false;
              processRequest(pendingReq);
            })
            .catch((error) => {
              isRefreshing = false;
              pendingRequests = [];
              observer.error(error);
            });
        }

        return () => {
          pendingRequests = pendingRequests.filter((pr) => pr !== pendingReq);
        };
      });
    }

    return forward(operation);
  });
}
