import type { NextApiRequest } from 'next';

import {
  FEATURE_GATE_DEFAULT_STORAGE,
  FEATURE_GATE_ITEM_KEY,
} from '~/common/gating/FeatureGateOverrides';
import Storage from '~/common/storage';
import { getFeatureGatesStorageKey } from '~/scenes/FeatureGatesScene/getFeatureGateStorageKey';

export function getCookieFeatureGateQueryParams(initialCookies?: NextApiRequest['cookies']) {
  // note(tchayen): this will always be empty for non-admin users, but unfortunately we need this to
  // create apollo client which we will use to fetch user data so at this point we don't know who is
  // an admin and who isn't.
  const storageKey = getFeatureGatesStorageKey();
  const browserCookie = new Storage(
    storageKey,
    'cookie',
    undefined,
    // We should only use cookies from the request in the initial SSR in case we decided to remove a cookie
    // with client-side code, this will stop hydration error during dev
    typeof window === 'undefined' ? initialCookies : {}
  );

  const featureGates = JSON.parse(
    browserCookie.getItem(FEATURE_GATE_ITEM_KEY) ?? FEATURE_GATE_DEFAULT_STORAGE
  );
  // If there are feature gates in the URL, use those instead of the ones in the cookie. The above
  // is then ignored.
  return featureGates;
}
