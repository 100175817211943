import { mergeClasses } from '@expo/styleguide';
import { InputHTMLAttributes, forwardRef, Ref } from 'react';

import { FormError } from './FormError';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  id?: string;
};

export const Input = forwardRef(function Input(
  { error, id, className, disabled, readOnly, ...rest }: InputProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <FormError error={error}>
      <input
        type="text"
        {...rest}
        ref={ref}
        id={id}
        name={id}
        disabled={disabled}
        readOnly={readOnly}
        data-testid={id}
        className={mergeClasses(
          'text-[16px] leading-relaxed tracking-[-0.011rem]',
          'relative w-full rounded-md border border-default bg-default p-3 text-default shadow-xs outline-0 transition-colors',
          'focus:border-palette-blue9',
          'placeholder:text-quaternary',
          error && '!border-danger',
          disabled && 'cursor-not-allowed bg-subtle opacity-60',
          readOnly && 'cursor-default',
          className
        )}
      />
    </FormError>
  );
});
