import { HelpCircleIcon } from '@expo/styleguide-icons/outline/HelpCircleIcon';
import { useState, useRef } from 'react';

import { useCurrentUserQuery } from '~/graphql/queries/CurrentUserQuery.query.generated';
import { Button } from '~/ui/components/Button';

import { Popover } from '../Popover';
import { PopoverFeedbackContents } from '../PopoverFeedback/PopoverFeedbackContents';

export const MOBILE_FEEDBACK_POPOVER_BUTTON_ID = 'mobile-feedback-popover-button';

export function MobileFeedback() {
  const [feedbackIsVisible, setFeedbackIsVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { data, error } = useCurrentUserQuery();
  const currentUser = data?.meUserActor;

  if (!currentUser || error) {
    return null;
  }

  function onDismiss(event?: TouchEvent | MouseEvent) {
    if (!event) {
      setFeedbackIsVisible(false);
    } else {
      setFeedbackIsVisible((prevFeedbackIsVisible) => !prevFeedbackIsVisible);
    }
  }

  return (
    <Popover
      className="hidden min-w-[95vw] max-md-gutters:block"
      sideOffset={16}
      collisionPadding={{ left: 12, right: 12 }}
      trigger={
        <div className="relative hidden max-md-gutters:mt-10 max-md-gutters:block">
          <Button
            id={MOBILE_FEEDBACK_POPOVER_BUTTON_ID}
            ref={buttonRef}
            theme="secondary"
            aria-label="Share feedback"
            size="md"
            block
            leftSlot={<HelpCircleIcon className="text-icon-secondary" />}
            onClick={() => {
              setFeedbackIsVisible((prevFeedbackIsVisible) => !prevFeedbackIsVisible);
            }}>
            Share feedback
          </Button>
        </div>
      }>
      <div className="hidden max-md-gutters:block">
        <PopoverFeedbackContents
          isVisible={feedbackIsVisible}
          onDismiss={onDismiss}
          currentUser={currentUser}
        />
      </div>
    </Popover>
  );
}
