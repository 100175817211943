// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { AppDataFragmentDoc } from './AppData.fragment.generated';
export type AppDataWithRepoFragment = { __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null, metadata: { __typename?: 'GitHubRepositoryMetadata', githubRepoName: string, githubRepoOwnerName: string } } | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string } };

export const AppDataWithRepoFragmentDoc = gql`
    fragment AppDataWithRepo on App {
  ...AppData
  githubRepository {
    metadata {
      githubRepoName
      githubRepoOwnerName
    }
  }
}
    ${AppDataFragmentDoc}`;