// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../graphql/types.generated';

import { gql } from '@apollo/client';
export type UserPermissionDataFragment = { __typename?: 'UserPermission', permissions: Array<Types.Permission>, role: Types.Role, userActor?: { __typename?: 'SSOUser', id: string, created: any, firstName?: string | null, lastName?: string | null, profilePhoto: string, displayName: string, username: string, bestContactEmail?: string | null } | { __typename?: 'User', email: string, id: string, created: any, firstName?: string | null, lastName?: string | null, profilePhoto: string, displayName: string, username: string, bestContactEmail?: string | null } | null };

export const UserPermissionDataFragmentDoc = gql`
    fragment UserPermissionData on UserPermission {
  permissions
  role
  userActor {
    id
    created
    firstName
    lastName
    profilePhoto
    displayName
    username
    bestContactEmail
    ... on User {
      email
    }
  }
}
    `;