// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { UserPermissionDataFragmentDoc } from '../../scenes/Settings/MembersScene/queries/UserPermissionData.fragment.generated';
export type AccountsDataFragment = { __typename: 'Account', id: string, name: string, isDisabled: boolean, createdAt: any, ownerUserActor?: { __typename: 'SSOUser', id: string, fullName?: string | null, profilePhoto: string, username: string } | { __typename: 'User', email: string, id: string, fullName?: string | null, profilePhoto: string, username: string } | null, viewerUserPermission: { __typename?: 'UserPermission', permissions: Array<Types.Permission>, role: Types.Role, userActor?: { __typename?: 'SSOUser', id: string, created: any, firstName?: string | null, lastName?: string | null, profilePhoto: string, displayName: string, username: string, bestContactEmail?: string | null } | { __typename?: 'User', email: string, id: string, created: any, firstName?: string | null, lastName?: string | null, profilePhoto: string, displayName: string, username: string, bestContactEmail?: string | null } | null }, subscription?: { __typename?: 'SubscriptionDetails', name?: string | null, id: string, planId?: string | null, status?: string | null, nextInvoice?: any | null } | null };

export const AccountsDataFragmentDoc = gql`
    fragment AccountsData on Account {
  __typename
  id
  name
  isDisabled
  createdAt
  ownerUserActor {
    __typename
    id
    fullName
    profilePhoto
    username
    ... on User {
      email
    }
  }
  viewerUserPermission {
    ...UserPermissionData
  }
  subscription {
    name
    id
    planId
    status
    nextInvoice
  }
}
    ${UserPermissionDataFragmentDoc}`;