import { useApolloClient } from '@apollo/client';

import { handleLogoutAsync } from '~/common/handlers/authentication';
import { useAPIV2Client } from '~/providers/useAPIV2';

export function useLogout() {
  const client = useApolloClient();
  const apiV2Client = useAPIV2Client();

  return async function onLogoutAsync() {
    const { endSessionRedirectUrl } = await handleLogoutAsync(apiV2Client, client);
    if (endSessionRedirectUrl) {
      window.location.href = endSessionRedirectUrl;
    } else {
      window.location.href = '/';
    }
  };
}
