import { AlertTriangleDuotoneIcon } from '@expo/styleguide-icons/duotone/AlertTriangleDuotoneIcon';
import { AnnotationDotsDuotoneIcon } from '@expo/styleguide-icons/duotone/AnnotationDotsDuotoneIcon';
import { Announcement02DuotoneIcon } from '@expo/styleguide-icons/duotone/Announcement02DuotoneIcon';

import PopoverFeedbackAnimatedBackground from './PopoverFeedbackAnimatedBackground';
import { PopoverFeedbackOption } from './PopoverFeedbackOption';
import { PopoverHeaderButtonGroup } from './PopoverHeaderButtonGroup';
import { FeedbackPopoverPage } from './shared';

type Props = {
  onDismiss: () => void;
  onSetPage: (pageNum: FeedbackPopoverPage) => void;
};

export function PopoverFeedbackOptions({ onDismiss, onSetPage }: Props) {
  function onFormChange(type: FeedbackPopoverPage) {
    onSetPage(type);
  }

  return (
    <div className="relative flex flex-1 flex-col">
      <PopoverFeedbackAnimatedBackground />
      <PopoverHeaderButtonGroup
        title="Share your feedback with us"
        onDismiss={onDismiss}
        onSetPage={onSetPage}
      />
      <div className="z-10 flex flex-1 items-center justify-center pb-6">
        <div className="grid grid-cols-3 gap-4">
          <PopoverFeedbackOption
            label={FeedbackPopoverPage.ISSUE}
            icon={<AlertTriangleDuotoneIcon className="icon-xl" />}
            onClick={onFormChange}
          />
          <PopoverFeedbackOption
            label={FeedbackPopoverPage.FEEDBACK}
            icon={<Announcement02DuotoneIcon className="icon-xl" />}
            onClick={onFormChange}
          />
          <PopoverFeedbackOption
            label={FeedbackPopoverPage.CONNECT}
            icon={<AnnotationDotsDuotoneIcon className="icon-xl" />}
            onClick={onFormChange}
          />
        </div>
      </div>
    </div>
  );
}
