import { mergeClasses, theme, typography } from '@expo/styleguide';
import { forwardRef, Ref, HTMLAttributes } from 'react';
import { titleCase } from 'title-case';

import { LinkBase, LinkProps } from '~/ui/components/LinkBase';

export enum TextElement {
  CODE = 'code',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  LI = 'li',
  P = 'p',
  SPAN = 'span',
  UL = 'ul',
  OL = 'ol',
  PRE = 'pre',
  KBD = 'kbd',
  TIME = 'time',
}
export type TextElementUnion = `${TextElement}`;
export type TextWeight = keyof typeof typography.utility.weight;
export type TextTheme = keyof typeof theme.text;

export type TextComponentProps = HTMLAttributes<
  | HTMLHeadingElement
  | HTMLParagraphElement
  | HTMLLIElement
  | HTMLUListElement
  | HTMLPreElement
  | HTMLTimeElement
> & {
  testID?: string;
  size?: 'xl';
  weight?: TextWeight;
  theme?: TextTheme;
  variant?: 'oneLine';
  tag?: TextElementUnion;
  skipCapitalization?: boolean;
};

function getXlSizeClassName(Element: TextElement) {
  switch (Element) {
    case TextElement.H1:
      return mergeClasses(
        'text-[49px] leading-[1.204]',
        'max-md-gutters:text-[43px] max-md-gutters:leading-[1.2093]',
        'max-sm-gutters:text-[37px] max-sm-gutters:leading-[1.2973]'
      );
    case TextElement.H2:
      return mergeClasses(
        'text-[39px] leading-[1.3076]',
        'max-md-gutters:text-[34px] max-md-gutters:leading-[1.2941]',
        'max-sm-gutters:text-[29px] max-sm-gutters:leading-[1.3103]'
      );
    case TextElement.H3:
      return mergeClasses(
        'text-[31px] leading-[1.29]',
        'max-md-gutters:text-[27px] max-md-gutters:leading-[1.3333]',
        'max-sm-gutters:text-[23px] max-sm-gutters:leading-[1.3913]'
      );
    case TextElement.H4:
      return mergeClasses(
        'text-[25px] leading-[1.4] tracking-[-0.021rem]',
        'max-md-gutters:text-[22px] max-md-gutters:leading-[1.409]',
        'max-sm-gutters:text-[19px] max-sm-gutters:leading-[1.5263]'
      );
    case TextElement.H5:
      return mergeClasses(
        'text-[20px] leading-normal tracking-[-0.017rem]',
        'max-md-gutters:text-[18px] max-md-gutters:leading-[1.5555]',
        'max-sm-gutters:text-[16px] max-sm-gutters:leading-[1.6154]'
      );
    case TextElement.H6:
      return 'text-[16px] leading-[1.6154] tracking-[-0.011rem]';
    default:
      return undefined;
  }
}

function getTextWeightClassName(weight?: string) {
  switch (weight) {
    case 'black':
      return 'font-black';
    case 'semiBold':
      return 'font-semiBold';
    case 'medium':
      return 'font-medium';
    case 'regular':
      return 'font-normal';
    default:
      return undefined;
  }
}

function getTextColorClassName(theme?: TextTheme) {
  switch (theme) {
    case 'default':
      return 'text-default';
    case 'secondary':
      return 'text-secondary';
    case 'tertiary':
      return 'text-tertiary';
    case 'quaternary':
      return 'text-quaternary';
    case 'success':
      return 'text-success';
    case 'danger':
      return 'text-danger';
    case 'warning':
      return 'text-warning';
    case 'info':
      return 'text-info';
    case 'link':
      return 'text-link';
    default:
      return undefined;
  }
}

export function createTextComponent(Element: TextElement, textClassName?: string) {
  return forwardRef(function TextComponent(
    {
      className,
      variant,
      testID,
      size,
      weight,
      tag,
      theme: textTheme,
      skipCapitalization,
      children,
      ...rest
    }: TextComponentProps,
    forwardedRef: Ref<any>
  ) {
    const TextElementTag = tag ?? Element;

    const isHeader = [
      TextElement.H1,
      TextElement.H2,
      TextElement.H3,
      TextElement.H4,
      TextElement.H5,
      TextElement.H6,
    ].includes(TextElementTag as TextElement);

    const maybeSentenceCasedChildren =
      typeof children === 'string' && !skipCapitalization && isHeader
        ? titleCase(children, { sentenceCase: true })
        : children;

    return (
      <TextElementTag
        className={mergeClasses(
          'text-inherit text-default',
          textClassName,
          size === 'xl' && getXlSizeClassName(Element),
          variant === 'oneLine' && 'truncate',
          getTextWeightClassName(weight),
          getTextColorClassName(textTheme),
          className
        )}
        data-testid={testID}
        ref={forwardedRef}
        {...rest}>
        {maybeSentenceCasedChildren}
      </TextElementTag>
    );
  });
}

export const H1 = createTextComponent(
  TextElement.H1,
  mergeClasses(
    'text-[31px] font-semibold leading-[1.29] tracking-[-0.022rem]',
    'max-md-gutters:text-[27px] max-md-gutters:leading-[1.3333]',
    'max-sm-gutters:text-[23px] max-sm-gutters:leading-[1.3913]'
  )
);
export const H2 = createTextComponent(
  TextElement.H2,
  mergeClasses(
    'text-[25px] font-semibold leading-[1.4] tracking-[-0.021rem]',
    'max-md-gutters:text-[22px] max-md-gutters:leading-[1.409]',
    'max-sm-gutters:text-[19px] max-sm-gutters:leading-[1.5263]'
  )
);
export const H3 = createTextComponent(
  TextElement.H3,
  mergeClasses(
    'text-[20px] font-semibold leading-normal tracking-[-0.017rem]',
    'max-md-gutters:text-[18px] max-md-gutters:leading-[1.5555]',
    'max-sm-gutters:text-[16px] max-sm-gutters:leading-relaxed'
  )
);
export const H4 = createTextComponent(
  TextElement.H4,
  mergeClasses('text-[16px] font-semibold leading-relaxed tracking-[-0.011rem]')
);
export const H5 = createTextComponent(
  TextElement.H5,
  mergeClasses('text-[13px] font-medium leading-[1.5833] tracking-[-0.003rem]')
);
export const H6 = createTextComponent(
  TextElement.H6,
  mergeClasses('text-[12px] font-medium leading-relaxed')
);

export const P = createTextComponent(
  TextElement.P,
  'font-normal text-[16px] leading-[1.625] tracking-[-0.011rem]'
);
export const CODE = createTextComponent(
  TextElement.CODE,
  mergeClasses(
    'text-[13px] font-normal leading-[130%] tracking-[-0.003rem]',
    'inline-block rounded-md border border-secondary bg-subtle px-1 py-0.5'
  )
);
export const LI = createTextComponent(
  TextElement.LI,
  mergeClasses('text-[16px] font-normal leading-relaxed tracking-[-0.011rem]', 'mb-2')
);
export const HEADLINE = createTextComponent(
  TextElement.P,
  'font-medium text-[16px] leading-[1.625] tracking-[-0.011rem]'
);
export const LABEL = createTextComponent(
  TextElement.SPAN,
  'font-medium text-[15px] leading-[1.6] tracking-[-0.009rem]'
);
export const CALLOUT = createTextComponent(
  TextElement.P,
  'font-normal text-[14px] leading-[1.5715] tracking-[-0.006rem]'
);
export const SPAN = createTextComponent(
  TextElement.SPAN,
  'font-normal text-[14px] leading-[1.5715] tracking-[-0.006rem]'
);
export const FOOTNOTE = createTextComponent(
  TextElement.P,
  'font-normal text-[13px] leading-[1.6154] tracking-[-0.003rem]'
);
export const CAPTION = createTextComponent(
  TextElement.P,
  'font-normal text-[12px] leading-[1.6154]'
);
export const BOLD = createTextComponent(TextElement.SPAN, 'font-semibold');
export const DEMI = createTextComponent(TextElement.SPAN, 'font-medium');
export const UL = createTextComponent(
  TextElement.UL,
  'list-disc ml-6 [&_ol]:mt-2 [&_ol]:mb-4 [&_ul]:mt-2 [&_ul]:mb-4'
);
export const OL = createTextComponent(
  TextElement.OL,
  'list-disc ml-6 [&_ol]:mt-2 [&_ol]:mb-4 [&_ul]:mt-2 [&_ul]:mb-4'
);
export const PRE = createTextComponent(
  TextElement.PRE,
  mergeClasses(
    'whitespace-pre-wrap text-[13px] font-normal leading-[130%] tracking-[-0.003rem]',
    'mx-0 my-4 inline-block rounded-md border border-default bg-subtle p-4'
  )
);
export const KBD = createTextComponent(
  TextElement.KBD,
  mergeClasses(
    'relative -top-px inline-block min-h-[20px] min-w-[20px] rounded-sm border border-secondary bg-subtle px-1 shadow-kbd',
    'text-center text-2xs font-semibold leading-[20px] text-secondary',
    'dark:bg-element'
  )
);

export const A = ({
  isStyled,
  className,
  ...rest
}: Omit<LinkProps, 'router'> & { isStyled?: boolean }) => {
  return (
    <LinkBase
      className={mergeClasses(
        'cursor-pointer decoration-0 transition-opacity hocus:opacity-80',
        isStyled && 'font-normal text-link visited:text-link',
        className
      )}
      {...rest}
    />
  );
};

export function HUGE({ size, className, ...rest }: TextComponentProps) {
  const Element = createTextComponent(
    TextElement.H1,
    mergeClasses(
      'font-black',
      size === 'xl'
        ? 'text-[61px] leading-[1.204] max-md-gutters:text-[53px] max-md-gutters:leading-[1.2075] max-sm-gutters:text-[46px] max-sm-gutters:leading-[1.1956]'
        : 'text-[39px] leading-[1.3076] max-md-gutters:text-[34px] max-md-gutters:leading-[1.2941] max-sm-gutters:text-[29px] max-sm-gutters:leading-[1.3103]',
      className
    )
  );

  return <Element {...rest} />;
}
