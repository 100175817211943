import { ButtonBase, WordMarkLogo, Logo as ExpoLogo } from '@expo/styleguide';
import { useRouter } from 'next/compat/router';
import { MouseEvent } from 'react';

type Props = {
  href?: string;
};

export function Logo({ href = '/' }: Props) {
  const router = useRouter();

  function onClick(event: MouseEvent) {
    event.preventDefault();
    return router?.push(href);
  }

  function onRightClick(event: MouseEvent) {
    event.preventDefault();
    return router?.push('/brand');
  }

  return (
    <ButtonBase
      onClick={onClick}
      onContextMenu={onRightClick}
      aria-label="Navigate to home"
      className="mt-px max-md-gutters:mt-0">
      <WordMarkLogo title="Expo home" className="w-[74px] text-default max-md-gutters:hidden" />
      <ExpoLogo title="Expo home" className="hidden text-default max-md-gutters:flex" />
    </ButtonBase>
  );
}
