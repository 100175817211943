import { Link } from '@expo/styleguide';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { Star06Icon } from '@expo/styleguide-icons/outline/Star06Icon';
import { ReactElement } from 'react';

import { CALLOUT, FOOTNOTE } from '~/ui/components/text';

type Props = {
  title: string;
  description: string;
  href: string;
  icon?: ReactElement;
  onClick?: () => void;
  hideIcon?: boolean;
  className?: string;
  openInNewTab?: boolean;
};

export function FeatureAnnouncement({
  title,
  href,
  description,
  icon,
  onClick,
  className,
  hideIcon,
  openInNewTab = true,
}: Props) {
  return (
    <div className={className}>
      <Link href={href} openInNewTab={openInNewTab} onClick={onClick}>
        <div className="flex items-center justify-between rounded-t-md bg-palette-blue5 px-4 py-2">
          <div className="flex items-center gap-2">
            {!hideIcon && (icon ?? <Star06Icon className="icon-sm text-palette-blue12" />)}
            <CALLOUT weight="medium" className="text-palette-blue12">
              {title}
            </CALLOUT>
          </div>
          <ArrowRightIcon className="icon-sm text-palette-blue12" />
        </div>
        <div className="rounded-b-md bg-palette-blue3 px-4 py-2">
          <FOOTNOTE className="text-palette-blue12">{description}</FOOTNOTE>
        </div>
      </Link>
    </div>
  );
}
