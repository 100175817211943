import { InvestorsItemInfo, SWMTeamItemInfo, TeamItemInfo } from '~/scenes/AboutScene/types';

export const teamEmployees: TeamItemInfo[] = [
  {
    name: 'Charlie Cheever',
    src: '/static/images/about/team.charlie.png',
    email: 'ccheever@expo.dev',
    x: `https://x.com/ccheever`,
    github: `https://github.com/ccheever`,
    bluesky: `https://bsky.app/profile/ccheever.bsky.social`,
  },
  {
    name: 'James Ide',
    src: '/static/images/about/team.james.png',
    x: `https://x.com/JI`,
    github: `https://github.com/ide`,
  },
  {
    name: 'Jess Hui',
    src: '/static/images/about/team.jess.png',
  },
  {
    name: 'Brent Vatne',
    src: '/static/images/about/team.brent.png',
    x: 'https://x.com/notbrent',
    github: 'https://github.com/brentvatne',
  },
  {
    name: 'Quinlan Jung',
    src: '/static/images/about/team.quin.png',
    x: 'https://x.com/quinlanjung',
    github: 'https://github.com/quinlanj',
  },
  {
    name: 'Evan Bacon',
    src: '/static/images/about/team.evan.png',
    x: 'https://x.com/baconbrix',
    github: 'https://github.com/EvanBacon',
  },
  {
    name: 'Nick Novitski',
    src: '/static/images/about/team.nick.png',
    x: 'https://x.com/nicknovitski',
    github: 'https://www.github.com/nicknovitski',
  },
  {
    name: 'Jon Samp',
    src: '/static/images/about/team.jon.png',
    x: 'https://x.com/jonsamp',
    github: 'https://github.com/jonsamp',
  },
  {
    name: 'Juwan Wheatley',
    src: '/static/images/about/team.juwan.png',
    x: 'https://x.com/fiberjw',
    github: 'https://github.com/fiberjw',
  },
  {
    name: 'Will Schurman',
    src: '/static/images/about/team.will.png',
    github: 'https://github.com/wschurman',
  },
  {
    name: 'Mike Hampton',
    src: '/static/images/about/team.mike.png',
    github: 'https://github.com/randomhajile',
  },
  {
    name: 'Cedric van Putten',
    src: '/static/images/about/team.cedric.png',
    github: 'https://github.com/bycedric',
    x: 'https://x.com/cedric_dev',
    bluesky: 'https://bsky.app/profile/cedric.dev',
  },
  {
    name: 'Kudo Chien',
    src: '/static/images/about/team.kudo.png',
    github: 'https://github.com/kudo',
    x: 'https://x.com/kudochien',
  },
  {
    name: 'Chris Jensen',
    src: '/static/images/about/team.chris.png',
  },
  {
    name: 'Doug Lowder',
    src: '/static/images/about/team.douglowder.png',
    github: 'https://github.com/douglowder',
    bluesky: 'https://bsky.app/profile/douglowder.bsky.social',
    linkedin: 'https://www.linkedin.com/in/douglowder/',
  },
  {
    name: 'Keith Kurak',
    src: '/static/images/about/team.keith.png',
    github: 'https://github.com/keith-kurak',
    x: 'https://x.com/llamaluvr',
    bluesky: 'https://bsky.app/profile/keith.bsky.social',
  },
  {
    name: 'Bartosz Kaszubowski',
    src: '/static/images/about/team.simek.png',
    github: 'https://github.com/Simek',
    x: 'https://x.com/simek',
    bluesky: 'https://bsky.app/profile/simek.dev',
  },
  {
    name: 'Aman Mittal',
    src: '/static/images/about/team.aman.png',
    github: 'https://github.com/amandeepmittal',
    x: 'https://x.com/amanhimself',
  },
  {
    name: 'Tomasz Czajecki',
    src: '/static/images/about/team.tomek.png',
    github: 'https://github.com/tchayen',
    x: 'https://x.com/tchayen',
  },
  {
    name: 'Gabriel Donadel',
    src: '/static/images/about/team.gabriel.png',
    github: 'https://github.com/gabrieldonadel',
    x: 'https://x.com/donadeldev',
  },
  {
    name: 'Stanisław Chmiela',
    src: '/static/images/about/team.stanley.png',
    github: 'https://github.com/sjchmiela',
    x: 'https://x.com/sjchmiela',
  },
  {
    name: 'Kadi Kraman',
    src: '/static/images/about/team.kadi.png',
    github: 'https://github.com/kadikraman',
    x: 'https://x.com/kadikraman',
    bluesky: 'https://bsky.app/profile/kadi.bsky.social',
    linkedin: 'https://www.linkedin.com/in/kadi-kraman/',
  },
  {
    name: 'Josh McFarlin',
    src: '/static/images/about/team.josh.png',
    github: 'https://github.com/Josh-McFarlin',
  },
  {
    name: 'Phil Pluckthun',
    src: '/static/images/about/team.phil.png',
    github: 'https://github.com/kitten',
    x: 'https://x.com/_philpl',
    bluesky: 'https://bsky.app/profile/kitten.sh',
  },
  {
    name: 'Alan Hughes',
    src: '/static/images/about/team.alan.png',
    github: 'https://github.com/alanjhughes',
    x: 'https://twitter.com/AlanJamesHughes',
  },
  {
    name: 'Dan Kelly',
    src: '/static/images/about/team.dan.png',
    x: 'https://twitter.com/DannyQueso',
    linkedin: 'https://www.linkedin.com/in/danielrobertkelly/',
    bluesky: 'https://bsky.app/profile/thedankelly.bsky.social',
  },
  {
    name: 'Mark Lawlor',
    src: '/static/images/about/team.mark.png',
    github: 'https://github.com/marklawlor',
    x: 'https://x.com/mark__lawlor',
  },
  {
    name: 'Alberto Moedano',
    src: '/static/images/about/team.beto.png',
    x: 'https://x.com/betomoedano',
    github: `https://github.com/betomoedano`,
    bluesky: `https://bsky.app/profile/codewithbeto.bsky.social`,
  },
];

export const featuredConsultants = [
  {
    src: '/static/consultants/consultant-swmansion.png',
    backgroundColor: '#001A71',
    href: 'https://swmansion.com',
    title: 'Software Mansion',
    body: 'At Software Mansion, we firmly believe in the importance of our commitment to open-source products. Thousands of developers use our React Native modules in their projects. We are always open to new opportunities, to use cutting-edge technology and tackle exciting challenges.',
    note: 'Software Mansion is the top contributing group to Expo with 2,500+ pull requests since 2017 and their hosting of App.js Conf.',
  },
];

export const consultants = [
  {
    src: '/static/consultants/consultant-callstack.png',
    backgroundColor: '#385FAA',
    title: 'Callstack',
    href: 'https://callstack.com/',
    body: `Callstack is a group of open source enthusiasts with a mission to help developers and companies launch their products for everyone, on every platform, at the same time. We work with many companies all over the world, from small startups to big corporate clients. At Callstack, we believe in community. That's why we run workshops,  meetups and organize React Native EU - first React Native conference in Europe. Many of our developers are contributors to popular libraries, including Mike and Satya being on the React Native core team.`,
  },
  {
    src: '/static/consultants/consultant-g2i.png',
    backgroundColor: '#C0A6A9',
    href: 'http://g2i.co/',
    title: 'G2i',
    body: 'G2i is talent platform for engineers by engineers focused on React, React Native, GraphQL, & Android/iOS. We provide on demand high-quality pre-vetted engineers and small teams of engineers that can integrate with your team full time. We love the React.js and React Native community and encourage our clients to use those frameworks. While our primary focus is on React/React Native, Node.js, GraphQL, and native Android/iOS, we also have engineers that are experienced with Ruby on Rails.',
  },
  {
    src: '/static/consultants/consultant-infinitered.jpg',
    backgroundColor: '#EB5151',
    href: 'https://infinite.red/',
    title: 'Infinite Red',
    body: "We're Infinite Red. We've been shipping software for over 10 years and have been working in React Native from the start. We’ve helped hundreds of companies design, build and launch their apps. Our 30+ Open Source projects, including the popular Ignite boilerplate and Reactotron debugging app, have helped tens of thousands of developers do the same. We also host Chain React, North America’s only React Native conference, every year in Portland, OR. We’re 100% remote (across the US and Canada) and proud of it.",
  },
  {
    src: '/static/consultants/consultant-appandflow.png',
    backgroundColor: '#121212',
    href: 'https://appandflow.com',
    title: 'App & Flow',
    body: `App & Flow has been building beautiful, high-performing apps since 2015. Now, we’re one of the most trusted app development agencies in the React Native ecosystem. Then as now, our mission is simple: build the very best apps for the very best companies, while treating our clients the way we want to be treated.`,
  },
  {
    src: '/static/consultants/consultant-echobind.png',
    backgroundColor: '#272727',
    href: 'https://echobind.com/services/react-native',
    title: 'Echobind',
    body: `Echobind builds mobile apps with React Native. We've worked with clients across a variety of industries from healthcare to consumer and startups to the enterprise. We take projects from initial concept through design, development, launch, and beyond. Need help reaching deadlines or working through difficult features or bugs? Our engineers can augment your team. Leveling up workflows and knowledge of the teams that we work with is what our clients love most.`,
  },
  {
    src: '/static/consultants/consultant-morrow.png',
    backgroundColor: '#3D2FA7',
    title: 'Morrow',
    href: 'https://themorrow.digital/',
    body: `React Native apps for pioneering businesses. At Morrow, we help forward-thinking organisations realise their vision by creating truly innovative apps that outpace their competition. We love Expo and use it to decrease time-to-market and break down barriers for our clients. If your app is broken or needs refactoring then we also offer remedial services. Our fully remote team is headquartered in the UK.`,
  },
  {
    src: '/static/consultants/consultant-margelo.jpg',
    backgroundColor: '#c1c1c1',
    title: 'Margelo',
    href: 'https://margelo.io/',
    body: `Margelo is a high-end React Native agency focused on powerful and beautiful high-performance mobile apps. We’ve built and maintain a large part of the React Native ecosystem, including popular 3D-toolkits, Camera libraries, and in-memory storages/databases. Our clients have been able to ship better products faster by partnering with us, either as a development team extension, or for full app development and design.`,
  },
  {
    src: '/static/consultants/consultant-geekyants.png',
    backgroundColor: '#fff',
    title: 'GeekyAnts',
    href: 'https://geekyants.com/',
    body: `GeekyAnts is a design and engineering studio obsessed with solving problems using technology. We are the creators of gluestack-ui (React Open Source Awards 2024 Winner) and NativeBase (20k+ GitHub Stars). Our expertise lies in building future-ready AI solutions, creating impactful design experiences, and engineering scalable solutions. Our portfolio consists of 500+ clients, across industries, and more than 700+ projects worldwide.`,
  },
];

export const teamInvestors: InvestorsItemInfo[] = [
  {
    name: 'Bobby Goodlatte',
    title: 'Investor',
    src: '/static/images/about/investor.bobby.png',
    x: 'https://x.com/rsg',
  },
  {
    name: 'Jerry Cain',
    title: 'Investor',
    src: '/static/images/about/investor.jerry.png',
  },
  {
    name: 'Jessica Livingston',
    title: 'Investor',
    src: '/static/images/about/investor.jessica.png',
    x: 'https://x.com/jesslivingston',
  },
  {
    name: 'Mehran Sahami',
    title: 'Investor',
    src: '/static/images/about/investor.mehran.png',
  },
  {
    name: 'Paul Graham',
    title: 'Investor',
    src: '/static/images/about/investor.paul.png',
    x: 'https://x.com/paulg',
  },
  {
    name: 'A.Capital',
    title: 'Venture Capital',
    needsBorder: true,
    src: '/static/images/about/investor.acapital.png',
    href: 'https://acapital.com/',
    // Currently unused info
    info: 'A.Capital provides resources and counsel to the creators, inventors and entrepreneurs who are building a new future. Founded by Ronny Conway in 2014, A.Capital invests in early-stage consumer, enterprise and crypto startups and offers world-class advice, connections, and team-building expertise to help those companies scale. A.Capital‘s portfolio includes companies like Notion, Pinterest, Airbnb, Weave, Databricks and Hooked. Learn more at www.acapital.com.',
  },
  {
    name: 'Graph Ventures',
    title: 'Venture Capital',
    needsBorder: true,
    src: '/static/images/about/investor.graphventures.png',
    href: 'https://www.graphventures.com/',
  },
  {
    name: 'South Park Commons',
    title: 'Venture Capital',
    needsBorder: true,
    src: '/static/images/about/investor.southparkcommons.png',
    href: 'https://www.southparkcommons.com/',
  },
  {
    name: 'SV Angel',
    title: 'Venture Capital',
    src: '/static/images/about/investor.svangel.png',
    needsBorder: true,
    x: 'https://x.com/svangel',
  },
  {
    name: 'Y Combinator',
    title: 'Venture Capital',
    src: '/static/images/about/investor.ycombinator.png',
    x: 'https://x.com/ycombinator',
  },
];

export const teamSoftwareMansion: SWMTeamItemInfo[] = [
  {
    name: 'Software Mansion',
    title: 'Agency',
    src: '/static/images/about/contributor.softwaremansion.png',
    x: 'https://x.com/swmansion',
    github: 'https://github.com/software-mansion',
    static: true,
  },
  {
    name: 'Tomasz Sapeta',
    src: '/static/images/about/contributor.tomek.png',
    x: 'https://x.com/tsapeta',
    github: 'https://github.com/tsapeta',
    bluesky: 'https://bsky.app/profile/tsapeta.bsky.social',
  },
  {
    name: 'Łukasz Kosmaty',
    src: '/static/images/about/contributor.lukasz.png',
    github: 'https://github.com/lukmccall',
  },
  {
    name: 'Szymon Dziedzic',
    src: '/static/images/about/contributor.szymon.png',
    github: 'https://github.com/szdziedzic',
    x: 'https://x.com/@szdziedzic',
    bluesky: 'https://bsky.app/profile/szdziedzic.bsky.social',
  },
  {
    name: 'Aleksander Mikucki',
    src: '/static/images/about/contributor.alek.png',
    x: 'https://x.com/aleqsio',
    github: 'https://github.com/aleqsio',
  },
  {
    name: 'Radosław Krzemień',
    src: '/static/images/about/contributor.radek.png',
    github: 'https://github.com/radoslawkrzemien',
  },
  {
    name: 'Piotr Szeremeta',
    src: '/static/images/about/contributor.piotrek.png',
    github: 'https://github.com/khamilowicz',
  },
  {
    name: 'Wojciech Dróżdż',
    src: '/static/images/about/contributor.wojtek.png',
    github: 'https://github.com/behenate',
    linkedin: 'https://www.linkedin.com/in/drozdz-wojciech/',
    x: 'https://x.com/WojciechDrozdz0',
  },
];

export const products = [
  {
    name: 'Orbit',
    description: 'Desktop app to simplify installing and running mobile apps on macOS',
    url: '/orbit',
    openInNewTab: false,
  },
  {
    name: 'Expo Go',
    description:
      'Sandbox app that allows you to quickly experiment with building native apps for Android and iOS',
    url: '/go',
    openInNewTab: false,
  },
  {
    name: 'Expo CLI',
    description: 'Command-line interface for building projects',
    url: '/tools#cli',
    openInNewTab: false,
  },
  {
    name: 'EAS CLI',
    description: 'Command-line interface for Expo Application Services',
    url: 'https://github.com/expo/eas-cli',
    openInNewTab: true,
  },
  {
    name: 'Snack',
    description: 'Expo in the browser',
    url: `${process.env.SNACK_SERVER_URL ?? process.env.EXPO_PUBLIC_SNACK_SERVER_URL}/`,
    openInNewTab: true,
  },
  {
    name: 'Discord',
    description: 'Community chat for Expo developers',
    url: 'https://chat.expo.dev',
    openInNewTab: true,
  },
  {
    name: 'Expo on GitHub',
    description: 'GitHub repo for the Expo platform',
    url: 'https://github.com/expo/expo',
    openInNewTab: true,
  },
  {
    name: 'Expo CLI on GitHub',
    description: 'GitHub repo for Expo CLI',
    url: 'https://github.com/expo/expo/tree/main/packages/@expo/cli',
    openInNewTab: true,
  },
];
