import { createContext, useContext, PropsWithChildren } from 'react';

import { APIV2Client } from '~/common/api-v2-client';
import { FeatureGateQueryParams } from '~/common/gating/FeatureGateOverrides';

type Props = PropsWithChildren<{
  featureGateQueryParams: FeatureGateQueryParams;
}>;

export const APIV2Context = createContext<APIV2Client>({} as any);

export function APIV2ContextProvider({ children, featureGateQueryParams }: Props) {
  const apiV2Client = new APIV2Client(featureGateQueryParams);

  return <APIV2Context.Provider value={apiV2Client}>{children}</APIV2Context.Provider>;
}

export function useAPIV2Client() {
  return useContext(APIV2Context);
}
