import { mergeClasses } from '@expo/styleguide';
import { Ref, useState } from 'react';
import TextareaAutoSize, { TextareaAutosizeProps } from 'react-textarea-autosize';

import * as Strings from '~/common/strings';
import { CAPTION } from '~/ui/components/text';

import { FormError } from '../FormError';

type TextAreaProps = TextareaAutosizeProps & {
  error?: string;
  id?: string;
  characterLimit?: number;
  minRows?: number;
  maxRows?: number;
  autoFocus?: boolean;
  defaultValue?: string;
  className?: string;
  ref?: Ref<HTMLTextAreaElement>;
};

export function TextArea({
  error,
  id,
  characterLimit,
  minRows,
  maxRows,
  onChange,
  defaultValue,
  className,
  autoFocus,
  disabled,
  ...rest
}: TextAreaProps) {
  const [characterCount, setCharacterCount] = useState(
    Strings.getRenderedLength(defaultValue ?? '')
  );

  return (
    <FormError error={error}>
      <div className="relative">
        {characterLimit && (
          <CAPTION
            theme={characterCount > characterLimit ? 'danger' : 'secondary'}
            tag="code"
            className="absolute bottom-3 right-2 z-10">
            {characterLimit - characterCount}
          </CAPTION>
        )}
        <TextareaAutoSize
          {...rest}
          id={id}
          maxRows={maxRows}
          minRows={minRows}
          disabled={disabled}
          onChange={(event) => {
            setCharacterCount(Strings.getRenderedLength(event.target.value));
            // still call the existing onChange function instead of overwriting it
            if (onChange) {
              onChange(event);
            }
          }}
          name={id}
          data-testid={id}
          className={mergeClasses(
            '-mb-0.5 min-h-[100px] w-full resize-none rounded-md border-default bg-default p-3 text-[16px] text-default shadow-xs',
            error && '!border-danger',
            disabled && 'cursor-not-allowed opacity-60',
            className
          )}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
        />
      </div>
    </FormError>
  );
}
