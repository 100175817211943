import { mergeClasses } from '@expo/styleguide';
import Image, { ImageProps } from 'next/image';

export function Figure({ width, height, src, onClick, className, alt = '', ...rest }: ImageProps) {
  return (
    <Image
      unoptimized
      alt={alt}
      src={src}
      width={width}
      height={height}
      className={mergeClasses(
        'relative inline-block flex-shrink-0 object-cover decoration-0',
        onClick && 'cursor-pointer'
      )}
      style={{ width, height }}
      {...rest}
    />
  );
}
