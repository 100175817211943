// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
export type GitHubBuildTriggerDataFragment = { __typename?: 'GitHubBuildTrigger', id: string, isActive: boolean, type: Types.GitHubBuildTriggerType, createdAt: any, updatedAt: any, buildProfile: string, lastRunAt?: any | null, platform: Types.AppPlatform, sourcePattern: string, targetPattern?: string | null, autoSubmit: boolean, submitProfile?: string | null, environment?: Types.EnvironmentVariableEnvironment | null, executionBehavior: Types.GitHubBuildTriggerExecutionBehavior, lastRunStatus?: Types.GitHubBuildTriggerRunStatus | null, lastRunErrorCode?: string | null, lastRunErrorMessage?: string | null, lastRunBuild?: { __typename?: 'Build', id: string } | null };

export const GitHubBuildTriggerDataFragmentDoc = gql`
    fragment GitHubBuildTriggerData on GitHubBuildTrigger {
  id
  isActive
  type
  createdAt
  updatedAt
  buildProfile
  lastRunAt
  platform
  sourcePattern
  targetPattern
  autoSubmit
  submitProfile
  environment
  executionBehavior
  lastRunStatus
  lastRunBuild {
    id
  }
  lastRunErrorCode
  lastRunErrorMessage
}
    `;