import { ApolloClient } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import invariant from 'invariant';

import * as Analytics from '~/common/analytics';
import type { APIV2Client } from '~/common/api-v2-client';
import { saveSessionData } from '~/common/auth-manager';
import { logoutAsync } from '~/common/handlers/authentication';
import {
  SSOAuthConfiguration,
  SSOAuthRequestData,
  SSOCallbackParams,
} from '~/common/sso-auth-flow-manager';
import {
  AccountSsoConfigurationPublicDataQuery,
  AccountSsoConfigurationPublicDataQueryDocument,
} from '~/graphql/queries/AccountSSOConfigurationPublicDataQuery.query.generated';
import { CurrentUserActorAnalyticsDocument } from '~/graphql/queries/CurrentUserActorAnalyticsQuery.query.generated';
import { CurrentUserActorAnalyticsQuery } from '~/graphql/types.generated';

export interface SSOLoginRequest {
  organizationName: string;
  authRequestData: SSOAuthRequestData;
  callbackParams: SSOCallbackParams;
}

export interface SSOSignupRequest extends SSOLoginRequest {
  username: string;
}

export const handleSSOSignupAsync = async (
  apiV2Client: APIV2Client,
  client: ApolloClient<any>,
  ssoSignupRequest: SSOSignupRequest
) => {
  // eslint-disable-next-line no-console
  console.log(
    'handleSSOSignupAsync args: ',
    'apiV2Client: ',
    apiV2Client,
    ' client: ',
    client,
    ', ssoSignupRequest: ',
    ssoSignupRequest
  );

  await logoutAsync(apiV2Client, { client, source: 'sso_signup' });

  const createSSOUserAndSessionResult = await apiV2Client.sendUnauthenticatedApiV2RequestAsync<{
    sessionSecret: string;
  }>('auth/create-sso-user-and-session', {
    body: {
      username: ssoSignupRequest.username,
      organizationName: ssoSignupRequest.organizationName,
      authRequestData: ssoSignupRequest.authRequestData,
      callbackParams: ssoSignupRequest.callbackParams,
    },
  });

  saveSessionData(createSSOUserAndSessionResult.sessionSecret);

  const currentUserActorAnalyticsResult = await client.query<CurrentUserActorAnalyticsQuery>({
    query: CurrentUserActorAnalyticsDocument,
    fetchPolicy: 'network-only',
    context: {
      headers: { 'expo-session': createSSOUserAndSessionResult.sessionSecret },
    },
  });
  const currentUserActor = currentUserActorAnalyticsResult.data.meUserActor;
  if (!currentUserActor) {
    throw new Error('User fetch after sign up failed');
  }

  invariant(currentUserActor?.__typename === 'SSOUser', 'Logged in user must have type SSOUser');
  Analytics.identify(currentUserActor.id, {
    username: currentUserActor.username,
  });
  Analytics.track(Analytics.events.USER_CREATED_ACCOUNT, { id: currentUserActor.id });
  Analytics.gtagEvent({
    action: Analytics.googleAnalyticsEvents.SIGN_UP,
    category: 'Form Submission',
    label: 'Sign Up',
  });
  Analytics.googleAdwordsEvent({
    trackingEventId: Analytics.googleAdwordsEventIds.SIGN_UP_FORM_SUBMISSION,
    googleAdsId: Analytics.googleAdWordsTagGlobalTagId,
  });

  return createSSOUserAndSessionResult.sessionSecret;
};

export const handleSSOLoginAsync = async (
  apiV2Client: APIV2Client,
  client: ApolloClient<any>,
  ssoLoginRequest: SSOLoginRequest
) => {
  // eslint-disable-next-line no-console
  console.log(
    'handleSSOLoginAsync args: ',
    'apiV2Client: ',
    apiV2Client,
    ' client: ',
    client,
    ', ssoLoginRequest: ',
    ssoLoginRequest
  );

  await logoutAsync(apiV2Client, { client, source: 'sso_login' });

  const result = await apiV2Client.sendUnauthenticatedApiV2RequestAsync<{
    sessionSecret: string;
  }>('auth/login-sso-user', {
    body: {
      organizationName: ssoLoginRequest.organizationName,
      authRequestData: ssoLoginRequest.authRequestData,
      callbackParams: ssoLoginRequest.callbackParams,
    },
  });

  saveSessionData(result.sessionSecret);

  const currentUserActorAnalyticsResult = await client.query<CurrentUserActorAnalyticsQuery>({
    query: CurrentUserActorAnalyticsDocument,
    fetchPolicy: 'network-only',
    context: {
      headers: { 'expo-session': result.sessionSecret },
    },
  });
  const currentUserActor = currentUserActorAnalyticsResult.data.meUserActor;
  if (!currentUserActor) {
    throw new Error('User fetch after sign up failed');
  }

  invariant(currentUserActor?.__typename === 'SSOUser', 'Logged in user must have type SSOUser');
  Analytics.identify(currentUserActor.id, {
    username: currentUserActor.username,
  });
  Analytics.track(Analytics.events.USER_LOGGED_IN, {
    id: currentUserActor.id,
  });

  return result.sessionSecret;
};

export const getAccountSSOConfigurationAsync = async (
  client: ApolloClient<any>,
  {
    accountName,
  }: {
    accountName: string;
  }
): Promise<SSOAuthConfiguration> => {
  try {
    const result = await client.query<AccountSsoConfigurationPublicDataQuery>({
      query: AccountSsoConfigurationPublicDataQueryDocument,
      variables: { accountName },
    });

    const { authorizationUrl } =
      result.data.accountSSOConfigurationPublicData.publicDataByAccountName;
    return { authorizationUrl };
  } catch (error) {
    const { graphQLErrors } = error as ErrorResponse;
    if (graphQLErrors) {
      // eslint-disable-next-line no-console
      console.log('AccountSSOConfig graphQL: ' + graphQLErrors[0].message);
      throw new Error(graphQLErrors[0].message);
    } else {
      // eslint-disable-next-line no-console
      console.log('AccountSSOConfig error: ' + (error as Error).message);
      throw error;
    }
  }
};

export const isUserNameAvailableAsync = async (
  apiV2Client: APIV2Client,
  { username }: { username: string }
) => {
  const result = await apiV2Client.sendUnauthenticatedApiV2RequestAsync<{
    isUsernameAvailable: boolean;
  }>('auth/is-username-available', {
    method: 'GET',
    searchParams: { username },
  });
  // eslint-disable-next-line no-console
  console.log(
    'isUserNameAvailableAsync result: ',
    username,
    ' result: ',
    result.isUsernameAvailable
  );
  return result.isUsernameAvailable;
};

export const handleSSOUpgradeSudoParamsAsync = async (apiV2Client: APIV2Client) => {
  return await apiV2Client.sendAuthenticatedApiV2RequestAsync<{
    authorizationUrl: string;
    organizationName: string;
  }>('auth/upgrade-sso-user', {
    method: 'GET',
  });
};

export const handleSSOUpgradeSudoAsync = async (
  apiV2Client: APIV2Client,
  ssoLoginRequest: SSOLoginRequest
) => {
  return await apiV2Client.sendAuthenticatedApiV2RequestAsync<{
    status: 'success';
  }>('auth/upgrade-sso-user', {
    body: {
      authRequestData: ssoLoginRequest.authRequestData,
      callbackParams: ssoLoginRequest.callbackParams,
    },
  });
};
