import type { ReactNode } from 'react';

import { LABEL, CALLOUT } from '~/ui/components/text';

type LabelProps = {
  title?: ReactNode;
  description?: ReactNode;
  htmlFor?: string;
  icon?: ReactNode;
};

export function Label({ title, description, htmlFor, icon }: LabelProps) {
  return (
    <label className="mb-2 block" htmlFor={htmlFor}>
      {(title || icon) && (
        <div className="flex items-center">
          {icon && <div className="mr-1.5">{icon}</div>}
          <LABEL>{title}</LABEL>
        </div>
      )}
      {description && (
        <CALLOUT theme="secondary" className="mt-1">
          {description}
        </CALLOUT>
      )}
    </label>
  );
}
