import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';
import FeatureGateOverrides from '../FeatureGateOverrides';

export class EASInsightsEnableDeploymentInsights extends FeatureGate {
  private readonly accountIds: string[];

  constructor(
    serverValue: boolean | undefined,
    featureGateOverrides: FeatureGateOverrides,
    { accountIds }: { accountIds: string[] }
  ) {
    super(serverValue, featureGateOverrides);
    this.accountIds = accountIds;
  }

  protected getAccounts(): string[] {
    return this.accountIds;
  }

  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.EAS_INSIGHTS_ENABLE_DEPLOYMENT_INSIGHTS;
  }
}

export function useEASInsightsEnableDeploymentInsights() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.EAS_INSIGHTS_ENABLE_DEPLOYMENT_INSIGHTS] ?? defaultGate;
}
