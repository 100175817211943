import { useContext, createContext } from 'react';

import { PageProps } from '~/scenes/_app/helpers';

export const PagePropsContext = createContext<PageProps | null>(null);

export function usePageProps(): PageProps {
  const context = useContext(PagePropsContext);

  if (context === null) {
    throw new Error('usePageProps can only be used in a PagePropsContext.Provider tree');
  }

  if (process.env.DEPLOYMENT_ENVIRONMENT === 'development' && !context.isServerSideRendered) {
    throw new Error('usePageProps must be used in a server-side rendered page');
  }

  return context;
}

PagePropsContext.displayName = 'PagePropsContext';
