import { mergeClasses } from '@expo/styleguide';
import { useRouter } from 'next/compat/router';

import { Logo } from '~/ui/components/Navigation/Logo';

import { ROUTES_WITHOUT_BORDER } from '../LoggedOutNavigation';

export function BarebonesNavigation() {
  const router = useRouter();

  const shouldIncludeBorder = router?.route ? !ROUTES_WITHOUT_BORDER.includes(router.route) : true;

  return (
    <nav>
      <div
        className={mergeClasses(
          'top-0 z-20 grid h-15 grid-cols-[1fr_auto] items-center gap-4 border-b border-transparent bg-default px-4',
          shouldIncludeBorder && 'border-default'
        )}>
        <Logo />
      </div>
    </nav>
  );
}
