import { mergeClasses } from '@expo/styleguide';
import { AndroidIcon } from '@expo/styleguide-icons/custom/AndroidIcon';
import { AppleIcon } from '@expo/styleguide-icons/custom/AppleIcon';
import { BuildIcon } from '@expo/styleguide-icons/custom/BuildIcon';
import { Cube02Icon } from '@expo/styleguide-icons/outline/Cube02Icon';
import { AlertTriangleSolidIcon } from '@expo/styleguide-icons/solid/AlertTriangleSolidIcon';
import { ComponentType, HTMLAttributes } from 'react';

import { BuildStatus } from '~/graphql/types.generated';
import { CALLOUT, FOOTNOTE } from '~/ui/components/text';

import { StatusIndicator } from '../StatusIndicator';

export type TagProps = {
  title?: string;
  icon?: TagIcon;
  customIcon?: ComponentType<HTMLAttributes<SVGSVGElement>>;
  className?: string;
  small?: boolean;
  theme?: TagTheme;
  testID?: string;
};

export type TagTheme = 'default' | 'blue' | 'red' | 'green' | 'yellow' | 'bare';

const icons = {
  Android: (props: HTMLAttributes<SVGSVGElement>) => <AndroidIcon {...props} />,
  Apple: (props: HTMLAttributes<SVGSVGElement>) => <AppleIcon {...props} />,
  Build: (props: HTMLAttributes<SVGSVGElement>) => <BuildIcon {...props} />,
  Channel: (props: HTMLAttributes<SVGSVGElement>) => <Cube02Icon {...props} />,
  Error: () => <StatusIndicator status={BuildStatus.Errored} />,
  New: () => <StatusIndicator status={BuildStatus.New} />,
  Queued: () => <StatusIndicator status={BuildStatus.InQueue} />,
  Canceled: () => <StatusIndicator status={BuildStatus.Canceled} />,
  Success: () => <StatusIndicator status={BuildStatus.Finished} />,
  Warning: ({ color, ...props }: HTMLAttributes<SVGSVGElement>) => (
    <AlertTriangleSolidIcon {...props} />
  ),
};

export type TagIcon = keyof typeof icons;

function getContainerStyle(tagTheme?: TagTheme) {
  switch (tagTheme) {
    case 'blue':
      return 'bg-info';
    case 'red':
      return 'bg-danger';
    case 'green':
      return 'bg-success';
    case 'yellow':
      return 'bg-warning';
    case 'bare':
      return 'bg-default';
    case 'default':
    default:
      return 'bg-element';
  }
}

function getIconFill(tagTheme?: TagTheme) {
  switch (tagTheme) {
    case 'blue':
      return 'text-info';
    case 'red':
      return 'text-danger';
    case 'green':
      return 'text-success';
    case 'yellow':
      return 'text-warning';
    case 'bare':
      return 'text-default';
    case 'default':
    default:
      return 'text-icon-default';
  }
}

function getLabelStyle(tagTheme?: TagTheme) {
  return !tagTheme || tagTheme === 'default' ? 'text-secondary' : getIconFill(tagTheme);
}

export function Tag({
  title,
  icon,
  customIcon,
  className,
  small,
  theme: tagTheme,
  testID,
}: TagProps) {
  const TagIcon = customIcon ?? icons[icon as TagIcon];
  const LabelElement = small ? FOOTNOTE : CALLOUT;

  return (
    <div
      data-testid={testID}
      className={mergeClasses(
        'inline-flex h-[30px] items-center gap-2 rounded-xl px-3',
        small && 'h-6 gap-1 px-2.5',
        getContainerStyle(tagTheme),
        getLabelStyle(tagTheme),
        className
      )}>
      {TagIcon && (
        <div
          className={mergeClasses(
            'relative flex items-center justify-center',
            small && 'scale-90'
          )}>
          <TagIcon className={mergeClasses('icon-sm', getIconFill(tagTheme))} />
        </div>
      )}
      {title && (
        <LabelElement tag="span" weight="medium" className="text-color-inherit">
          {title}
        </LabelElement>
      )}
    </div>
  );
}
