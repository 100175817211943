import { useState, createContext, useContext, PropsWithChildren } from 'react';

type AuthFormContextT = {
  email?: string;
  username?: string;
  setEmailOrUsername: (emailOrUsername: string) => void;
};

export const AuthFormContext = createContext<AuthFormContextT>({
  email: undefined,
  username: undefined,
  setEmailOrUsername: (_: string) => {},
});

export function AuthFormContextProvider({ children }: PropsWithChildren) {
  const [email, setEmail] = useState<string | undefined>();
  const [username, setUsername] = useState<string | undefined>();

  function setEmailOrUsername(emailOrUsername: string) {
    if (emailOrUsername.includes('@')) {
      setEmail(emailOrUsername);
      setUsername(undefined);
    } else {
      setUsername(emailOrUsername);
      setEmail(undefined);
    }
  }

  return (
    <AuthFormContext.Provider
      value={{
        email,
        username,
        setEmailOrUsername,
      }}>
      {children}
    </AuthFormContext.Provider>
  );
}

export function useAuthFormContext() {
  return useContext(AuthFormContext);
}
