import { useState, useEffect } from 'react';

export function useColorScheme() {
  const [colorScheme, setColorScheme] = useState<'light' | 'dark'>('light');

  useEffect(function setupColorSchemeChangeListener() {
    function onDarkModeDetection(event: MediaQueryListEvent) {
      setColorScheme(event.matches ? 'dark' : 'light');
    }

    if (typeof window !== 'undefined') {
      setColorScheme(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

      window.matchMedia('(prefers-color-scheme: dark)').addListener(onDarkModeDetection);
    }

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeListener(onDarkModeDetection);
    };
  }, []);

  return colorScheme;
}
