import { mergeClasses } from '@expo/styleguide';
import { StatusCanceledIcon } from '@expo/styleguide-icons/custom/StatusCanceledIcon';
import { StatusFailureIcon } from '@expo/styleguide-icons/custom/StatusFailureIcon';
import { StatusNewIcon } from '@expo/styleguide-icons/custom/StatusNewIcon';
import { StatusSuccessIcon } from '@expo/styleguide-icons/custom/StatusSuccessIcon';
import { StatusWaitingIcon } from '@expo/styleguide-icons/custom/StatusWaitingIcon';
import type { HTMLAttributes } from 'react';

import {
  BuildStatus,
  GitHubJobRunTrigger,
  GitHubJobRunTriggerRunStatus,
  JobRunStatus,
  SubmissionStatus,
} from '~/graphql/types.generated';
import { CogActivityIndicator } from '~/ui/components/CogActivityIndicator';

type Props = HTMLAttributes<HTMLDivElement> & {
  status: BuildStatus | SubmissionStatus | GitHubJobRunTrigger['lastRunStatus'] | JobRunStatus;
  isExpired?: boolean;
};

const ICON_CLASSES = 'relative top-[-18px]';

function getIconElement(
  status: BuildStatus | SubmissionStatus | GitHubJobRunTrigger['lastRunStatus'] | JobRunStatus,
  isExpired?: boolean
) {
  switch (status) {
    case SubmissionStatus.AwaitingBuild:
    case BuildStatus.New:
    case JobRunStatus.New:
    case null:
    case undefined:
      return (
        <StatusNewIcon
          data-testid="status-new"
          title="New"
          className={mergeClasses(ICON_CLASSES, 'text-icon-tertiary')}
        />
      );
    case SubmissionStatus.InQueue:
    case BuildStatus.InQueue:
    case JobRunStatus.InQueue:
      return (
        <StatusWaitingIcon
          className={mergeClasses(ICON_CLASSES, 'text-icon-warning')}
          data-testid="status-queued"
          title="Waiting"
        />
      );
    case BuildStatus.InProgress:
    case SubmissionStatus.InProgress:
    case JobRunStatus.InProgress:
      return (
        <CogActivityIndicator
          className={mergeClasses(ICON_CLASSES, 'm-0.5')}
          iconClassName="group-hover:bg-element"
          testID="status-building"
          title="In progress"
        />
      );
    case BuildStatus.PendingCancel:
    case BuildStatus.Canceled:
    case SubmissionStatus.Canceled:
    case JobRunStatus.PendingCancel:
    case JobRunStatus.Canceled:
      return (
        <StatusCanceledIcon
          className={mergeClasses(
            ICON_CLASSES,
            isExpired ? 'text-icon-quaternary' : 'text-palette-gray9'
          )}
          data-testid="status-cancelled"
          title="Canceled"
        />
      );
    case SubmissionStatus.Finished:
    case BuildStatus.Finished:
    case GitHubJobRunTriggerRunStatus.Success:
    case JobRunStatus.Finished:
      return (
        <StatusSuccessIcon
          className={mergeClasses(
            ICON_CLASSES,
            isExpired ? 'text-icon-quaternary' : 'text-icon-success'
          )}
          data-testid="status-finished"
          title="Finished"
        />
      );
    case SubmissionStatus.Errored:
    case BuildStatus.Errored:
    case GitHubJobRunTriggerRunStatus.Errored:
    case JobRunStatus.Errored:
      return (
        <StatusFailureIcon
          className={mergeClasses(
            ICON_CLASSES,
            isExpired ? 'text-icon-quaternary' : 'text-icon-danger'
          )}
          data-testid="status-failed"
          title="Failed"
        />
      );
  }
}

export function StatusIndicator({ status, isExpired, className, ...rest }: Props) {
  return (
    <div className={mergeClasses('relative inline-block size-5', className)} {...rest}>
      <div className="ml-0.5 mt-0.5 size-4 rounded-full bg-default" />
      {getIconElement(status, isExpired)}
    </div>
  );
}
