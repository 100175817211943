import { GetServerSidePropsContext } from 'next';

import { Snack } from '~/graphql/types.generated';

const ASSET_PREFIX = process.env.ASSET_PREFIX ?? '';
const CACHE_BUSTER = process.env.RELEASE_ID ?? null;

export const getSnackUrl = (snack?: Pick<Snack, 'fullName'>) => {
  return `${process.env.SNACK_SERVER_URL}/${snack?.fullName ?? ''}`;
};

export const getAssetUrl = (url: string) => {
  return `${ASSET_PREFIX}${url}${CACHE_BUSTER ? `?${CACHE_BUSTER}` : ''}`;
};

export const redirect = (
  location: string,
  ctx: GetServerSidePropsContext,
  { permanent }: { permanent?: boolean } = {}
) => {
  const currentPathname = !ctx.req
    ? window.location.pathname
    : (ctx.req as any)._parsedUrl?.pathname;

  // Prevent redirect loops
  if (currentPathname === location) {
    return;
  }

  if (ctx.res) {
    ctx.res.writeHead(permanent ? 308 : 307, { Location: location });
    ctx.res.end();
    return;
  }

  if (/^https?:/.test(location)) {
    window.location.href = location;
  } else {
    window.location.pathname = location;
  }
};

export function parseGraphQLError(error: Error) {
  return (error as any)?.graphQLErrors ? (error as any)?.graphQLErrors[0]?.message : error.message;
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
