import type { NextRouter } from 'next/router';

type RouterProps = Pick<NextRouter, 'pathname' | 'asPath'>;

export type LinkResolverOptions = {
  stripQueryParams?: string[];
};

const basePath = 'https://expo.dev';

/**
 * A routing function that determines any additional segments a navigation link should have based on a given pathname
 * Router values are used to determine what (if any) additional url segments should be included in the link based on the current app state
 * For example, if the user is currently on a nested screen like 'settings', we want to include that segment in our link under certain conditions
 * Additional options can be provided to customize the link further:
 *  - stripQueryParams?: string[] - the url.search key values that should be removed from the returned link
 *
 * @param pathname
 * @param routerProps
 * @param options
 * @returns string
 */
function linkResolver(
  pathname: string,
  routerProps?: RouterProps | null,
  options: LinkResolverOptions = {}
) {
  if (!routerProps) {
    return '';
  }

  const url = new URL(routerProps.asPath, basePath);

  const pathSegments = pathname.split('/').filter(Boolean);
  const tokenSegments = routerProps.pathname.split('/').filter(Boolean);
  const currentPathSegments = url.pathname.split('/').filter(Boolean);

  // if currentPath has less segments than href, we can say href has greater specificity and should know what its doing
  if (pathSegments.length > currentPathSegments.length) {
    return pathname;
  }

  const tokenIndices = [];

  for (let i = 0; i < currentPathSegments.length; i++) {
    const tokenSegment = tokenSegments[i];
    if (tokenSegment) {
      const isToken = tokenSegment.startsWith('[');

      if (isToken) {
        tokenIndices.push(i);
      }
    }
  }

  // try to resolve any non-token slugs that href might want to include
  let segments: string[] = [];
  let remainingPathSegments: string[] = [];

  for (let i = 0; i < pathSegments.length; i++) {
    const hrefSegment = pathSegments[i];
    const pathSegment = currentPathSegments[i];

    const isTokenSegment = tokenIndices.includes(i);

    // href diverges from path and its not a wildcard token - favour href
    if (hrefSegment !== pathSegment && !isTokenSegment) {
      return pathname;
    }

    segments.push(hrefSegment);

    // append as many non-token slugs as possible to href
    if (i === pathSegments.length - 1) {
      const nextTokenIndex = tokenIndices.find((index) => index > i);
      remainingPathSegments = currentPathSegments.slice(i + 1, nextTokenIndex);
    }
  }

  segments = [...segments, ...remainingPathSegments];

  url.pathname = `/${segments.join('/')}`;

  if (typeof window !== 'undefined') {
    url.hash = window.location.hash;
    url.search = window.location.search;

    if (options.stripQueryParams) {
      options.stripQueryParams.forEach((paramName) => {
        url.searchParams.delete(paramName);
      });
    }
  }

  return `${url.pathname}${url.search}${url.hash}`;
}

export { linkResolver };
