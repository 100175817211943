// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { CurrentUserActorAnalyticsDataFragmentDoc } from '../fragments/CurrentUserActorAnalyticsData.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserActorAnalyticsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserActorAnalyticsQuery = { __typename: 'RootQuery', meUserActor?: { __typename: 'SSOUser', id: string, username: string } | { __typename: 'User', email: string, id: string, username: string } | null };


export const CurrentUserActorAnalyticsDocument = gql`
    query CurrentUserActorAnalytics {
  __typename
  meUserActor {
    ...CurrentUserActorAnalyticsData
  }
}
    ${CurrentUserActorAnalyticsDataFragmentDoc}`;
export function useCurrentUserActorAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserActorAnalyticsQuery, CurrentUserActorAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserActorAnalyticsQuery, CurrentUserActorAnalyticsQueryVariables>(CurrentUserActorAnalyticsDocument, options);
      }
export function useCurrentUserActorAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserActorAnalyticsQuery, CurrentUserActorAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserActorAnalyticsQuery, CurrentUserActorAnalyticsQueryVariables>(CurrentUserActorAnalyticsDocument, options);
        }
export function useCurrentUserActorAnalyticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserActorAnalyticsQuery, CurrentUserActorAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserActorAnalyticsQuery, CurrentUserActorAnalyticsQueryVariables>(CurrentUserActorAnalyticsDocument, options);
        }
export type CurrentUserActorAnalyticsQueryHookResult = ReturnType<typeof useCurrentUserActorAnalyticsQuery>;
export type CurrentUserActorAnalyticsLazyQueryHookResult = ReturnType<typeof useCurrentUserActorAnalyticsLazyQuery>;
export type CurrentUserActorAnalyticsSuspenseQueryHookResult = ReturnType<typeof useCurrentUserActorAnalyticsSuspenseQuery>;
export type CurrentUserActorAnalyticsQueryResult = Apollo.QueryResult<CurrentUserActorAnalyticsQuery, CurrentUserActorAnalyticsQueryVariables>;
export function refetchCurrentUserActorAnalyticsQuery(variables?: CurrentUserActorAnalyticsQueryVariables) {
      return { query: CurrentUserActorAnalyticsDocument, variables: variables }
    }