import isString from 'lodash/isString';
import toArray from 'lodash/toArray';

const HREF_REGULAR_EXPRESSION = /https?:\/\/.+/;

export const isEmptyOrNull = (text?: string | null) => {
  return !text?.trim();
};

export const mutateStringToUrl = (url: string) => {
  if (isEmptyOrNull(url)) {
    return url;
  }

  const lowercaseUrl = url.toLowerCase();

  if (!HREF_REGULAR_EXPRESSION.test(lowercaseUrl)) {
    return 'http://' + lowercaseUrl;
  }

  return lowercaseUrl;
};

export const strip = (stringToStrip: string, text: string) => {
  if (isEmptyOrNull(text)) {
    return null;
  }

  return text.replace(stringToStrip, '');
};

export const stripProtocol = (text: string) => {
  if (isEmptyOrNull(text)) {
    return null;
  }

  return text.replace(/(^\w+:|^)\/\//, '');
};

export const ellipsize = (text: string, length: number) => {
  if (isEmptyOrNull(text)) {
    return null;
  }

  return text.substring(0, length) + (text.length > length ? '...' : '');
};

const PLURAL_WORDS: { [word: string]: string | undefined } = {
  concurrency: 'concurrencies',
};

export const pluralize = (word: string, count: number) => {
  const shouldUsePluralWord = count > 1 || count === 0;
  const pluralWord = PLURAL_WORDS[word] ?? `${word}s`;

  return shouldUsePluralWord ? pluralWord : word;
};

export const getRenderedLength = (text: string) => {
  if (isEmptyOrNull(text)) {
    return 0;
  }

  return toArray(text).length;
};

export const capitalizeFirstLetter = (string?: string) => {
  if (!isString(string)) {
    return string;
  }

  return string.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
};
