// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../graphql/types.generated';

import { gql } from '@apollo/client';
export type UserInvitationDataFragment = { __typename?: 'UserInvitation', id: string, email: string, created: any, expires: any, accountName: string, accountProfilePhoto?: string | null, permissions: Array<Types.Permission>, role: Types.Role, isForOrganization: boolean };

export const UserInvitationDataFragmentDoc = gql`
    fragment UserInvitationData on UserInvitation {
  id
  email
  created
  expires
  accountName
  accountProfilePhoto
  permissions
  role
  isForOrganization
}
    `;