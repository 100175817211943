import { LoggedInProps } from '~/scenes/_app/helpers';
import { useAccountSelectorQuery } from '~/ui/components/SidebarNavigation/queries/AccountSelector.query.generated';

import { UserAvatar } from './UserAvatar';

type AdminUserAvatarProps = {
  currentUser: LoggedInProps['currentUser'];
  accountName: string;
};

export function AdminUserAvatar({ currentUser, accountName }: AdminUserAvatarProps) {
  const { data } = useAccountSelectorQuery({ variables: { accountName } });
  if (!data) {
    return null;
  }
  const accounts = data.account.byName.ownerUserActor?.accounts ?? [];

  return <UserAvatar currentUser={currentUser} accounts={accounts} />;
}
