import { useRouter } from 'next/compat/router';
import {
  createContext,
  type PropsWithChildren,
  type Dispatch,
  type SetStateAction,
  useContext,
  useState,
} from 'react';

import { getSelectedSidebarItem, SidebarItem } from '~/ui/components/SidebarNavigation/helpers';

type SidebarCollapsiblesContextType = {
  isProjectSettingsOpen: boolean;
  setIsProjectSettingsOpen: Dispatch<SetStateAction<boolean>>;
  isAccountSettingsOpen: boolean;
  setIsAccountSettingsOpen: Dispatch<SetStateAction<boolean>>;
  isHostingOpen: boolean;
  setIsHostingOpen: Dispatch<SetStateAction<boolean>>;
  isUpdatesOpen: boolean;
  setIsUpdatesOpen: Dispatch<SetStateAction<boolean>>;
  isBuildsOpen: boolean;
  setIsBuildsOpen: Dispatch<SetStateAction<boolean>>;
};

export const SidebarCollapsiblesContext = createContext<SidebarCollapsiblesContextType>({
  isProjectSettingsOpen: true,
  setIsProjectSettingsOpen: () => {},
  isAccountSettingsOpen: true,
  setIsAccountSettingsOpen: () => {},
  isHostingOpen: true,
  setIsHostingOpen: () => {},
  isUpdatesOpen: true,
  setIsUpdatesOpen: () => {},
  isBuildsOpen: true,
  setIsBuildsOpen: () => {},
});

export function SidebarCollapsiblesProvider({ children }: PropsWithChildren) {
  const router = useRouter();

  const selectedSidebarItem = getSelectedSidebarItem(router?.pathname ?? '', null);

  const [isProjectSettingsOpen, setIsProjectSettingsOpen] = useState(
    !!selectedSidebarItem &&
      [
        SidebarItem.PROJECT_OVERVIEW, // open the configuration section by when on the project root page
        SidebarItem.PROJECT_SETTINGS_OVERVIEW,
        SidebarItem.PROJECT_SETTINGS_CREDENTIALS,
        SidebarItem.PROJECT_SETTINGS_SECRETS,
        SidebarItem.PROJECT_SETTINGS_GITHUB,
        SidebarItem.PROJECT_SETTINGS_ENVIRONMENT_VARIABLES,
      ].includes(selectedSidebarItem)
  );
  const [isAccountSettingsOpen, setIsAccountSettingsOpen] = useState(true);
  const [isHostingOpen, setIsHostingOpen] = useState(
    !!selectedSidebarItem &&
      [
        SidebarItem.PROJECT_HOSTING_DEPLOYMENTS,
        SidebarItem.PROJECT_HOSTING_DEPLOYMENTS_OVERVIEW,
        SidebarItem.PROJECT_HOSTING_DEPLOYMENTS_METRICS,
        SidebarItem.PROJECT_HOSTING_DEPLOYMENTS_LOGS,
        SidebarItem.PROJECT_HOSTING_METRICS,
        SidebarItem.PROJECT_HOSTING_CRASHES,
        SidebarItem.PROJECT_HOSTING_REQUESTS,
        SidebarItem.PROJECT_HOSTING_SETTINGS,
      ].includes(selectedSidebarItem)
  );
  const [isBuildsOpen, setIsBuildsOpen] = useState(
    !!selectedSidebarItem &&
      [
        SidebarItem.PROJECT_BUILDS,
        SidebarItem.PROJECT_DEVELOPMENT_BUILDS,
        SidebarItem.PROJECT_SUBMISSIONS,
      ].includes(selectedSidebarItem)
  );
  const [isUpdatesOpen, setIsUpdatesOpen] = useState(
    !!selectedSidebarItem &&
      [
        SidebarItem.PROJECT_DEPLOYMENTS,
        SidebarItem.PROJECT_RUNTIMES,
        SidebarItem.PROJECT_UPDATES,
        SidebarItem.PROJECT_BRANCHES,
        SidebarItem.PROJECT_CHANNELS,
      ].includes(selectedSidebarItem)
  );

  return (
    <SidebarCollapsiblesContext.Provider
      value={{
        isProjectSettingsOpen,
        setIsProjectSettingsOpen,
        isAccountSettingsOpen,
        setIsAccountSettingsOpen,
        isHostingOpen,
        setIsHostingOpen,
        isUpdatesOpen,
        setIsUpdatesOpen,
        isBuildsOpen,
        setIsBuildsOpen,
      }}>
      {children}
    </SidebarCollapsiblesContext.Provider>
  );
}

export function useSidebarCollapsiblesContext() {
  return useContext(SidebarCollapsiblesContext);
}
