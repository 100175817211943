import { mergeClasses } from '@expo/styleguide';
import { Building07Icon } from '@expo/styleguide-icons/outline/Building07Icon';
import { UserCircleSolidIcon } from '@expo/styleguide-icons/solid/UserCircleSolidIcon';
import deburr from 'lodash/deburr';
import Image from 'next/image';
import { ComponentType, HTMLAttributes } from 'react';

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

const AVATAR_BUCKET = 'https://storage.googleapis.com/expo-website-default-avatars-2023';

type Props = {
  id?: string;
  name?: string;
  profilePhoto?: string;
  size?: AvatarSize;
  className?: string;
  isOrganization?: boolean;
  alt?: string;
  FallbackIcon?: ComponentType<HTMLAttributes<SVGSVGElement>>;
};

export function Avatar({
  id,
  profilePhoto,
  size,
  name = '',
  className,
  alt,
  isOrganization = false,
  FallbackIcon = UserCircleSolidIcon,
}: Props) {
  const firstLetter = name?.charAt(0).toLowerCase();

  if (isOrganization) {
    return (
      <div
        className={mergeClasses(
          `flex shrink-0 items-center justify-center rounded-full`,
          getOrganizationColors(firstLetter),
          getAvatarSize(size),
          className
        )}
        role="img">
        <Building07Icon className={mergeClasses('text-[inherit]', getIconSize(size))} />
      </div>
    );
  }

  if (!profilePhoto || !firstLetter) {
    return (
      <div
        className={mergeClasses(
          `flex shrink-0 items-center justify-center rounded-full bg-element`,
          getAvatarSize(size),
          className
        )}
        role="img">
        <FallbackIcon className={mergeClasses('text-icon-secondary', getIconSize(size))} />
      </div>
    );
  }

  let _profilePhoto = profilePhoto;

  if (_profilePhoto.match('gravatar.com')) {
    const letterCandidate = deburr(firstLetter).toLowerCase();
    const finalAssetName = /^[\da-z]$/.test(letterCandidate) ? letterCandidate : 'default';
    const defaultProfilePhoto = `${AVATAR_BUCKET}/${finalAssetName}.png`;
    _profilePhoto = `${profilePhoto}&d=${defaultProfilePhoto}`;
  }

  return (
    <Image
      id={id}
      src={_profilePhoto}
      height={getImageSize(size)}
      width={getImageSize(size)}
      className={mergeClasses(
        `flex shrink-0 items-center justify-center rounded-full bg-element`,
        getAvatarSize(size),
        className
      )}
      alt={alt ?? `${name} account icon`}
    />
  );
}
function getImageSize(size?: AvatarSize) {
  switch (size) {
    case 'xs':
      return 16;
    case 'md':
      return 32;
    case 'lg':
      return 40;
    case 'xl':
      return 56;
    case '2xl':
      return 80;
    case '3xl':
      return 120;
    case 'sm':
    default:
      return 26;
  }
}

function getAvatarSize(size?: AvatarSize) {
  switch (size) {
    case 'xs':
      return 'size-4';
    case 'md':
      return 'size-8';
    case 'lg':
      return 'size-10';
    case 'xl':
      return 'size-[56px]';
    case '2xl':
      return 'size-20';
    case '3xl':
      return 'size-30';
    case 'sm':
    default:
      return 'size-[26px]';
  }
}

function getIconSize(size?: AvatarSize) {
  switch (size) {
    case 'xs':
      return 'icon-xs';
    case 'md':
      return 'icon-sm';
    case 'lg':
      return 'icon-md';
    case 'xl':
      return 'icon-lg';
    case '2xl':
      return 'icon-2xl';
    case '3xl':
      return 'size-12';
    case 'sm':
    default:
      return 'icon-xs';
  }
}

function getOrganizationColors(firstLetter?: string) {
  if (firstLetter?.match(/[a-d]/)) {
    return 'bg-palette-blue5 text-palette-blue12';
  } else if (firstLetter?.match(/[e-h]/)) {
    return 'bg-palette-green5 text-palette-green12';
  } else if (firstLetter?.match(/[i-l]/)) {
    return 'bg-palette-yellow7 text-palette-yellow12';
  } else if (firstLetter?.match(/[m-p]/)) {
    return 'bg-palette-orange5 text-palette-orange12';
  } else if (firstLetter?.match(/[q-t]/)) {
    return 'bg-palette-red5 text-palette-red12';
  } else if (firstLetter?.match(/[u-z]/)) {
    return 'bg-palette-pink5 text-palette-pink12';
  } else {
    return 'bg-palette-purple5 text-palette-purple12';
  }
}
