// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateGitHubBuildTriggerMutationVariables = Types.Exact<{
  githubBuildTriggerData: Types.CreateGitHubBuildTriggerInput;
}>;


export type CreateGitHubBuildTriggerMutation = { __typename?: 'RootMutation', githubBuildTrigger: { __typename?: 'GitHubBuildTriggerMutation', createGitHubBuildTrigger: { __typename?: 'GitHubBuildTrigger', id: string } } };


export const CreateGitHubBuildTriggerDocument = gql`
    mutation CreateGitHubBuildTrigger($githubBuildTriggerData: CreateGitHubBuildTriggerInput!) {
  githubBuildTrigger {
    createGitHubBuildTrigger(githubBuildTriggerData: $githubBuildTriggerData) {
      id
    }
  }
}
    `;
export function useCreateGitHubBuildTriggerMutation(baseOptions?: Apollo.MutationHookOptions<CreateGitHubBuildTriggerMutation, CreateGitHubBuildTriggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGitHubBuildTriggerMutation, CreateGitHubBuildTriggerMutationVariables>(CreateGitHubBuildTriggerDocument, options);
      }
export type CreateGitHubBuildTriggerMutationHookResult = ReturnType<typeof useCreateGitHubBuildTriggerMutation>;
export type CreateGitHubBuildTriggerMutationResult = Apollo.MutationResult<CreateGitHubBuildTriggerMutation>;
export type CreateGitHubBuildTriggerMutationOptions = Apollo.BaseMutationOptions<CreateGitHubBuildTriggerMutation, CreateGitHubBuildTriggerMutationVariables>;