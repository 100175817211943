import { FormEvent } from 'react';

import { Button } from '~/ui/components/Button';
import { DialogContentContainer } from '~/ui/components/Dialog/DialogContentContainer';
import { DialogFooter } from '~/ui/components/Dialog/DialogFooter';
import { Form } from '~/ui/components/form/Form';
import { FormError } from '~/ui/components/form/FormError';
import { FormGroup } from '~/ui/components/form/FormGroup';
import { FormStates } from '~/ui/components/form/FormStates';
import { Input } from '~/ui/components/form/Input';

import { useProjectCreationFormState } from './useProjectCreationFormState';

type ProjectCreationFormProps = {
  accountName: string;
  accountId: string;
  onComplete: (slug: string, id: string) => Promise<void>;
  onRequestClose: () => void;
};

export function ProjectCreationForm({
  accountName,
  accountId,
  onComplete,
  onRequestClose,
}: ProjectCreationFormProps) {
  const { formState, formError, onSubmit, onChange, generatedSlug, slugError } =
    useProjectCreationFormState({
      accountId,
      accountName,
    });
  async function submitFormAsync(event: FormEvent) {
    const data = await onSubmit(event);
    if (!data?.app) {
      return;
    }
    const { app } = data.app.createAppAndGithubRepository;
    await onComplete(app.slug, app.id);
  }

  return (
    <Form disabled={formState !== FormStates.IDLE} onSubmit={submitFormAsync}>
      <DialogContentContainer>
        <FormGroup
          htmlFor="displayName"
          title="Display Name"
          description="A human-readable name for your Expo project.">
          <Input autoFocus id="displayName" onChange={onChange} />
        </FormGroup>
        <FormGroup
          htmlFor="slug"
          title="Slug"
          description="A URL-friendly name for your project that is unique across your account.">
          <Input id="slug" value={generatedSlug} error={slugError} onChange={onChange} />
        </FormGroup>
        <FormError error={formError} />
      </DialogContentContainer>
      <DialogFooter>
        <Button onClick={onRequestClose} theme="quaternary">
          Cancel
        </Button>
        <Button status={formState} type="submit">
          Create
        </Button>
      </DialogFooter>
    </Form>
  );
}
