import Storage from '~/common/storage';

export const SSO_AUTH_FLOW_KEY = 'ssoData';

const DEBUG = false;
const keyNamespace = getSSOAuthFlowStorageKey();
const cookieStorage: Storage = new Storage(keyNamespace, 'cookie');

export function getSSOAuthRequestFields(authConfiguration: SSOAuthConfiguration) {
  const authState = getClientSideRandomBase36String(32);
  const authNonce = getClientSideRandomBase36String(32);
  const authUrl = getAuthorizationUrl(authConfiguration, authState, authNonce);
  return { authUrl, authState, authNonce };
}

function getClientSideRandomBase36String(bytesLength = 32): string {
  if (typeof window === 'undefined') {
    throw new Error('Client-side random string must be generated on the client');
  }

  const randomByteArray = new Uint8Array(bytesLength);
  window.crypto.getRandomValues(randomByteArray);
  let base36String = '';
  for (let i = 0; i < bytesLength; i++) {
    base36String += randomByteArray[i].toString(36);
  }
  return base36String;
}

function getAuthorizationUrl(
  authConfiguration: SSOAuthConfiguration,
  authState: string,
  authNonce: string
): string {
  const authorizationUrl = new URL(authConfiguration.authorizationUrl);
  authorizationUrl.searchParams.set('state', authState);
  authorizationUrl.searchParams.set('nonce', authNonce);
  return authorizationUrl.toString();
}

export function getSSOCallbackParameters(callbackPath: string, base: string): SSOCallbackParams {
  logAuthFlowUrl('Auth Provider callback uri', callbackPath);
  const callbackUrl = new URL(callbackPath, base);

  const callbackParams = {
    code: callbackUrl.searchParams.get('code') ?? '',
    state: callbackUrl.searchParams.get('state') ?? '',
  };

  logCallbackParams(callbackParams);
  return callbackParams;
}

export function getSSOAuthFlowData(key: string): SSOAuthFlowData | null {
  const authFlowDataString = cookieStorage.getItem(key);
  if (authFlowDataString) {
    const authFlowData = JSON.parse(authFlowDataString);
    if (isAuthFlowDataValid(authFlowData.expiresAt)) {
      logAuthFlowCookieAction('Retrieved', authFlowData);
      return authFlowData;
    }
    clearSSOAuthFlowData(key);
    logAuthFlowCookieAction('Found expired');
    return null;
  }
  logAuthFlowCookieAction('Did not find');
  return null;
}

function isAuthFlowDataValid(expiresAt: string) {
  return Date.now() < new Date(expiresAt).getTime();
}

export function saveSSOAuthFlowData(key: string, authFlowData: SSOAuthFlowData) {
  clearSSOAuthFlowData(key);
  cookieStorage.setItem(key, JSON.stringify(authFlowData), {
    expires: new Date(authFlowData.expiresAt).getTime(),
  });
  logAuthFlowCookieAction('Saved', authFlowData);
}

export function clearSSOAuthFlowData(key: string) {
  cookieStorage.removeItem(key);
  logAuthFlowCookieAction('Removed');
}

export function getSSOAuthFlowStorageKey() {
  return process.env.DEPLOYMENT_ENVIRONMENT === 'production'
    ? 'expo.authflow'
    : 'staging.expo.authflow';
}

// Sent to the IdP in the auth request
export type SSOAuthRequestData = {
  authState: string;
  authNonce: string;
};

// Returned in auth response from IdP
export type SSOCallbackParams = {
  code: string;
  state: string;
};

export type SSOAuthConfiguration = {
  authorizationUrl: string;
};

export type SSOAuthFlowData = {
  organizationName?: string;
  username?: string;
  appRedirectUri?: string | string[];
  redirectUri?: string | string[];
  authConfiguration: SSOAuthConfiguration;
  authRequestData: SSOAuthRequestData;
  expiresAt: string;
  sudo?: true;
};

//TODO Remove these debug logging functions and DEBUG once the complete end-to-end
// SSO signup and SSO login are working.

function logAuthFlowUrl(kind: string, url: string) {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('*** ', kind, ': ', url);
  }
}

function logAuthFlowCookieAction(action: string, authFlowData?: SSOAuthFlowData) {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('*** ', action, ' AuthFlowData cookie');
    if (authFlowData) {
      logAuthFlowCookieData(authFlowData);
    }
  }
}

function logCallbackParams(callbackParams: SSOCallbackParams) {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('*** callbackParams', callbackParams);
  }
}

function logAuthFlowCookieData(authFlowData: SSOAuthFlowData) {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('***** AuthFlowData cookie values *****');
    // eslint-disable-next-line no-console
    console.log(
      'organizationName=',
      authFlowData.organizationName,
      ', username=',
      authFlowData.username,
      ', cliRedirectUrl=',
      authFlowData.appRedirectUri
    );
    // eslint-disable-next-line no-console
    console.log('authConfiguration=', authFlowData.authConfiguration);
    // eslint-disable-next-line no-console
    console.log('authRequestData=', authFlowData.authRequestData);
    // eslint-disable-next-line no-console
    console.log('expiresAt=', authFlowData.expiresAt);
  }
}
