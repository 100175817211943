import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';
import { Tier } from '~/common/gating/Tier';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';

export class StripeKillswitchFeatureGate extends FeatureGate {
  protected getFullyRolledOutTiers(): ReadonlySet<Tier> {
    return new Set([]);
  }

  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.STRIPE_KILLSWITCH;
  }
}

export function useStripeKillswitchFeatureGate() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.STRIPE_KILLSWITCH] ?? defaultGate;
}
