import { ParsedUrlQuery } from 'node:querystring';

import { getParamString } from '~/common/getParamString';

export function formatParams(params?: ParsedUrlQuery): Record<string, string> {
  if (!params) {
    return {};
  }

  return Object.keys(params).reduce((acc, paramKey) => {
    return {
      ...acc,
      [paramKey]: getParamString(params[paramKey]),
    };
  }, {});
}
