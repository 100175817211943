import { Tier } from './Tier';

export function getCurrentTier() {
  switch (process.env.DEPLOYMENT_ENVIRONMENT) {
    case 'development':
      return Tier.DEVELOPMENT;
    case 'staging':
      return Tier.STAGING;
    case 'production':
      return Tier.PRODUCTION;
    case 'pull-request':
      return Tier.PULL_REQUEST;
    default:
      return Tier.PRODUCTION;
  }
}
