import { mergeClasses } from '@expo/styleguide';
import { AlertTriangleSolidIcon } from '@expo/styleguide-icons/solid/AlertTriangleSolidIcon';
import { InfoCircleSolidIcon } from '@expo/styleguide-icons/solid/InfoCircleSolidIcon';
import { XSquareSolidIcon } from '@expo/styleguide-icons/solid/XSquareSolidIcon';
import { HTMLAttributes } from 'react';

import { CALLOUT, LABEL } from '~/ui/components/text';

type Props = HTMLAttributes<HTMLDivElement> & {
  title?: string;
  type?: 'info' | 'warning' | 'error' | 'info-light' | 'primary';
};

export function InlineHelp({ title, type = 'info', children, className, ...rest }: Props) {
  return (
    <div
      className={mergeClasses(
        'flex flex-1 flex-col gap-1.5 rounded-md border border-default bg-subtle p-4 text-default',
        type === 'warning' && 'border-warning bg-warning',
        type === 'error' && 'border-danger bg-danger',
        type === 'info-light' && 'bg-default',
        type === 'primary' && 'border-info bg-info',
        className
      )}
      {...rest}>
      <div className="flex items-center gap-1.5">
        {type === 'info' && (
          <>
            <InfoCircleSolidIcon className="min-w-[18px]" />
            <LABEL>{title ?? 'Info'}</LABEL>
          </>
        )}
        {type === 'info-light' && (
          <>
            <InfoCircleSolidIcon className="min-w-[18px]" />
            <LABEL>{title ?? 'Info'}</LABEL>
          </>
        )}
        {type === 'warning' && (
          <>
            <AlertTriangleSolidIcon className="min-w-[18px] text-warning" />
            <LABEL theme="warning">{title ?? 'Warning'}</LABEL>
          </>
        )}
        {type === 'error' && (
          <>
            <XSquareSolidIcon className="min-w-[18px] text-danger" />
            <LABEL theme="danger">{title ?? 'Error'}</LABEL>
          </>
        )}
        {type === 'primary' && (
          <>
            <InfoCircleSolidIcon className="min-w-[18px] text-icon-info" />
            <LABEL theme="info">{title ?? 'Info'}</LABEL>
          </>
        )}
      </div>
      {children && typeof children === 'string' ? <CALLOUT>{children}</CALLOUT> : children}
    </div>
  );
}
