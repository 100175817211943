import { NextPageContext } from 'next';

import Document from '~/ui/components/Document';
import { ErrorPage as ErrorPageComponent } from '~/ui/components/Error/ErrorPage';

type Props = {
  statusCode: number;
};

function ErrorPage({ statusCode }: Props) {
  return (
    <Document meta={{ name: 'error' }}>
      <ErrorPageComponent statusCode={statusCode} />
    </Document>
  );
}

ErrorPage.getInitialProps = (ctx: NextPageContext) => {
  const { err, req, res } = ctx;
  if (typeof window === 'undefined' && err) {
    (req as any).log.error(err);
  }
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorPage;
