import { ParsedUrlQuery } from 'node:querystring';
import parseUrl from 'parse-url';

import { getParamString } from '~/common/getParamString';

export type RedirectOptions = {
  query: {
    app_redirect_uri?: string | string[];
    redirect_uri?: string | string[];
  };
  username?: string;
  sessionSecret?: string;
  organizationName?: string;
};

/**
 * Whether the specified app_redirect_uri is allowed.
 *
 * Expo Go always has an app_redirect_uri = expauth://auth
 * Dev clients have an app_redirect_uri = <any_scheme>://auth
 * eas-cli and expo cli have app_redirect_uri = http://localhost:<port>//auth/callback
 * So we can only check that auth is the path or that the URI matches http://localhost:<port>/auth/callback.
 */
export function isAllowedAppRedirectUri(uri: string) {
  try {
    const { protocol, resource, port, pathname, query } = parseUrl(decodeURIComponent(uri));

    if (
      !['http', 'https'].includes(protocol) &&
      (resource === 'auth' || pathname.endsWith('auth'))
    ) {
      return true;
    }

    if (
      protocol === 'http' &&
      resource === 'localhost' &&
      pathname === '/auth/callback' &&
      port &&
      Object.keys(query).length === 0
    ) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
  return false;
}

/**
 * Whether the specified redirect URI is a relative path on this site.
 */
export function isInternalRedirectUri(uri: string) {
  return uri.startsWith('/') && !uri.startsWith('//');
}

/**
 * Whether the specified redirect URI is a known Expo domain.
 */
export function isExpoRedirectUri(uri: string) {
  try {
    const { protocol, resource } = parseUrl(decodeURIComponent(uri));
    return ['http', 'https'].includes(protocol) && /^.*\.?expo.(io|dev|test)$/.test(resource);
  } catch {
    return false;
  }
}

export function getRedirectPath({
  query,
  username,
  sessionSecret,
  organizationName,
}: RedirectOptions) {
  const appRedirectUri = decodeURIComponent(getParamString(query.app_redirect_uri));
  const redirectUri = getParamString(query.redirect_uri);

  if (appRedirectUri && isAllowedAppRedirectUri(appRedirectUri)) {
    return `${appRedirectUri}?username_or_email=${username?.toLowerCase()}&session_secret=${sessionSecret}`;
  } else if (
    redirectUri &&
    (isInternalRedirectUri(redirectUri) || isExpoRedirectUri(redirectUri))
  ) {
    return redirectUri;
  } else if (organizationName) {
    return `/accounts/${encodeURIComponent(organizationName.toLowerCase())}`;
  } else {
    return `/accounts/${username?.toLowerCase()}`;
  }
}

export function appendRedirectParams(
  path: string,
  query?: ParsedUrlQuery,
  extraParams?: Record<string, string>
) {
  const redirectUri = query?.redirect_uri && getParamString(query?.redirect_uri);
  const appRedirectUri = query?.app_redirect_uri && getParamString(query?.app_redirect_uri);
  const isOnboarding = query?.is_onboarding && getParamString(query?.is_onboarding);

  const paramsString = new URLSearchParams(
    removeUndefinedKeys({
      redirect_uri: redirectUri,
      app_redirect_uri: appRedirectUri,
      is_onboarding: isOnboarding,
      ...extraParams,
    })
  ).toString();

  return paramsString.length ? `${path}?${paramsString}` : path;
}

function removeUndefinedKeys(obj: object) {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => !!value));
}
