// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { AppDataWithRepoFragmentDoc } from '../fragments/AppDataWithRepo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppsPaginatedQueryVariables = Types.Exact<{
  accountName: Types.Scalars['String']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.AccountAppsFilterInput>;
}>;


export type AppsPaginatedQuery = { __typename?: 'RootQuery', account: { __typename?: 'AccountQuery', byName: { __typename?: 'Account', id: string, appsPaginated: { __typename?: 'AccountAppsConnection', edges: Array<{ __typename?: 'AccountAppsEdge', node: { __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null, metadata: { __typename?: 'GitHubRepositoryMetadata', githubRepoName: string, githubRepoOwnerName: string } } | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } } };


export const AppsPaginatedQueryDocument = gql`
    query AppsPaginatedQuery($accountName: String!, $after: String, $first: Int, $before: String, $last: Int, $filter: AccountAppsFilterInput) {
  account {
    byName(accountName: $accountName) {
      id
      appsPaginated(
        after: $after
        first: $first
        before: $before
        last: $last
        filter: $filter
      ) {
        edges {
          node {
            ...AppDataWithRepo
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
}
    ${AppDataWithRepoFragmentDoc}`;
export function useAppsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<AppsPaginatedQuery, AppsPaginatedQueryVariables> & ({ variables: AppsPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppsPaginatedQuery, AppsPaginatedQueryVariables>(AppsPaginatedQueryDocument, options);
      }
export function useAppsPaginatedQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppsPaginatedQuery, AppsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppsPaginatedQuery, AppsPaginatedQueryVariables>(AppsPaginatedQueryDocument, options);
        }
export function useAppsPaginatedQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppsPaginatedQuery, AppsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppsPaginatedQuery, AppsPaginatedQueryVariables>(AppsPaginatedQueryDocument, options);
        }
export type AppsPaginatedQueryHookResult = ReturnType<typeof useAppsPaginatedQuery>;
export type AppsPaginatedQueryLazyQueryHookResult = ReturnType<typeof useAppsPaginatedQueryLazyQuery>;
export type AppsPaginatedQuerySuspenseQueryHookResult = ReturnType<typeof useAppsPaginatedQuerySuspenseQuery>;
export type AppsPaginatedQueryQueryResult = Apollo.QueryResult<AppsPaginatedQuery, AppsPaginatedQueryVariables>;
export function refetchAppsPaginatedQuery(variables: AppsPaginatedQueryVariables) {
      return { query: AppsPaginatedQueryDocument, variables: variables }
    }