// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { AccountsDataFragmentDoc } from './AccountsData.fragment.generated';
import { UserInvitationDataFragmentDoc } from '../../scenes/Settings/MembersScene/queries/UserInvitationData.fragment.generated';
export type CurrentUserData_SsoUser_Fragment = { __typename: 'SSOUser', id: string, username: string, profilePhoto: string, firstName?: string | null, lastName?: string | null, isExpoAdmin: boolean, bestContactEmail?: string | null, featureGates: any, primaryAccount: { __typename?: 'Account', id: string, name: string }, accounts: Array<{ __typename: 'Account', id: string, name: string, isDisabled: boolean, createdAt: any, ownerUserActor?: { __typename: 'SSOUser', id: string, fullName?: string | null, profilePhoto: string, username: string } | { __typename: 'User', email: string, id: string, fullName?: string | null, profilePhoto: string, username: string } | null, viewerUserPermission: { __typename?: 'UserPermission', permissions: Array<Types.Permission>, role: Types.Role, userActor?: { __typename?: 'SSOUser', id: string, created: any, firstName?: string | null, lastName?: string | null, profilePhoto: string, displayName: string, username: string, bestContactEmail?: string | null } | { __typename?: 'User', email: string, id: string, created: any, firstName?: string | null, lastName?: string | null, profilePhoto: string, displayName: string, username: string, bestContactEmail?: string | null } | null }, subscription?: { __typename?: 'SubscriptionDetails', name?: string | null, id: string, planId?: string | null, status?: string | null, nextInvoice?: any | null } | null }>, githubUser?: { __typename?: 'GitHubUser', id: string, githubUserIdentifier: string } | null, preferences: { __typename?: 'UserPreferences', selectedAccountName?: string | null } };

export type CurrentUserData_User_Fragment = { __typename: 'User', email: string, emailVerified: boolean, id: string, username: string, profilePhoto: string, firstName?: string | null, lastName?: string | null, isExpoAdmin: boolean, bestContactEmail?: string | null, featureGates: any, pendingUserInvitations: Array<{ __typename?: 'UserInvitation', id: string, email: string, created: any, expires: any, accountName: string, accountProfilePhoto?: string | null, permissions: Array<Types.Permission>, role: Types.Role, isForOrganization: boolean }>, primaryAccount: { __typename?: 'Account', id: string, name: string }, accounts: Array<{ __typename: 'Account', id: string, name: string, isDisabled: boolean, createdAt: any, ownerUserActor?: { __typename: 'SSOUser', id: string, fullName?: string | null, profilePhoto: string, username: string } | { __typename: 'User', email: string, id: string, fullName?: string | null, profilePhoto: string, username: string } | null, viewerUserPermission: { __typename?: 'UserPermission', permissions: Array<Types.Permission>, role: Types.Role, userActor?: { __typename?: 'SSOUser', id: string, created: any, firstName?: string | null, lastName?: string | null, profilePhoto: string, displayName: string, username: string, bestContactEmail?: string | null } | { __typename?: 'User', email: string, id: string, created: any, firstName?: string | null, lastName?: string | null, profilePhoto: string, displayName: string, username: string, bestContactEmail?: string | null } | null }, subscription?: { __typename?: 'SubscriptionDetails', name?: string | null, id: string, planId?: string | null, status?: string | null, nextInvoice?: any | null } | null }>, githubUser?: { __typename?: 'GitHubUser', id: string, githubUserIdentifier: string } | null, preferences: { __typename?: 'UserPreferences', selectedAccountName?: string | null } };

export type CurrentUserDataFragment = CurrentUserData_SsoUser_Fragment | CurrentUserData_User_Fragment;

export const CurrentUserDataFragmentDoc = gql`
    fragment CurrentUserData on UserActor {
  __typename
  id
  username
  profilePhoto
  firstName
  lastName
  isExpoAdmin
  primaryAccount {
    id
    name
  }
  accounts {
    ...AccountsData
  }
  bestContactEmail
  featureGates
  ... on User {
    email
    emailVerified
    pendingUserInvitations {
      ...UserInvitationData
    }
  }
  githubUser {
    id
    githubUserIdentifier
  }
  preferences {
    selectedAccountName
  }
}
    ${AccountsDataFragmentDoc}
${UserInvitationDataFragmentDoc}`;