import { Namespace, NoOpExposureLogger, parseExperimentationConfig } from '@expo/experimentation';
import nullthrows from 'nullthrows';
import { createContext, useContext } from 'react';

import { ExperimentationQuery } from '~/graphql/types.generated';

export class ExperimentationManager {
  private readonly userNamespaceMap?: ReadonlyMap<string, Namespace>;
  private readonly deviceNamespaceMap: ReadonlyMap<string, Namespace>;

  constructor(experimentationConfig: ExperimentationQuery, userId?: string) {
    const deviceNamespaceFnMap = parseExperimentationConfig(experimentationConfig.deviceConfig, {
      flavorMap: new Map([['no-op', new NoOpExposureLogger()]]),
      fallbackLoggerType: 'no-op',
    });

    const deviceNamespaceMap = new Map<string, Namespace>();
    deviceNamespaceFnMap.forEach((namespaceFn, key) =>
      deviceNamespaceMap.set(key, namespaceFn([experimentationConfig.deviceExperimentationUnit]))
    );
    this.deviceNamespaceMap = deviceNamespaceMap;

    if (userId) {
      const userNamespaceFnMap = parseExperimentationConfig(experimentationConfig.userConfig, {
        flavorMap: new Map([['no-op', new NoOpExposureLogger()]]),
        fallbackLoggerType: 'no-op',
      });
      const userNamespaceMap = new Map<string, Namespace>();
      userNamespaceFnMap.forEach((namespaceFn, key) =>
        userNamespaceMap.set(key, namespaceFn([userId]))
      );
      this.userNamespaceMap = userNamespaceMap;
    }
  }

  public getUserNamespace(name: string): Namespace {
    if (!this.userNamespaceMap) {
      throw new Error('User namespaces only available in authenticated routes');
    }
    return nullthrows(this.userNamespaceMap.get(name), `User namespace ${name} not defined`);
  }

  public getDeviceNamespace(name: string): Namespace {
    return nullthrows(this.deviceNamespaceMap.get(name), `Device namespace ${name} not defined`);
  }
}

export const ExperimentationContext = createContext<ExperimentationManager | undefined>(undefined);

ExperimentationContext.displayName = 'ExperimentationContext';

export function useUserExperimentationNamespace(namespaceName: string): Namespace {
  return nullthrows(
    useContext(ExperimentationContext),
    'useUserExperimentationNamespace must be used within a ExperimentationContext.Provider'
  ).getUserNamespace(namespaceName);
}

export function useDeviceExperimentationNamespace(namespaceName: string): Namespace {
  return nullthrows(
    useContext(ExperimentationContext),
    'useDeviceExperimentationNamespace must be used within a ExperimentationContext.Provider'
  ).getDeviceNamespace(namespaceName);
}
