import { mergeClasses } from '@expo/styleguide';
import { BuildIcon } from '@expo/styleguide-icons/custom/BuildIcon';
import { format } from 'date-fns/format';

import { dateFnsFormats } from '~/common/constants';
import { WebsiteNotificationsPaginatedQuery } from '~/graphql/queries/WebsiteNotificationsPaginated.query.generated';
import { NotificationEvent } from '~/graphql/types.generated';
import { LinkBase } from '~/ui/components/LinkBase';
import { CALLOUT, FOOTNOTE, LABEL } from '~/ui/components/text';

type Props = {
  notification: NonNullable<
    WebsiteNotificationsPaginatedQuery['meUserActor']
  >['websiteNotificationsPaginated']['edges'][0]['node'];
  withDivider?: boolean;
  onDismiss?: () => void;
};

export function NotificationCard({ notification, withDivider = false, onDismiss }: Props) {
  const formattedDate = format(new Date(notification.createdAt), dateFnsFormats.date);

  const composeLinks = (notificationEvent: NotificationEvent, accountName: string) => {
    switch (notificationEvent) {
      case NotificationEvent.BuildLimitThresholdExceeded:
      case NotificationEvent.BuildPlanCreditThresholdExceeded:
        return `/accounts/${accountName}/settings/billing`;
      case NotificationEvent.BuildComplete:
      case NotificationEvent.BuildErrored:
      case NotificationEvent.SubmissionComplete:
      case NotificationEvent.SubmissionErrored:
      case NotificationEvent.Test:
        return `/`;
      default:
        return undefined;
    }
  };
  return (
    <LinkBase
      key={notification.id}
      href={composeLinks(notification.event, notification.accountName)}
      onClick={onDismiss}
      className={mergeClasses(
        'group flex bg-default p-3 text-sm !outline-offset-[-3px]',
        withDivider && 'border-b border-b-secondary',
        'hover:bg-subtle'
      )}>
      <div
        className={mergeClasses(
          'flex size-8 items-center justify-center rounded-full bg-element',
          'group-hover:bg-selected'
        )}>
        <BuildIcon />
      </div>
      <div className="flex flex-1 items-center justify-between pl-2">
        <div className="flex flex-col pl-2">
          <LABEL theme="default">{notification.accountName}</LABEL>
          <CALLOUT theme="default">{notification.websiteMessage}</CALLOUT>
          <FOOTNOTE theme="secondary">{formattedDate}</FOOTNOTE>
        </div>
        <div className="flex items-center pl-2">
          {notification.isRead ? (
            <div className="size-2 rounded-full" />
          ) : (
            <div className="size-2 rounded-full bg-palette-blue11" />
          )}
        </div>
      </div>
    </LinkBase>
  );
}
