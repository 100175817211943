import { addHighlight, CommandItemBase } from '@expo/styleguide-search-ui';
import { useRef } from 'react';

import { getProjectURL } from '~/common/helpers';
import { LinkBase } from '~/ui/components/LinkBase';
import { AppNode } from '~/ui/components/Navigation/Search/types';
import { ProjectIcon } from '~/ui/components/ProjectIcon';

type Props = {
  item: AppNode;
  query: string;
  accountName?: string;
  onSelect?: () => void;
};

export function ProjectItem({ item, accountName, query, onSelect }: Props) {
  const linkRef = useRef<HTMLAnchorElement>(null);

  if (!accountName) {
    return null;
  }

  const projectName = item.name ?? item.slug;
  return (
    <LinkBase href={getProjectURL(accountName, item.slug)} ref={linkRef}>
      <CommandItemBase
        className="relative"
        onSelect={() => {
          linkRef?.current?.click();
          onSelect?.();
        }}
        value={`expo-project-${item.slug}`}>
        <div className="inline-flex items-center gap-3">
          <ProjectIcon name={projectName} />
          <p
            className="text-xs font-medium"
            dangerouslySetInnerHTML={{ __html: addHighlight(projectName, query) }}
          />
        </div>
      </CommandItemBase>
    </LinkBase>
  );
}
