import { mergeClasses } from '@expo/styleguide';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { AnchorHTMLAttributes } from 'react';

import { A, CALLOUT, P, SPAN } from '~/ui/components/text';

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  text: string;
  size?: 'paragraph' | 'callout' | 'span';
  type?: 'internal' | 'external';
};

const getTextElement = (size: Props['size']) => {
  switch (size) {
    case 'callout':
      return CALLOUT;
    case 'span':
      return SPAN;
    default:
      return P;
  }
};

export function InlineLink(props: Props) {
  const { text, href, className, size = 'paragraph', type = 'internal', ...rest } = props;
  const TextElement = getTextElement(size);

  return (
    <A
      href={href}
      openInNewTab={type === 'external'}
      className={mergeClasses('flex items-center border-b border-b-palette-gray8', className)}
      {...rest}>
      <TextElement variant="oneLine" className="flex items-center gap-1">
        {text}
        {type === 'external' && (
          <ArrowUpRightIcon className={mergeClasses('text-icon-secondary', 'icon-sm')} />
        )}
      </TextElement>
    </A>
  );
}
