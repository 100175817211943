import { useRouter } from 'next/compat/router';
import type { NextRouter } from 'next/router';

import { Avatar } from '~/ui/components/Avatar';
import { linkResolver } from '~/ui/components/Navigation/linkResolver';
import { PopoverItem } from '~/ui/components/SidebarNavigation/components/PopoverItem';

type Props = {
  accountName: string;
  displayName: string;
  iconUrl?: string;
  isSelected?: boolean;
  setIsVisible: (isVisible: boolean, selectedName?: string) => void;
  onSelect?: (selectedName: string) => void;
  simple?: boolean;
  applyHref?: boolean;
  isOrganizationAccount?: boolean;
};

function getLink(accountName: string, router?: NextRouter | null) {
  const hasBuildId = router?.query?.buildId != null;
  const hasProjectName = router?.query?.projectName != null;
  const isSnacksPath =
    router?.pathname.replace('[account]', accountName) === `/accounts/${accountName}/snacks`;

  if (!router || hasBuildId || hasProjectName || isSnacksPath) {
    return `/accounts/${accountName}`;
  }

  const link = linkResolver(`/accounts/${accountName}`, router);
  return link.startsWith('/') ? link : `/${link}`;
}

export function AccountListItem({
  displayName,
  accountName,
  iconUrl,
  isSelected,
  setIsVisible,
  onSelect,
  applyHref = true,
  simple = false,
  isOrganizationAccount = false,
}: Props) {
  const router = useRouter();

  return (
    <PopoverItem
      title={displayName}
      icon={
        <Avatar
          profilePhoto={iconUrl}
          name={displayName ? displayName : accountName}
          isOrganization={isOrganizationAccount}
        />
      }
      onClick={(event) => {
        if (onSelect) {
          onSelect(accountName);
        }

        if (!(event.ctrlKey || event.metaKey || event.shiftKey)) {
          setIsVisible(false, accountName);
        }
      }}
      isSelected={isSelected}
      href={simple || applyHref ? getLink(accountName, router) : undefined}
    />
  );
}
