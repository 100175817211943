import { ButtonBase } from '@expo/styleguide';
import { CheckIcon } from '@expo/styleguide-icons/outline/CheckIcon';
import { ReactElement, ReactNode, MouseEvent } from 'react';

import { LinkBase } from '~/ui/components/LinkBase';
import { CALLOUT } from '~/ui/components/text';

export type PopoverItemProps = {
  title: string;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  href?: string;
  isSelected?: boolean;
  icon?: ReactElement;
  rightSlot?: ReactNode;
  testID?: string;
  destructive?: boolean;
};

export function PopoverItem({
  title,
  icon,
  onClick,
  href,
  rightSlot,
  testID,
  destructive,
  isSelected = false,
}: PopoverItemProps) {
  const Element = href ? LinkBase : ButtonBase;

  return (
    <div className="flex items-center justify-center px-3 -outline-offset-[5px] transition hover:bg-element">
      <Element
        onClick={onClick}
        className="flex h-10 max-w-full flex-1 items-center justify-between gap-3 truncate"
        href={href}
        testID={testID}>
        <div className="flex flex-1 items-center gap-2 truncate py-1">
          {icon}
          <CALLOUT theme={destructive ? 'danger' : 'default'} className="truncate">
            {title}
          </CALLOUT>
        </div>
        {isSelected && <CheckIcon />}
      </Element>
      {rightSlot && <div className="ml-3">{rightSlot}</div>}
    </div>
  );
}
