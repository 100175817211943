// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type AppDataFragment = { __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string }, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null } | null };

export const AppDataFragmentDoc = gql`
    fragment AppData on App {
  __typename
  id
  icon {
    url
    primaryColor
  }
  iconUrl
  fullName
  name
  slug
  ownerAccount {
    name
    id
  }
  githubRepository {
    githubRepositoryUrl
  }
  lastDeletionAttemptTime
}
    `;