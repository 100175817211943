// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type SubscriptionDataFragment = { __typename?: 'SubscriptionDetails', id: string, isDowngrading?: boolean | null, planId?: string | null, price: number, recurringCents?: number | null, name?: string | null, nextInvoice?: any | null, nextInvoiceAmountDueCents?: number | null, status?: string | null, endedAt?: any | null, willCancel?: boolean | null, cancelAt?: any | null, coupon?: { __typename?: 'StripeCoupon', id: string, name: string, amountOff?: string | null, percentOff?: number | null, appliesTo?: string | null, valid: boolean } | null, addons: Array<{ __typename?: 'AddonDetails', id: string, planId: string, name: string, quantity?: number | null, nextInvoice?: any | null, willCancel?: boolean | null }>, concurrencies?: { __typename?: 'Concurrencies', total: number, android: number, ios: number } | null, meteredBillingStatus: { __typename?: 'MeteredBillingStatus', EAS_BUILD: boolean, EAS_UPDATE: boolean }, futureSubscription?: { __typename?: 'FutureSubscription', id: string, createdAt: any, planId: string, startDate: any, recurringCents?: number | null, meteredBillingStatus: { __typename?: 'MeteredBillingStatus', EAS_BUILD: boolean, EAS_UPDATE: boolean } } | null, upcomingInvoice?: { __typename?: 'Invoice', lineItems: Array<{ __typename?: 'InvoiceLineItem', id: string, amount: number, quantity: number, metadata: any }> } | null };

export const SubscriptionDataFragmentDoc = gql`
    fragment SubscriptionData on SubscriptionDetails {
  id
  isDowngrading
  planId
  price
  recurringCents
  name
  nextInvoice
  nextInvoiceAmountDueCents
  status
  endedAt
  willCancel
  cancelAt
  coupon {
    id
    name
    amountOff
    percentOff
    appliesTo
    valid
  }
  addons {
    id
    planId
    name
    quantity
    nextInvoice
    willCancel
  }
  concurrencies {
    total
    android
    ios
  }
  meteredBillingStatus {
    EAS_BUILD
    EAS_UPDATE
  }
  futureSubscription {
    id
    createdAt
    planId
    startDate
    recurringCents
    meteredBillingStatus {
      EAS_BUILD
      EAS_UPDATE
    }
  }
  upcomingInvoice {
    lineItems {
      id
      amount
      quantity
      metadata
    }
  }
}
    `;