import { mergeClasses } from '@expo/styleguide';
import { PropsWithChildren } from 'react';

import { CALLOUT } from '~/ui/components/text';

type Props = PropsWithChildren<{
  className?: string;
}>;

export function TooltipText({ children, className }: Props) {
  return (
    <CALLOUT className={mergeClasses('break-words text-center text-palette-white', className)}>
      {children}
    </CALLOUT>
  );
}
