import { ArrowLeftIcon } from '@expo/styleguide-icons/outline/ArrowLeftIcon';
import { XCloseIcon } from '@expo/styleguide-icons/outline/XCloseIcon';
import { PopoverClose } from '@radix-ui/react-popover';

import { Button } from '~/ui/components/Button';
import { HEADLINE } from '~/ui/components/text';

import { FeedbackPopoverPage } from './shared';

type Props = {
  hasBackOption?: boolean;
  previousPage?: FeedbackPopoverPage;
  onDismiss: () => void;
  onSetPage?: (page: FeedbackPopoverPage) => void;
  resetFormState?: () => void;
  goBackToOptionsOnHide?: boolean;
  title?: string;
};

export function PopoverHeaderButtonGroup({
  hasBackOption,
  previousPage,
  onDismiss,
  onSetPage,
  resetFormState,
  goBackToOptionsOnHide = false,
  title,
}: Props) {
  function onHide() {
    onDismiss();
    resetFormState?.();
    if (onSetPage && goBackToOptionsOnHide) {
      onSetPage(FeedbackPopoverPage.OPTIONS);
    }
  }

  function onGoBack() {
    previousPage && onSetPage && onSetPage(previousPage);
    resetFormState?.();
  }

  return (
    <div className="grid w-full grid-cols-[36px_1fr_36px] p-2">
      <div>
        {hasBackOption && (
          <Button
            theme="quaternary"
            onClick={onGoBack}
            leftSlot={<ArrowLeftIcon className="icon-md" />}
          />
        )}
      </div>
      <HEADLINE className="self-center text-center">{title}</HEADLINE>
      <PopoverClose asChild>
        <Button
          theme="quaternary"
          onClick={onHide}
          leftSlot={<XCloseIcon className="icon-md" />}
          className="px-2"
        />
      </PopoverClose>
    </div>
  );
}
