import { getCurrentSessionSecret } from '~/common/auth-manager';
import { ApiError } from '~/common/errors/ApiError';
import { GenericError } from '~/common/errors/GenericError';
import { FeatureGateQueryParams } from '~/common/gating/FeatureGateOverrides';
import requestSudoUpgrade from '~/ui/components/authentication/UpgradeSudoForm/asyncUpgradeSudoForm';

type SendOptions = {
  method?: string;
  headers?: Record<string, string>;
  body?: object;
  searchParams?: Record<string, string>;
};

const apiServerURL =
  typeof window !== 'undefined' ? process.env.API_SERVER_URL : process.env.API_SERVER_INTERNAL_URL;

export class APIV2Client {
  constructor(readonly featureGateQueryParams: FeatureGateQueryParams) {}

  private async sendApiV2RequestAsync<TData>(
    route: string,
    options: SendOptions,
    retryIfSudoError?: false
  ): Promise<TData> {
    const url = new URL(`${apiServerURL}/v2/${route}`);
    if (options.searchParams) {
      url.search = new URLSearchParams(options?.searchParams).toString();
    }

    Object.entries(this.featureGateQueryParams).forEach(([param, values]) => {
      if (values.length > 0) {
        url.searchParams.append(param, values.join(','));
      }
    });

    let response: Response;
    try {
      response = await fetch(url.toString(), {
        method: options.method ?? 'POST',
        body: options.body ? JSON.stringify(options.body) : null,
        headers: {
          ...options.headers,
          accept: 'application/json',
          ...(options.body ? { 'content-type': 'application/json' } : null),
        },
      });
    } catch (error) {
      throw new GenericError(
        `Something went wrong when connecting to Expo: ${(error as Error).message}.`
      );
    }

    let text: string;
    try {
      text = await response.text();
    } catch (error) {
      throw new GenericError(
        `Something went wrong when reading the response (HTTP ${response.status}) from Expo: ${
          (error as Error).message
        }.`
      );
    }

    let body: any;
    try {
      body = JSON.parse(text);
    } catch {
      throw new GenericError(`The Expo server responded in an unexpected way: ${text}`);
    }

    if (Array.isArray(body.errors) && body.errors.length > 0) {
      const responseError = body.errors[0];

      if (responseError.code === 'SUDO_MODE_REQUIRED' && retryIfSudoError !== false) {
        await requestSudoUpgrade(this);
        return await this.sendApiV2RequestAsync(route, options, false);
      }

      const errorMessage = responseError.details
        ? responseError.details.message
        : responseError.message;
      const error = new ApiError(errorMessage, responseError.code);
      error.serverStack = responseError.stack;
      error.metadata = responseError.metadata;
      throw error;
    }

    if (!response.ok) {
      throw new GenericError(`The Expo server responded with a ${response.status} error.`);
    }

    return body.data;
  }

  public async sendAuthenticatedApiV2RequestAsync<TData>(
    route: string,
    options: SendOptions = {}
  ): Promise<TData> {
    const sessionSecret = getCurrentSessionSecret();
    if (!sessionSecret) {
      throw new ApiError('Must be logged in to perform request');
    }

    const newOptions = {
      ...options,
      headers: {
        ...options.headers,
        ...(sessionSecret
          ? {
              'Expo-Session': sessionSecret,
            }
          : {}),
      },
    };
    return await this.sendApiV2RequestAsync(route, newOptions);
  }

  public async sendOptionallyAuthenticatedApiV2RequestAsync<TData>(
    route: string,
    options: SendOptions = {}
  ): Promise<TData> {
    const sessionSecret = getCurrentSessionSecret();
    const newOptions = {
      ...options,
      headers: {
        ...options.headers,
        ...(sessionSecret
          ? {
              'Expo-Session': sessionSecret,
            }
          : {}),
      },
    };
    return await this.sendApiV2RequestAsync(route, newOptions);
  }

  public async sendUnauthenticatedApiV2RequestAsync<TData>(
    route: string,
    options: SendOptions = {}
  ): Promise<TData> {
    return await this.sendApiV2RequestAsync(route, options);
  }
}
