import { PlusSquareIcon } from '@expo/styleguide-icons/outline/PlusSquareIcon';
import { ReactNode, useState } from 'react';

import { AccountsDataFragment } from '~/graphql/types.generated';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { Avatar } from '~/ui/components/Avatar';
import { AccountListItem } from '~/ui/components/SidebarNavigation/components/AccountListItem';
import { PopoverItem } from '~/ui/components/SidebarNavigation/components/PopoverItem';
import { PopoverSectionTitle } from '~/ui/components/SidebarNavigation/components/PopoverSectionTitle';
import { SidebarSelector } from '~/ui/components/SidebarNavigation/components/SidebarSelector';
import { A, CALLOUT, FOOTNOTE } from '~/ui/components/text';

import { useSelectedAccount } from './useSelectedAccount';
import { getUserInvitations, sortByAccountName } from '../helpers';

type Props = {
  selectedUsername?: string;
  currentUser: LoggedInProps['currentUser'];
  currentDateAsString: string;
  accounts: AccountsDataFragment[];
  disabled?: boolean;
  onSelect?: (selectedName: string) => void;
  header?: ReactNode;
  showCreateOrganization?: boolean;
  showInvitations?: boolean;
  applyHref?: boolean;
};

export function AccountSelector({
  selectedUsername,
  currentUser,
  currentDateAsString,
  accounts,
  disabled,
  onSelect,
  header,
  showCreateOrganization = true,
  showInvitations = true,
  applyHref = true,
}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const {
    accountNameFromUrl,
    organizationAccounts,
    personalAccount,
    personalAccountOrSuperuser,
    selectedAccount,
    userAccounts,
  } = useSelectedAccount(currentUser, accounts);
  const pendingUserInvitations = getUserInvitations(currentUser, currentDateAsString);
  const hasPendingUserInvitations = pendingUserInvitations.length > 0;

  const resolvedSelectedUsername = selectedUsername ?? selectedAccount?.name;
  const resolvedAccount = accounts.find((account) => account.name === resolvedSelectedUsername);
  const isSSOUser = personalAccount?.ownerUserActor?.__typename === 'SSOUser';

  return (
    <SidebarSelector
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label={
        header ??
        (hasPendingUserInvitations ? (
          <div className="flex items-center">
            <CALLOUT theme="secondary">Account</CALLOUT>
            <div className="ml-2 size-2 rounded-full bg-palette-red10 shadow-xs" />
          </div>
        ) : (
          'Account'
        ))
      }
      disabled={disabled}
      popoverContent={
        <>
          {personalAccountOrSuperuser?.ownerUserActor && (
            <>
              <PopoverSectionTitle
                title={
                  !personalAccount && currentUser.isExpoAdmin ? 'Superuser' : 'Personal account'
                }
              />
              <AccountListItem
                accountName={personalAccountOrSuperuser.ownerUserActor?.username}
                displayName={
                  personalAccountOrSuperuser.ownerUserActor?.fullName ??
                  personalAccountOrSuperuser.name
                }
                iconUrl={personalAccountOrSuperuser.ownerUserActor.profilePhoto}
                isSelected={resolvedSelectedUsername === personalAccountOrSuperuser?.name}
                onSelect={onSelect}
                applyHref={applyHref}
                setIsVisible={setIsVisible}
              />
            </>
          )}
          {userAccounts.length > 0 && (
            <>
              <PopoverSectionTitle
                title={
                  !personalAccount && currentUser.isExpoAdmin
                    ? `Team accounts (${accountNameFromUrl})`
                    : 'Team accounts'
                }
              />
              {sortByAccountName(userAccounts).map((userAccount) => (
                <AccountListItem
                  key={userAccount.id}
                  displayName={userAccount.ownerUserActor?.fullName ?? userAccount.name}
                  accountName={userAccount.ownerUserActor?.username ?? userAccount.name}
                  iconUrl={userAccount.ownerUserActor?.profilePhoto}
                  isSelected={resolvedSelectedUsername === userAccount.name}
                  onSelect={onSelect}
                  applyHref={applyHref}
                  setIsVisible={setIsVisible}
                />
              ))}
            </>
          )}
          {organizationAccounts.length > 0 && (
            <>
              <PopoverSectionTitle
                title={
                  !personalAccount && currentUser.isExpoAdmin
                    ? `Organizations (${accountNameFromUrl})`
                    : 'Organizations'
                }
              />
              {sortByAccountName(organizationAccounts).map((organizationAccount) => (
                <AccountListItem
                  key={organizationAccount.id}
                  displayName={organizationAccount.name}
                  accountName={organizationAccount.name}
                  isSelected={resolvedSelectedUsername === organizationAccount.name}
                  setIsVisible={setIsVisible}
                  onSelect={onSelect}
                  applyHref={applyHref}
                  isOrganizationAccount
                />
              ))}
            </>
          )}
          {!isSSOUser && showCreateOrganization && (
            <PopoverItem
              title="Create Organization"
              icon={<PlusSquareIcon />}
              onClick={(event) => {
                if (!(event.ctrlKey || event.metaKey || event.shiftKey)) {
                  setIsVisible(false);
                }
              }}
              href="/create-organization"
            />
          )}
          {showInvitations && hasPendingUserInvitations && (
            <>
              <PopoverSectionTitle
                title={
                  <div className="flex items-center">
                    <CALLOUT theme="secondary" weight="medium">
                      Invitations
                    </CALLOUT>
                    <div className="ml-2 size-2 rounded-full bg-palette-red10 shadow-xs" />
                  </div>
                }
              />
              {pendingUserInvitations?.length ? (
                pendingUserInvitations.map((invitation) => (
                  <A
                    href={`/invitations/accept?id=${invitation.id}`}
                    className="flex min-h-[40px] w-full cursor-pointer items-center px-3 outline-offset-[-5px] transition hover:bg-element"
                    key={invitation.id}>
                    <CALLOUT className="truncate">{invitation.accountName}</CALLOUT>
                  </A>
                ))
              ) : (
                <A
                  href="/settings#email"
                  className="block w-full cursor-pointer px-3 py-2 outline-offset-[-5px] transition hover:bg-element">
                  <div>
                    <CALLOUT className="mb-1">Verify your email</CALLOUT>
                    <FOOTNOTE theme="secondary">
                      Verify your email to view pending invitations.
                    </FOOTNOTE>
                  </div>
                </A>
              )}
            </>
          )}
        </>
      }
      triggerContent={
        <>
          <Avatar
            profilePhoto={resolvedAccount?.ownerUserActor?.profilePhoto}
            name={resolvedAccount?.ownerUserActor?.fullName ?? resolvedAccount?.name}
            isOrganization={resolvedAccount?.ownerUserActor === null}
          />
          <CALLOUT className="truncate !font-medium">{resolvedSelectedUsername}</CALLOUT>
        </>
      }
      triggerId="nav-bar-account-selector"
    />
  );
}
