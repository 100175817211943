// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateGitHubBuildTriggerMutationVariables = Types.Exact<{
  githubBuildTriggerId: Types.Scalars['ID']['input'];
  githubBuildTriggerData: Types.UpdateGitHubBuildTriggerInput;
}>;


export type UpdateGitHubBuildTriggerMutation = { __typename?: 'RootMutation', githubBuildTrigger: { __typename?: 'GitHubBuildTriggerMutation', updateGitHubBuildTrigger: { __typename?: 'GitHubBuildTrigger', id: string } } };


export const UpdateGitHubBuildTriggerDocument = gql`
    mutation UpdateGitHubBuildTrigger($githubBuildTriggerId: ID!, $githubBuildTriggerData: UpdateGitHubBuildTriggerInput!) {
  githubBuildTrigger {
    updateGitHubBuildTrigger(
      githubBuildTriggerId: $githubBuildTriggerId
      githubBuildTriggerData: $githubBuildTriggerData
    ) {
      id
    }
  }
}
    `;
export function useUpdateGitHubBuildTriggerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGitHubBuildTriggerMutation, UpdateGitHubBuildTriggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGitHubBuildTriggerMutation, UpdateGitHubBuildTriggerMutationVariables>(UpdateGitHubBuildTriggerDocument, options);
      }
export type UpdateGitHubBuildTriggerMutationHookResult = ReturnType<typeof useUpdateGitHubBuildTriggerMutation>;
export type UpdateGitHubBuildTriggerMutationResult = Apollo.MutationResult<UpdateGitHubBuildTriggerMutation>;
export type UpdateGitHubBuildTriggerMutationOptions = Apollo.BaseMutationOptions<UpdateGitHubBuildTriggerMutation, UpdateGitHubBuildTriggerMutationVariables>;