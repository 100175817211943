import { mergeClasses } from '@expo/styleguide';
import { forwardRef, HTMLAttributes, Ref } from 'react';

type ActivityIndicatorProps = HTMLAttributes<SVGSVGElement> & {
  animating?: boolean;
  hidesWhenStopped?: boolean;
  size?: 'sm' | 'md' | 'lg';
};

export const ActivityIndicator = forwardRef(function ActivityIndicator(
  {
    animating = true,
    hidesWhenStopped = true,
    size = 'sm',
    className,
    ...rest
  }: ActivityIndicatorProps,
  forwardedRef: Ref<HTMLDivElement>
) {
  return (
    <div role="progressbar" ref={forwardedRef}>
      <svg
        height="100%"
        viewBox="0 0 32 32"
        width="100%"
        className={mergeClasses(
          'relative animate-spin stroke-palette-blue10',
          indicatorSizes[size],
          !animating && 'pause-animation',
          !animating && hidesWhenStopped && 'invisible',
          className
        )}
        {...rest}>
        <circle cx="16" cy="16" fill="none" r="14" strokeWidth="4" className="opacity-20" />
        <circle
          cx="16"
          cy="16"
          fill="none"
          r="14"
          strokeWidth="4"
          strokeDasharray="80"
          strokeDashoffset="60"
        />
      </svg>
    </div>
  );
});

ActivityIndicator.displayName = 'ActivityIndicator';

const indicatorSizes = {
  sm: 'size-[20px]',
  md: 'size-[32px]',
  lg: 'size-[36px]',
};
