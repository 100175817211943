import { mergeClasses } from '@expo/styleguide';
import type { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

export default function Content({ children, className, ...rest }: Props) {
  return (
    <div
      className={mergeClasses('relative mx-auto w-full max-w-screen-xl-gutters px-6', className)}
      {...rest}>
      {children}
    </div>
  );
}
