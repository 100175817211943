import { createContext, useContext, ReactNode } from 'react';

import { FeatureGateQueryParams } from '~/common/gating/FeatureGateOverrides';

type Props = {
  children: ReactNode;
  featureGateQueryParams: FeatureGateQueryParams | null;
};

export const FeatureGateURLQueryParamsContext = createContext<FeatureGateQueryParams | null>(null);

export function FeatureGateURLQueryParamsContextProvider(props: Props) {
  return (
    <FeatureGateURLQueryParamsContext.Provider value={props.featureGateQueryParams}>
      {props.children}
    </FeatureGateURLQueryParamsContext.Provider>
  );
}

export function useFeatureGateURLQueryParams() {
  return useContext(FeatureGateURLQueryParamsContext);
}
