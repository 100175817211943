import { LifeBuoy01DuotoneIcon } from '@expo/styleguide-icons/duotone/LifeBuoy01DuotoneIcon';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';

import { P } from '~/ui/components/text';

import { PopoverHeaderButtonGroup } from './PopoverHeaderButtonGroup';
import { FeedbackPopoverPage } from './shared';
import { Button } from '../Button';

type Props = {
  onDismiss: () => void;
  onSetPage: (pageNum: FeedbackPopoverPage) => void;
  hasBackOption?: boolean;
};

export function PopoverFeedbackIssue({ onDismiss, onSetPage, hasBackOption }: Props) {
  return (
    <div className="flex flex-1 flex-col">
      <PopoverHeaderButtonGroup
        hasBackOption={hasBackOption}
        previousPage={FeedbackPopoverPage.OPTIONS}
        onDismiss={onDismiss}
        onSetPage={onSetPage}
        title="Get help with an issue"
      />
      <div className="flex flex-col gap-4 px-4 py-3">
        <P>We'd love to help you find a way to address any issues you're encountering!</P>
        <Button
          theme="secondary"
          size="xl"
          href="/support"
          className="px-4 py-7"
          leftSlot={<LifeBuoy01DuotoneIcon />}
          rightSlot={<ArrowRightIcon className="icon-md ml-auto text-icon-secondary" />}>
          Get Support
        </Button>
      </div>
    </div>
  );
}
