import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';

export class ServerlessDeploymentsFeatureGate extends FeatureGate {
  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.SERVERLESS_DEPLOYMENTS;
  }
}

export function useServerlessDeploymentsFeatureGate() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.SERVERLESS_DEPLOYMENTS] ?? defaultGate;
}
