import { FeatureGateQueryParams } from '~/common/gating/FeatureGateOverrides';

export function addFeatureGateQueryParamsToHref(
  href: string,
  featureGateQueryParams: FeatureGateQueryParams
) {
  const featureGateEntries = Object.entries(featureGateQueryParams).filter(
    ([param, values]) => !href.includes(param) && values.length > 0
  );
  if (featureGateEntries.length === 0) {
    return href;
  }

  const hashIndex = href.indexOf('#');
  const hash = hashIndex === -1 ? '' : href.substring(hashIndex);
  const hrefWithoutHash = hashIndex === -1 ? href : href.substring(0, hashIndex);

  const queryParamString = featureGateEntries
    .map(
      ([param, values]) => `${encodeURIComponent(param)}=${encodeURIComponent(values.join(','))}`
    )
    .join('&');
  return hrefWithoutHash.includes('?')
    ? `${hrefWithoutHash}&${queryParamString}${hash}`
    : `${hrefWithoutHash}?${queryParamString}${hash}`;
}

export function shouldOpenInNewTabIfLinkIsExternal(href: string, openInNewTabOverride?: boolean) {
  const domainRe = /https?:\/\/((?:[\w-]+\.)+\w{2,})/i;
  const prPreviewDomainRegex = /^pr-\d+\.expo\.dev$/;

  const getDomain = (url: string) => domainRe.exec(url)?.[1];
  const internalDomains = ['expo.dev', 'expo.test', 'staging.expo.dev', 'localhost'];

  if (openInNewTabOverride !== undefined) {
    return openInNewTabOverride;
  }

  if (
    !href ||
    href.startsWith('/') ||
    href.startsWith('#') ||
    href.startsWith('mailto') ||
    href.startsWith('tel') ||
    href.startsWith('sms')
  ) {
    return false;
  }

  const linkDomain = getDomain(href);

  if (!linkDomain) {
    return false;
  }

  return !(prPreviewDomainRegex.test(linkDomain) || internalDomains.includes(linkDomain));
}
