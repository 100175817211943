// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type CurrentUserActorAnalyticsData_SsoUser_Fragment = { __typename: 'SSOUser', id: string, username: string };

export type CurrentUserActorAnalyticsData_User_Fragment = { __typename: 'User', email: string, id: string, username: string };

export type CurrentUserActorAnalyticsDataFragment = CurrentUserActorAnalyticsData_SsoUser_Fragment | CurrentUserActorAnalyticsData_User_Fragment;

export const CurrentUserActorAnalyticsDataFragmentDoc = gql`
    fragment CurrentUserActorAnalyticsData on UserActor {
  __typename
  id
  username
  ... on User {
    email
  }
}
    `;