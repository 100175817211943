import { mergeClasses } from '@expo/styleguide';
import type { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement> & {
  type?: 'regular' | 'nested' | 'list' | 'form';
};

export function BoxWrapper({ children, className, type = 'regular', ...rest }: Props) {
  return (
    <div
      className={mergeClasses(
        'rounded-md border border-secondary bg-default shadow-xs',
        type === 'form' &&
          'rounded-lg shadow-sm max-sm-gutters:rounded-none max-sm-gutters:border-x-0 max-sm-gutters:shadow-none',
        type === 'list' && 'hocus:shadow-sm',
        type === 'nested' && 'shadow-none',
        className
      )}
      {...rest}>
      {children}
    </div>
  );
}
