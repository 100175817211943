import type { NextRouter } from 'next/router';
import { useEffect, RefObject } from 'react';

import { isTeamAccount, isUserAccount } from '~/common/helpers';
import { LoggedInProps } from '~/scenes/_app/helpers';

export const layout = {
  padding: 16,
  gridGap: 12,
  buttonSize: 40,
};

export function useNavigationBlurHandler(ref: RefObject<HTMLDivElement>, onDismiss: () => void) {
  function onBlur(event: any) {
    if (ref.current) {
      const isInsidePopover = ref.current.contains(event.relatedTarget);
      const isNotSelectTag = event.target.tagName === 'SELECT';
      if (!isInsidePopover && !isNotSelectTag) {
        onDismiss();
      }
    }
  }

  useEffect(
    function onBlurDidUpdate() {
      ref.current?.addEventListener('focusout', onBlur);

      return function willUnmount() {
        ref.current?.removeEventListener('focusout', onBlur);
      };
    },
    [onBlur]
  );
}

export function isUserSettingsRoute(router?: NextRouter | null) {
  return !!router?.route?.match(/^\/settings.*/);
}

export function isAccountSettingsRoute(router?: NextRouter | null) {
  return !!router?.route?.match(/^\/accounts\/\[account]\/settings.*/);
}

export function isSettingsRoute(router?: NextRouter | null) {
  return !!(router && (isUserSettingsRoute(router) || isAccountSettingsRoute(router)));
}

export function isProfile(router?: NextRouter | null) {
  return !!router?.route?.match(/^\/\[username]/);
}

export function isPublicRoute(router?: NextRouter | null) {
  return (
    !router?.route.startsWith('/accounts') &&
    !router?.route.startsWith('/settings') &&
    !router?.route.startsWith('/snacks') &&
    !router?.route.startsWith('/onboarding') &&
    !router?.route.startsWith('/feature-gates') &&
    !router?.route.startsWith('/annotations')
  );
}

export function isIndeterminateRoute(router?: NextRouter | null) {
  return router?.asPath && /accounts\/\[.*?]/.test(decodeURIComponent(router.asPath));
}

export function hasAccountSelector(router?: NextRouter | null) {
  const topLevelPages = ['/', '/snacks'];

  if (!router) {
    return false;
  }

  if (isIndeterminateRoute(router)) {
    return false;
  }

  if (isUserSettingsRoute(router)) {
    return false;
  }

  // Hide it on profile pages.
  if (isProfile(router)) {
    return false;
  }

  if (topLevelPages.includes(router.route) || isSettingsRoute(router)) {
    return true;
  }

  if (router.route.startsWith('/accounts/[account]')) {
    return true;
  }

  return false;
}

export function getPageName(
  currentUser: LoggedInProps['currentUser'],
  router?: NextRouter | null,
  account: string = ''
) {
  if (!router) {
    return null;
  }

  if (router?.asPath?.match(/^\/__\/graphiql/)) {
    return 'GraphiQL';
  }

  if (isUserSettingsRoute(router)) {
    return 'User settings';
  }

  if (isSettingsRoute(router)) {
    if (isUserAccount(account, currentUser)) {
      return 'Account settings';
    }
    if (isTeamAccount(account, currentUser)) {
      return 'Team settings';
    }
    return 'Organization settings';
  }

  return null;
}
