import { CommandMenuTrigger } from '@expo/styleguide-search-ui';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/compat/router';
import { useCallback } from 'react';

import { useSetUserPreferencesMutation } from '~/graphql/mutations/SetUserPreferences.mutation.generated';
import { useSearchDialogContext } from '~/providers/SearchDialogContext';
import { PageProps } from '~/scenes/_app/helpers';
import { hasAccountSelector } from '~/ui/components/Navigation/LoggedInNavigation/helpers';

import { AccountSelector } from './components/AccountSelector';
import { AccountSelectorSuperuser } from './components/AccountSelectorSuperuser';
import { ProjectSelector } from './components/ProjectSelector';

type NavigationSelectorsProps = {
  accountName: string;
  projectName: string;
  currentUser?: PageProps['currentUser'];
  currentDateAsString: string;
  isSuperuserMode: boolean;
};

export function NavigationSelectors({
  accountName,
  projectName,
  currentUser,
  currentDateAsString,
  isSuperuserMode,
}: NavigationSelectorsProps) {
  const router = useRouter();
  const { setOpen } = useSearchDialogContext();
  const urlAccountName = encodeURIComponent(accountName);
  const [setUserPreferences] = useSetUserPreferencesMutation();

  const onSelect = useCallback(
    debounce((selectedName: string) => {
      void setUserPreferences({
        variables: {
          preferences: {
            selectedAccountName: selectedName,
          },
        },
      });
    }, 3000),
    [] // note(tchayen): It seems that setUserPreferences() reference is stable.
  );

  return (
    <>
      <CommandMenuTrigger setOpen={setOpen} className="mx-3 w-[calc(100%-24px)]" />
      {hasAccountSelector(router) && (
        <>
          {currentUser &&
            (isSuperuserMode ? (
              <div className="mx-3">
                <AccountSelectorSuperuser
                  currentUser={currentUser}
                  currentDateAsString={currentDateAsString}
                  accountName={accountName}
                  onSelect={onSelect}
                />
              </div>
            ) : (
              <div className="mx-3">
                <AccountSelector
                  accounts={currentUser.accounts ?? []}
                  currentUser={currentUser}
                  currentDateAsString={currentDateAsString}
                  onSelect={onSelect}
                />
              </div>
            ))}
          {projectName && (
            <div className="mx-3">
              <ProjectSelector
                key={urlAccountName}
                accountName={urlAccountName}
                projectSlug={projectName}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
