import { Button, mergeClasses } from '@expo/styleguide';
import { EyeIcon } from '@expo/styleguide-icons/outline/EyeIcon';
import { EyeOffIcon } from '@expo/styleguide-icons/outline/EyeOffIcon';

import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';
import { FOOTNOTE } from '~/ui/components/text';

type Props = {
  isVisible: boolean;
  onClick: () => void;
  className?: string;
};

export function PasswordInputToggler({ className, isVisible, onClick }: Props) {
  return (
    <TooltipRoot>
      <TooltipTrigger asChild>
        <Button
          theme="quaternary"
          className={mergeClasses('absolute right-2', className)}
          aria-label={isVisible ? 'Hide password' : 'Show password'}
          leftSlot={
            isVisible ? (
              <EyeOffIcon className="icon-md text-icon-secondary" />
            ) : (
              <EyeIcon className="icon-md text-icon-secondary" />
            )
          }
          onClick={onClick}
        />
      </TooltipTrigger>
      <TooltipContent side="bottom">
        <FOOTNOTE>{isVisible ? 'Hide password' : 'Show password'}</FOOTNOTE>
      </TooltipContent>
    </TooltipRoot>
  );
}
