import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';

export class EnvironmentVariablesFeatureGate extends FeatureGate {
  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.ENVIRONMENT_VARIABLES;
  }
}

export function useEnvironmentVariablesFeatureGate() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.ENVIRONMENT_VARIABLES] ?? defaultGate;
}
