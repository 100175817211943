import {
  Link as StyleguideLink,
  LinkBase as StyleguideLinkBase,
  LinkBaseProps as StyleguideLinkBaseProps,
} from '@expo/styleguide';
import { type LinkProps as NextLinkProps } from 'next/link';
import { forwardRef, KeyboardEvent, useMemo } from 'react';

import { useFeatureGateURLQueryParams } from '~/providers/useFeatureGateURLQueryParams';

import { addFeatureGateQueryParamsToHref, shouldOpenInNewTabIfLinkIsExternal } from './helpers';

const urlsToLeakReferrerTo = ['https://chat.expo.dev', 'https://discord.gg/expo'];

export type LinkProps = StyleguideLinkBaseProps & {
  isStyled?: boolean;
  ariaLabel?: string;
} & Pick<NextLinkProps, 'prefetch'>;

export const LinkBase = forwardRef<HTMLAnchorElement, LinkProps>(function LinkBase(
  { children, href, isStyled, ariaLabel, onClick, openInNewTab, prefetch, disabled, ...rest },
  ref
) {
  const featureGateQueryParams = useFeatureGateURLQueryParams();
  const newHref =
    href && featureGateQueryParams
      ? addFeatureGateQueryParamsToHref(href, featureGateQueryParams)
      : href;

  function onKeyDown(event: KeyboardEvent<HTMLAnchorElement>) {
    if (event.key === 'Enter' && onClick) {
      onClick(event as any);
    }
  }

  const linkIsExternal = useMemo(() => {
    return href ? shouldOpenInNewTabIfLinkIsExternal(href, openInNewTab) : false;
  }, [href, openInNewTab]);

  const LinkElement = isStyled ? StyleguideLink : StyleguideLinkBase;

  const shouldLeakReferrer = urlsToLeakReferrerTo.some((url) => href?.startsWith(url));

  const skipNextLink = !!href?.includes('/blog');

  const shouldPrefetch =
    !href || skipNextLink || !!href?.startsWith('#') || disabled ? undefined : prefetch;

  return (
    <LinkElement
      href={newHref}
      ref={ref}
      aria-label={ariaLabel}
      onClick={onClick}
      onKeyDown={onKeyDown}
      skipNextLink={skipNextLink}
      disabled={disabled}
      // if `openInNewTab` is defined, it'll override the default external link behavior
      openInNewTab={(!shouldLeakReferrer && openInNewTab) ?? linkIsExternal}
      {...(shouldLeakReferrer && { target: '_blank', referrerPolicy: 'origin', rel: 'noopener' })}
      {...{ prefetch: shouldPrefetch }}
      {...rest}>
      {children}
    </LinkElement>
  );
});
