import { CommandMenu } from '@expo/styleguide-search-ui';
import { useEffect, useState } from 'react';

import * as Analytics from '~/common/analytics';
import { useAppsPaginatedQuery } from '~/graphql/queries/AppsPaginatedQuery.query.generated';
import { AccountAppsSortByField } from '~/graphql/types.generated';
import { useSearchDialogContext } from '~/providers/SearchDialogContext';

import { ProjectItem } from './ProjectItem';
import { AppNode } from './types';

const MAX_PROJECT_RESULTS = 8;

type SearchProps = {
  accountName?: string | null;
};

export function Search({ accountName }: SearchProps) {
  // Storing results in state is meant to help with blank search result list
  // when waiting for new data.
  const [apps, setApps] = useState<AppNode[]>([]);
  const [input, setInput] = useState('');

  const { isOpen, setOpen } = useSearchDialogContext();

  const { data } = useAppsPaginatedQuery({
    skip: !accountName,
    fetchPolicy: 'no-cache',
    variables: {
      first: MAX_PROJECT_RESULTS,
      accountName: accountName!,
      filter: {
        searchTerm: input,
        sortByField: AccountAppsSortByField.LatestActivityTime,
      },
    },
  });

  // This is to ensure that we wait for the next Apollo response before updating
  // the list, avoiding empty list flickering.
  useEffect(() => {
    if (data) {
      setApps(data?.account.byName.appsPaginated.edges.map((edge) => edge.node) ?? []);
    }
  }, [data]);

  if (accountName) {
    return (
      <CommandMenu
        open={isOpen}
        setOpen={setOpen}
        config={{ docsVersion: 'latest' }}
        customSections={[
          {
            heading: 'Projects',
            items: apps.map((item) => {
              return (
                <ProjectItem
                  item={item}
                  query={input ?? ''}
                  accountName={accountName}
                  key={item.slug}
                  onSelect={() => {
                    setOpen(false);
                  }}
                />
              );
            }),
            getItemsAsync: async (query) => {
              setInput(query);
              Analytics.track(Analytics.events.USER_SEARCHED, { query: input });
            },
            sectionIndex: 0,
          },
        ]}
      />
    );
  } else {
    return <CommandMenu open={isOpen} setOpen={setOpen} config={{ docsVersion: 'latest' }} />;
  }
}
