import { SuccessCheckmark } from '~/ui/components/SuccessCheckmark';
import { P } from '~/ui/components/text';

import { PopoverHeaderButtonGroup } from './PopoverHeaderButtonGroup';
import { FeedbackPopoverPage } from './shared';

type Props = {
  onDismiss: () => void;
  goBackToOptionsOnHide?: boolean;
  onSetPage?: (page: FeedbackPopoverPage) => void;
};

export function PopoverFeedbackSuccess({
  onDismiss,
  goBackToOptionsOnHide = false,
  onSetPage = () => {},
}: Props) {
  return (
    <div className="flex flex-1 flex-col items-center">
      <PopoverHeaderButtonGroup
        hasBackOption={false}
        goBackToOptionsOnHide={goBackToOptionsOnHide}
        previousPage={FeedbackPopoverPage.SUCCESS}
        onDismiss={onDismiss}
        onSetPage={onSetPage}
        title="Feedback received"
      />
      <SuccessCheckmark size="sm" className="mt-8" />
      <P className="max-w-[30ch] py-5 text-center">
        Thank you for taking the time to send us your feedback.
      </P>
    </div>
  );
}
