export class ApiError extends Error {
  code: string;
  serverStack?: string;
  metadata?: object;

  constructor(message: string, code: string = 'UNKNOWN') {
    super(message);
    this.code = code;
  }
}

ApiError.prototype.name = 'ApiError';
Object.defineProperty(ApiError.prototype, 'name', { enumerable: false });
