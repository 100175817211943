import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';

import * as Analytics from '~/common/analytics';
import { MINUTE_IN_MS } from '~/common/format-duration';
import {
  IdentifyingGitHubAppInstallationData,
  getGitHubAppInstallationsForAuthenticatedUserAsync,
} from '~/common/github-utils';
import { isOwnerOrAdminOrImplicitOwner } from '~/common/helpers';
import Storage from '~/common/storage';
import { GitHubAppEnvironment } from '~/graphql/types.generated';
import { usePageProps } from '~/providers/PagePropsContext';
import { usePrevious } from '~/providers/usePrevious';
import { PageProps } from '~/scenes/_app/helpers';

import { useGenerateGitHubUserAccessTokenMutation } from './GitHubRepositorySelector/GenerateGitHubUserAccessToken.mutation.generated';
import { useGetGitHubAppCredentialsQuery } from './queries/GetGitHubAppCredentials.query.generated';
import {
  GitHubUserDataFragment,
  useGetGitHubUserLazyQuery,
} from '../Settings/SettingsIndexScene/PersonalSettings/Connections/queries/GetGitHubUser.query.generated';

export const githubOAuthStorageKey =
  process.env.DEPLOYMENT_ENVIRONMENT === 'production'
    ? 'expo.githuboauth.storage'
    : 'staging.expo.githuboauth.storage';

export const githubOAuthStateKey = 'state';

const cookieStorage: Storage = new Storage(githubOAuthStorageKey, 'cookie');

export type GitHubLinkingAction =
  | 'user-linking-only'
  | 'app-installation'
  | 'user-linking-and-app-installation-if-needed';

function createGitHubOAuthURL({
  clientIdentifier,
  state,
}: {
  clientIdentifier: string;
  state: string;
}) {
  return `https://github.com/login/oauth/authorize?client_id=${encodeURIComponent(
    clientIdentifier
  )}&redirect_uri=${encodeURIComponent(
    `${location.origin}/github/callback/oauth`
  )}&scope=read:user,user:email&state=${encodeURIComponent(state)}`;
}

export function canCurrentUserLinkInstallationToAccount(
  accountName?: string,
  currentUser?: PageProps['currentUser']
): boolean {
  const account = currentUser?.accounts?.find((account) => account.name === accountName);

  // note(Juwan): users can't link their account to GitHub if they are not an owner or admin because this
  // involves creating a robot user

  return (
    !!currentUser?.isExpoAdmin ||
    (account ? isOwnerOrAdminOrImplicitOwner(account, currentUser?.username ?? '') : false)
  );
}

export function useGitHubLinkingFlow({
  accountName,
  skip,
}: {
  accountName?: string;
  skip?: boolean;
} = {}) {
  const [{ loading, error, githubAppInstallations, githubUser, githubUserAccessToken }, setState] =
    useState<{
      loading: boolean;
      error?: Error;
      githubUserAccessToken?: string;
      githubUser?: GitHubUserDataFragment;
      githubAppInstallations?: IdentifyingGitHubAppInstallationData[];
    }>({
      loading: true,
    });

  const {
    data: githubAppCredentialsData,
    loading: loadingGitHubAppCredentials,
    error: githubAppCredentialsError,
  } = useGetGitHubAppCredentialsQuery({ skip });

  const [getGitHubUserAsync] = useGetGitHubUserLazyQuery();
  const [generateGitHubUserAccessToken] = useGenerateGitHubUserAccessTokenMutation();
  const pollingHandle = useRef<number>();
  const { currentUser } = usePageProps();
  const prevActiveInstallations = usePrevious(githubAppInstallations);

  useEffect(
    function getInstallationsFromGitHubAPIOnMount() {
      // note(Juwan): get installations from GitHub API on first load
      void (async () => {
        if (!skip) {
          try {
            await fetchDataAsync();
          } catch (error) {
            console.error(error);

            setState((prev) => ({
              ...prev,
              loading: false,
              error: error as Error,
            }));
          }
        }
      })();
    },
    [skip]
  );

  useEffect(
    // note(Juwan): once a GitHub app installation is added to the account, we can stop polling
    function stopPollingAfterInstallation() {
      if (
        !skip &&
        prevActiveInstallations &&
        githubAppInstallations &&
        githubAppInstallations.length > prevActiveInstallations.length
      ) {
        stopPolling();
      }
    },
    [githubAppInstallations, skip]
  );

  if (skip) {
    return {
      loading: false,
      openGitHubPopup: () => undefined,
      refetch: () => undefined,
    };
  }

  async function getGitHubUserAccessTokenAsync() {
    if (githubUserAccessToken) {
      return githubUserAccessToken;
    }

    const { data } = await generateGitHubUserAccessToken({ fetchPolicy: 'network-only' });

    const newToken = data?.githubUser.generateGitHubUserAccessToken;

    if (!newToken) {
      throw new Error('Something went wrong generating a GitHub user access token.');
    }

    return newToken;
  }

  async function fetchDataAsync() {
    const { data: githubUserData } = await getGitHubUserAsync({ fetchPolicy: 'network-only' });

    if (!githubUserData?.meUserActor?.githubUser?.metadata?.login) {
      setState((prev) => ({
        ...prev,
        loading: false,
        githubAppInstallations: [],
        githubUser: undefined,
        githubUserAccessToken: undefined,
      }));
      return;
    }

    const auth = await getGitHubUserAccessTokenAsync();

    if (!auth) {
      throw new Error('Could not get GitHub user access token.');
    }

    const installations = await getGitHubAppInstallationsForAuthenticatedUserAsync(auth);

    setState((prev) => ({
      ...prev,
      loading: false,
      githubUserAccessToken: auth,
      githubUser: githubUserData.meUserActor?.githubUser ?? undefined,
      githubAppInstallations: installations,
    }));
  }

  function startPolling(interval: number) {
    stopPolling();

    pollingHandle.current = window.setInterval(async () => {
      try {
        await fetchDataAsync();
      } catch (error) {
        console.error(error);

        setState((prev) => ({
          ...prev,
          error: error as Error,
        }));
      }
    }, interval);

    // note(Juwan): stop polling after 3 mins
    setTimeout(() => {
      console.error('Could not link GitHub after 3 minutes. Stopping polling.');
      stopPolling();
    }, MINUTE_IN_MS * 3);
  }

  function stopPolling() {
    if (pollingHandle.current) {
      clearInterval(pollingHandle.current);
      pollingHandle.current = undefined;
    }
  }

  const canLinkInstallation = canCurrentUserLinkInstallationToAccount(accountName, currentUser);

  function openGitHubPopup({
    action,
    onComplete,
  }: {
    action: GitHubLinkingAction;
    onComplete?: () => void;
  }) {
    Analytics.track(
      action === 'user-linking-only'
        ? Analytics.events.GITHUB_USER_LINKING_STARTED
        : Analytics.events.GITHUB_REPO_LINKING_STARTED,
      {
        userId: currentUser?.id,
        accountName,
      }
    );

    async function handleWindowCloseAsync() {
      try {
        stopPolling();
        await fetchDataAsync();
        onComplete?.();
      } catch (error) {
        console.error(error);
      }
    }

    try {
      if (!githubAppCredentialsData) {
        throw new Error('GitHub OAuth failed: could not fetch GitHub app credentials.');
      }

      const {
        githubApp: { clientIdentifier, environment },
      } = githubAppCredentialsData;

      if (
        process.env.DEPLOYMENT_ENVIRONMENT === 'development' &&
        environment === GitHubAppEnvironment.Staging
      ) {
        // note(Juwan): the staging bot redirects to staging.expo.dev. to set up successfully when running in dev,
        // you need to use the development bot, which redirects to expo.test
        throw new Error(
          'You cannot link your account to GitHub in development mode when using the staging bot. Link your account to GitHub on staging.expo.dev or run the API server with `yarn start:docker` to use a local database and test the linking flow with expo.test.'
        );
      }

      if (
        process.env.DEPLOYMENT_ENVIRONMENT === 'pull-request' &&
        environment === GitHubAppEnvironment.Staging
      ) {
        throw new Error(
          'You cannot link your account to GitHub in a PR when using the staging bot. Link your account to GitHub on staging.expo.dev or run the API server with `yarn start:docker` to use a local database and test the linking flow with expo.test.'
        );
      }

      const state = JSON.stringify({
        action,
        nonce: nanoid(),
      } as {
        action: GitHubLinkingAction;
        nonce: string;
      });

      cookieStorage.setItem(githubOAuthStateKey, state);

      const githubOAuthURL = createGitHubOAuthURL({ clientIdentifier, state });

      // note(Juwan): we have to open the window before doing any async actions so that the browser understands that
      // this is a user-initiated action and doesn't block the popup
      const popup = window.open(
        githubOAuthURL,
        'expo-github-app-install',
        // note(Juwan): do not add noopener, it will make this call return null https://developer.mozilla.org/en-US/docs/Web/API/Window/open#noopener
        'width=800,height=800,toolbar=0,menubar=0,location=0,popup'
      );

      if (!popup) {
        throw new Error('Could not open a new window to start the GitHub OAuth flow.');
      }

      popup.focus();

      if (action !== 'user-linking-only') {
        startPolling(1000); // note(Juwan): poll for new installations as a backup if refetching after the popup closes fails
      }

      const handle = window.setInterval(async () => {
        if (popup.closed) {
          void handleWindowCloseAsync();
          window.clearInterval(handle);
        }
      }, 250);
    } catch (error) {
      console.error(error);
      alert((error as Error).message);
    }
  }

  return {
    githubAppInstallations,
    loading: loadingGitHubAppCredentials || loading,
    error: githubAppCredentialsError ?? error,
    refetch: fetchDataAsync,
    openGitHubPopup,
    canLinkInstallation,
    githubUserAccessToken,
    githubUser,
  };
}
