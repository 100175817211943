import { DocsLogo, SnackLogo } from '@expo/styleguide';
import { ThemeIcon } from '@expo/styleguide-icons/custom/ThemeIcon';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { Flag01Icon } from '@expo/styleguide-icons/outline/Flag01Icon';
import { Home02Icon } from '@expo/styleguide-icons/outline/Home02Icon';
import { LifeBuoy01Icon } from '@expo/styleguide-icons/outline/LifeBuoy01Icon';
import { LogOut03Icon } from '@expo/styleguide-icons/outline/LogOut03Icon';
import { NotificationMessageIcon } from '@expo/styleguide-icons/outline/NotificationMessageIcon';
import { Settings01Icon } from '@expo/styleguide-icons/outline/Settings01Icon';
import { UserCircleIcon } from '@expo/styleguide-icons/outline/UserCircleIcon';
import { useRouter } from 'next/compat/router';
import { useEffect } from 'react';

import { formatParams } from '~/common/formatParams';
import { getUserDisplayName } from '~/common/helpers';
import { PageProps } from '~/scenes/_app/helpers';
import { Avatar } from '~/ui/components/Avatar';
import { Button } from '~/ui/components/Button';
import { useLogout } from '~/ui/components/Navigation/LoggedInNavigation/useLogout';
import { NavigationSelectors } from '~/ui/components/SidebarNavigation/NavigationSelectors';
import { SidebarCollapsible } from '~/ui/components/SidebarNavigation/SidebarCollapsible';
import { SidebarLink } from '~/ui/components/SidebarNavigation/SidebarLink';
import { SidebarLinks } from '~/ui/components/SidebarNavigation/SidebarLinks';
import { SidebarItem, getSelectedSidebarItem } from '~/ui/components/SidebarNavigation/helpers';
import { ThemeSelector } from '~/ui/components/ThemeSelector';
import { appendRedirectParams } from '~/ui/components/authentication/helpers';
import { CALLOUT } from '~/ui/components/text';

type Props = {
  onDismiss?: () => void;
  accountName?: string;
  currentUser?: PageProps['currentUser'];
  currentDateAsString: string;
};

export function MobileNavigation({
  onDismiss,
  currentUser,
  currentDateAsString,
  accountName,
}: Props) {
  const router = useRouter();
  const { projectName } = formatParams(router?.query);

  const selectedSidebarItem = getSelectedSidebarItem(
    router?.pathname ?? '',
    null,
    true /* isMobile */
  );

  const scrollPosition = window.scrollY;

  function enableScrollLock(bodyElement: HTMLBodyElement | null) {
    if (bodyElement) {
      bodyElement.style.overflow = 'hidden';
    }
  }

  function disableScrollLock(bodyElement: HTMLBodyElement | null) {
    if (bodyElement) {
      bodyElement.style.removeProperty('overflow');
      window.scrollTo(0, scrollPosition);
    }
  }

  useEffect(function didMount() {
    // Safari allows scrolling the body element behind popovers. This sets the overflow style to prevent that.
    // Approach taken from https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/
    const bodyElement = document.querySelector('body');

    enableScrollLock(bodyElement);

    return () => {
      disableScrollLock(bodyElement);
    };
  }, []);

  const onLogout = useLogout();

  const themeRow = (
    <div className="flex items-center justify-between px-3 py-1">
      <div className="flex items-center gap-2">
        <ThemeIcon />
        <CALLOUT>Theme</CALLOUT>
      </div>
      <ThemeSelector />
    </div>
  );

  const isSuperuserMode =
    (currentUser?.isExpoAdmin &&
      !currentUser?.accounts?.find((account) => account?.name === accountName)) ??
    false;

  const staticPagesLinksSection = (
    <>
      <SidebarLink
        title="Homepage"
        Icon={Home02Icon}
        href="/home"
        onClick={onDismiss}
        isSelected={selectedSidebarItem === SidebarItem.MOBILE_HOMEPAGE}
      />
      <SidebarLink
        title={
          <span className="flex items-center gap-1">
            Docs
            <ArrowUpRightIcon className="icon-sm" />
          </span>
        }
        Icon={DocsLogo}
        href="https://docs.expo.dev"
        onClick={onDismiss}
        isSelected={selectedSidebarItem === SidebarItem.MOBILE_DOCS}
      />
      <SidebarLink
        title="Get help"
        Icon={LifeBuoy01Icon}
        href="/support"
        onClick={onDismiss}
        isSelected={selectedSidebarItem === SidebarItem.MOBILE_GET_HELP}
      />
      <SidebarLink
        title="Tools"
        href="/tools"
        onClick={onDismiss}
        isSelected={selectedSidebarItem === SidebarItem.MOBILE_TOOLS}
      />
      <SidebarLink
        title="EAS"
        href="/eas"
        onClick={onDismiss}
        isSelected={selectedSidebarItem === SidebarItem.MOBILE_EAS}
      />
      <SidebarLink
        title="Pricing"
        href="/pricing"
        onClick={onDismiss}
        isSelected={selectedSidebarItem === SidebarItem.MOBILE_PRICING}
      />
      <SidebarLink
        title="Changelog"
        href="/changelog"
        onClick={onDismiss}
        isSelected={selectedSidebarItem === SidebarItem.MOBILE_CHANGELOG}
      />
      {currentUser?.isExpoAdmin && (
        <SidebarCollapsible title="Expo Staff">
          <SidebarLink
            title="Feature gates"
            Icon={Flag01Icon}
            href="/feature-gates"
            onClick={onDismiss}
          />
          <SidebarLink
            title="Manage annotations"
            Icon={NotificationMessageIcon}
            href="/annotations"
            onClick={onDismiss}
          />
        </SidebarCollapsible>
      )}
    </>
  );

  return (
    <div className="absolute left-0 top-[60px] z-[100] flex h-[calc(100dvh-60px)] w-dvw flex-col divide-y overflow-y-auto bg-default">
      {accountName && (
        <div className="flex flex-col gap-2 border-secondary py-3">
          <NavigationSelectors
            accountName={accountName}
            projectName={projectName}
            currentUser={currentUser}
            currentDateAsString={currentDateAsString}
            isSuperuserMode={isSuperuserMode}
          />
        </div>
      )}
      {currentUser && accountName && (
        <div className="flex flex-col gap-1 border-secondary p-3">
          <SidebarLinks
            accountName={accountName}
            projectName={projectName}
            currentUser={currentUser}
            selectedSidebarItem={selectedSidebarItem}
            onClick={onDismiss}
          />
        </div>
      )}
      <div className="flex flex-col gap-1 border-secondary p-3">
        {currentUser ? (
          <>
            <div className="flex items-center gap-3 bg-default px-3 pb-2">
              <Avatar
                name={currentUser?.firstName ? currentUser.firstName : currentUser.username}
                profilePhoto={currentUser?.profilePhoto}
                size="md"
                alt="User menu"
                className="shadow-xs"
              />
              <div className="flex flex-1 flex-col truncate">
                <CALLOUT weight="medium" className="truncate">
                  {getUserDisplayName(currentUser)}
                </CALLOUT>
                <CALLOUT theme="secondary" className="truncate">
                  {currentUser.bestContactEmail}
                </CALLOUT>
              </div>
            </div>
            <SidebarLink
              title="Profile"
              Icon={UserCircleIcon}
              href={`/@${currentUser.username}`}
              onClick={onDismiss}
              isSelected={selectedSidebarItem === SidebarItem.USER_PROFILE}
            />
            <SidebarLink
              title="Snacks"
              Icon={SnackLogo}
              href={`/accounts/${currentUser.username}/snacks`}
              onClick={onDismiss}
              isSelected={selectedSidebarItem === SidebarItem.ACCOUNT_SNACKS}
            />
            {themeRow}
            <SidebarLink
              title="User settings"
              Icon={Settings01Icon}
              href="/settings"
              onClick={onDismiss}
              isSelected={selectedSidebarItem === SidebarItem.USER_SETTINGS}
            />
            {staticPagesLinksSection}
            <SidebarLink
              title="Log out"
              Icon={LogOut03Icon}
              onClick={onLogout}
              destructive
              data-testid="logout-button"
            />
          </>
        ) : (
          <div className="flex flex-col gap-3">
            <Button
              prefetch={false}
              block
              href={appendRedirectParams('/signup', router?.query)}
              onClick={onDismiss}>
              Sign Up
            </Button>
            <Button
              prefetch={false}
              theme="secondary"
              block
              href={appendRedirectParams('/login', router?.query)}
              onClick={onDismiss}>
              Log In
            </Button>
            {themeRow}
            <div className="flex flex-col gap-1">{staticPagesLinksSection}</div>
          </div>
        )}
      </div>
    </div>
  );
}
