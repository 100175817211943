import { FeatureGateKey } from '~/common/gating/FeatureGateKey';
import FeatureGateOverrides from '~/common/gating/FeatureGateOverrides';
import * as FeatureGateTestOverrides from '~/common/gating/FeatureGateTestOverrides';
import { Tier } from '~/common/gating/Tier';
import { getCurrentTier } from '~/common/gating/getCurrentTier';

export default abstract class FeatureGate {
  constructor(
    private readonly serverValue: boolean | undefined,
    private readonly featureGateOverrides: FeatureGateOverrides
  ) {}

  /**
   * Provide a set of Tiers that the gate is enabled for all users (logged in or logged out) .
   */

  protected isRolledOut?(): boolean;

  protected getFullyRolledOutTiers?(): ReadonlySet<Tier>;

  protected abstract getExperimentKey(): FeatureGateKey;

  // accounts we are whitelisting for this experiment
  protected getFullyRolledOutProductionAccounts?(): ReadonlySet<string>;
  protected getFullyRolledOutStagingAccounts?(): ReadonlySet<string>;

  // accounts that are associated with the current user.
  protected getAccounts?(): string[];

  // IPs we are whitelisting for this experiment
  protected getFullyRolledOutIPs?(): ReadonlySet<string>;

  // IPs that are associated with the current user.
  protected getIP?(): string;

  public isEnabled() {
    const experimentKey = this.getExperimentKey();

    if (process.env.NODE_ENV === 'test' && FeatureGateTestOverrides.isOverridden(experimentKey)) {
      return FeatureGateTestOverrides.getOverride(experimentKey);
    }

    if (this.featureGateOverrides.isOverridden(experimentKey)) {
      return this.featureGateOverrides.getOverride(experimentKey);
    }

    if (this.isRolledOut?.()) {
      return true;
    }

    if (this.getFullyRolledOutTiers?.().has(getCurrentTier())) {
      return true;
    }

    if (this.getRelatedAccounts()?.size) {
      return true;
    }

    if (this.getIP?.() && this.getFullyRolledOutIPs?.().has(this.getIP())) {
      return true;
    }

    if (this.serverValue) {
      return true;
    }

    return false;
  }

  protected getFullyRolledOutAccounts() {
    return (
      (getCurrentTier() === Tier.PRODUCTION
        ? this.getFullyRolledOutProductionAccounts
        : this.getFullyRolledOutStagingAccounts)?.() ?? new Set<string>()
    );
  }

  protected getRelatedAccounts() {
    return new Set(
      (this.getAccounts?.() ?? []).filter((account) =>
        this.getFullyRolledOutAccounts?.().has(account)
      )
    );
  }

  public getMetadata() {
    return {
      relatedAccounts: this.getRelatedAccounts(),
      experimentKey: this.getExperimentKey(),
      fullyRolledOutTiers: this.getFullyRolledOutTiers?.(),
      fullyRolledOutAccounts: this.getFullyRolledOutAccounts?.(),
      fullyRolledOutIPs: this.getFullyRolledOutIPs?.(),
    };
  }

  /**
   * NOTE: this is for admin/debugging purposes only. Keep in sync with isEnabled().
   */
  public getReasonOfEnabled() {
    const experimentKey = this.getExperimentKey();

    if (process.env.NODE_ENV === 'test' && FeatureGateTestOverrides.isOverridden(experimentKey)) {
      return 'test';
    }

    if (this.featureGateOverrides.isOverridden(experimentKey)) {
      return 'manual override';
    }

    if (this.isRolledOut?.()) {
      return 'production';
    }

    if (this.getFullyRolledOutTiers?.().has(getCurrentTier())) {
      const t = Tier[getCurrentTier()];
      let string = '';
      switch (t) {
        case 'DEVELOPMENT':
          string = 'dev';
          break;
        case 'STAGING':
          string = 'staging';
          break;
        case 'PULL_REQUEST':
          string = 'PR';
          break;
        case 'PRODUCTION':
          string = 'prod';
          break;
      }
      return `current tier (${string})`;
    }

    if (this.getAccounts?.().some((account) => this.getFullyRolledOutAccounts?.().has(account))) {
      return 'account';
    }

    if (this.getIP?.() && this.getFullyRolledOutIPs?.().has(this.getIP())) {
      return 'IP';
    }

    if (this.serverValue) {
      return 'server value';
    }

    return undefined;
  }

  /**
   * Test gate override methods
   */

  public static overrideKeyForScope(key: FeatureGateKey, enabled: boolean, scope: () => void) {
    if (process.env.NODE_ENV !== 'test') {
      throw new Error(`Cannot overrideKeyForScope outside of test environment`);
    }

    FeatureGateTestOverrides.setOverride(key, enabled);
    try {
      scope();
    } finally {
      FeatureGateTestOverrides.removeOverride(key);
    }
  }

  public static async overrideKeyForScopeAsync(
    key: FeatureGateKey,
    enabled: boolean,
    scope: () => Promise<void>
  ) {
    if (process.env.NODE_ENV !== 'test') {
      throw new Error(`Cannot overrideKeyForScopeAsync outside of test environment`);
    }

    FeatureGateTestOverrides.setOverride(key, enabled);
    try {
      await scope();
    } finally {
      FeatureGateTestOverrides.removeOverride(key);
    }
  }

  public static overrideKeyForEachInTest(key: FeatureGateKey, enabled: boolean) {
    beforeEach(() => {
      FeatureGateTestOverrides.setOverride(key, enabled);
    });
    afterEach(() => {
      FeatureGateTestOverrides.removeOverride(key);
    });
  }

  public getServerValue() {
    return this.serverValue;
  }
}
