import { ComponentType } from 'react';

import ErrorPage from '~/pages/_error';
import Document from '~/ui/components/Document';

import { LoggedInProps } from './helpers';

export function withAuthAndAccountRequired<P extends LoggedInProps>(
  Component: ComponentType<P>,
  { hideForSSOViewer = false }: { hideForSSOViewer?: boolean } = {}
) {
  function WithAuthAndAccountRequired(props: P) {
    const { currentUser, accountName } = props;
    if (
      !currentUser ||
      !accountName ||
      (hideForSSOViewer && currentUser.__typename === 'SSOUser')
    ) {
      return (
        <Document meta={{ name: 'error' }}>
          <ErrorPage statusCode={404} />
        </Document>
      );
    }

    return <Component {...props} />;
  }

  WithAuthAndAccountRequired.pageOptions = {
    authRequired: true,
  };

  return WithAuthAndAccountRequired;
}
