// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import { AppDataShortFragmentDoc } from '../../../../graphql/fragments/AppDataShort.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectSelectorQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  accountName: Types.Scalars['String']['input'];
}>;


export type ProjectSelectorQuery = { __typename?: 'RootQuery', account: { __typename?: 'AccountQuery', byName: { __typename?: 'Account', id: string, appsPaginated: { __typename?: 'AccountAppsConnection', edges: Array<{ __typename?: 'AccountAppsEdge', node: { __typename: 'App', id: string, iconUrl?: string | null, name: string, slug: string, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null } }> } } } };


export const ProjectSelectorQueryDocument = gql`
    query ProjectSelectorQuery($first: Int!, $accountName: String!) {
  account {
    byName(accountName: $accountName) {
      id
      appsPaginated(first: $first) {
        edges {
          node {
            id
            ...AppDataShort
          }
        }
      }
    }
  }
}
    ${AppDataShortFragmentDoc}`;
export function useProjectSelectorQuery(baseOptions: Apollo.QueryHookOptions<ProjectSelectorQuery, ProjectSelectorQueryVariables> & ({ variables: ProjectSelectorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectSelectorQuery, ProjectSelectorQueryVariables>(ProjectSelectorQueryDocument, options);
      }
export function useProjectSelectorQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectSelectorQuery, ProjectSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectSelectorQuery, ProjectSelectorQueryVariables>(ProjectSelectorQueryDocument, options);
        }
export function useProjectSelectorQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectSelectorQuery, ProjectSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectSelectorQuery, ProjectSelectorQueryVariables>(ProjectSelectorQueryDocument, options);
        }
export type ProjectSelectorQueryHookResult = ReturnType<typeof useProjectSelectorQuery>;
export type ProjectSelectorQueryLazyQueryHookResult = ReturnType<typeof useProjectSelectorQueryLazyQuery>;
export type ProjectSelectorQuerySuspenseQueryHookResult = ReturnType<typeof useProjectSelectorQuerySuspenseQuery>;
export type ProjectSelectorQueryQueryResult = Apollo.QueryResult<ProjectSelectorQuery, ProjectSelectorQueryVariables>;
export function refetchProjectSelectorQuery(variables: ProjectSelectorQueryVariables) {
      return { query: ProjectSelectorQueryDocument, variables: variables }
    }