import { mergeClasses } from '@expo/styleguide';
import type { ReactNode } from 'react';

import { CALLOUT } from '~/ui/components/text';

type Props = {
  description: ReactNode;
  className?: string;
};

export function BoxNotice({ description, className }: Props) {
  return (
    <div className={mergeClasses('rounded-b-md bg-subtle px-6 py-3', className)}>
      {typeof description === 'string' ? <CALLOUT>{description}</CALLOUT> : description}
    </div>
  );
}
