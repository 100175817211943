import { mergeClasses } from '@expo/styleguide';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

export function BoxFooter({ children, className, ...rest }: Props) {
  return (
    <div
      className={mergeClasses(
        'flex items-center justify-end rounded-b-md border-t border-t-secondary bg-subtle p-3',
        className
      )}
      {...rest}>
      {children}
    </div>
  );
}
