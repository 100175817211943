import { useRouter } from 'next/compat/router';
import type { PropsWithChildren, ReactNode } from 'react';

import { ERROR_TITLE_404, ERROR_MESSAGE_404 } from '~/common/errors/errorMessages';
import { getAccountPathPrefix } from '~/common/getAccountPathPrefix';
import { Button } from '~/ui/components/Button';
import { NotFound } from '~/ui/components/Error/NotFound';
import { ServerError } from '~/ui/components/Error/ServerError';
import { H1, H5 } from '~/ui/components/text';

type Props = PropsWithChildren<{
  title: string;
  statusCode?: number | string;
  button?: ReactNode;
}>;

const ErrorMessage = ({
  title = ERROR_TITLE_404,
  children = ERROR_MESSAGE_404,
  statusCode,
  button,
}: Props) => {
  const router = useRouter();

  return (
    <div className="m-auto flex w-full max-w-screen-md flex-col items-center justify-center gap-6 pb-[164px] pt-[124px] text-center">
      {statusCode === 404 && <NotFound />}
      {statusCode === 500 && <ServerError />}
      <H1 size="xl">{title}</H1>
      <H5 size="xl" weight="regular" tag="p" className="max-w-[500px]">
        {children}
      </H5>
      {button ?? (
        <Button href={`${getAccountPathPrefix(router?.query?.account)}/`}>Return Home</Button>
      )}
    </div>
  );
};

export default ErrorMessage;
