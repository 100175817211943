// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetUserPreferencesMutationVariables = Types.Exact<{
  preferences: Types.UserPreferencesInput;
}>;


export type SetUserPreferencesMutation = { __typename?: 'RootMutation', me: { __typename?: 'MeMutation', setPreferences: { __typename?: 'UserPreferences', selectedAccountName?: string | null, onboarding?: { __typename?: 'UserPreferencesOnboarding', appId: string, platform?: Types.AppPlatform | null, lastUsed: string, isCLIDone?: boolean | null } | null } } };


export const SetUserPreferencesDocument = gql`
    mutation SetUserPreferences($preferences: UserPreferencesInput!) {
  me {
    setPreferences(preferences: $preferences) {
      selectedAccountName
      onboarding {
        appId
        platform
        lastUsed
        isCLIDone
      }
    }
  }
}
    `;
export function useSetUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>(SetUserPreferencesDocument, options);
      }
export type SetUserPreferencesMutationHookResult = ReturnType<typeof useSetUserPreferencesMutation>;
export type SetUserPreferencesMutationResult = Apollo.MutationResult<SetUserPreferencesMutation>;
export type SetUserPreferencesMutationOptions = Apollo.BaseMutationOptions<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>;