import { mergeClasses } from '@expo/styleguide';
import { Settings01Icon } from '@expo/styleguide-icons/outline/Settings01Icon';
import { motion, useReducedMotion } from 'framer-motion';

type Props = {
  testID?: string;
  title?: string;
  className?: string;
  iconClassName?: string;
};

export function CogActivityIndicator({ testID, title, className, iconClassName }: Props) {
  const shouldReduceMotion = useReducedMotion();

  return (
    <motion.div
      animate={{ rotate: 360 }}
      transition={{ ease: 'linear', duration: 2, repeat: shouldReduceMotion ? 0 : Infinity }}
      data-testid={testID}
      className={className}
      style={{ display: 'grid', placeItems: 'center' }}>
      <Settings01Icon className={mergeClasses('icon-sm', iconClassName)} title={title} />
    </motion.div>
  );
}
