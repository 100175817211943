import { ApolloError } from '@apollo/client';
import { mergeClasses } from '@expo/styleguide';
import { breakpoints } from '@expo/styleguide-base';
import { Menu01Icon } from '@expo/styleguide-icons/outline/Menu01Icon';
import { Star01Icon } from '@expo/styleguide-icons/outline/Star01Icon';
import { XIcon } from '@expo/styleguide-icons/outline/XIcon';
import { CommandMenuTrigger } from '@expo/styleguide-search-ui';
import { useRouter } from 'next/compat/router';
import { useEffect, useState } from 'react';

import * as Analytics from '~/common/analytics';
import { useSearchDialogContext } from '~/providers/SearchDialogContext';
import { useWindowPageYOffset } from '~/providers/useWindowPageYOffset';
import { Button } from '~/ui/components/Button';
import { InlineHelp } from '~/ui/components/InlineHelp';
import { Logo } from '~/ui/components/Navigation/Logo';
import { appendRedirectParams } from '~/ui/components/authentication/helpers';
import { CALLOUT, A } from '~/ui/components/text';

import { ToolsButton } from './ToolsButton';
import { MobileNavigation } from '../MobileNavigation';

type Props = {
  currentDateAsString: string;
  error?: ApolloError;
};

export const ROUTES_WITHOUT_BORDER = ['/home'];

export const breakpointEdge = (breakpoints.medium + breakpoints.large) / 2;

export function LoggedOutNavigation({ currentDateAsString, error }: Props) {
  const { setOpen } = useSearchDialogContext();
  const router = useRouter();
  const pageYOffset = useWindowPageYOffset();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Make sure to keep in sync with breakpoints in the CSS below.
    const matchMedia = window.matchMedia(`(max-width: ${breakpointEdge}px)`);

    const listener = () => {
      if (!matchMedia.matches) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const shouldIncludeBorder = router?.route ? !ROUTES_WITHOUT_BORDER.includes(router.route) : true;
  const isToolsPage = router?.asPath === '/tools';

  return (
    <nav>
      {error && (
        <InlineHelp type="warning" className="rounded-none border-0 border-b">
          <CALLOUT>
            We encountered an error that prevented us from loading authentication information. Try
            reloading the page or try again later. Check our{' '}
            <A href="https://status.expo.dev" isStyled openInNewTab>
              status page
            </A>{' '}
            for more.
          </CALLOUT>
        </InlineHelp>
      )}
      <div
        className={mergeClasses(
          'sticky top-0 z-20 grid h-15 grid-cols-[1fr_auto] items-center gap-4 border-b border-transparent bg-default px-4',
          (shouldIncludeBorder || pageYOffset > 0) && 'border-default'
        )}>
        <Logo />
        <div className="grid grid-flow-col items-center gap-2 max-lg-gutters:hidden">
          <CommandMenuTrigger
            setOpen={setOpen}
            className="min-w-[226px] max-xl-gutters:min-w-[180px]"
          />
          <Button theme="quaternary" href="https://docs.expo.dev">
            Docs
          </Button>
          <ToolsButton isToolsPage={isToolsPage} />
          <Button prefetch={false} theme="quaternary" href="/eas">
            EAS
          </Button>
          <Button prefetch={false} theme="quaternary" href="/pricing">
            Pricing
          </Button>
          <Button prefetch={false} theme="quaternary" href="/careers">
            Careers
          </Button>
          <Button
            theme="quaternary"
            className="pl-3"
            leftSlot={<Star01Icon />}
            href="https://github.com/expo/expo"
            onClick={() => {
              Analytics.track(Analytics.events.STAR_US_ON_GITHUB_CLICKED);
            }}
            openInNewTab>
            <span className="max-xl-gutters:hidden">Star Us on </span>
            <span>GitHub</span>
          </Button>
          <Button
            prefetch={false}
            theme="quaternary"
            href={appendRedirectParams('/signup', router?.query)}
            className="border-palette-gray12 bg-palette-gray12 text-palette-gray1 hocus:bg-palette-gray12 hocus:opacity-80">
            Sign Up
          </Button>
          <Button
            prefetch={false}
            theme="quaternary"
            href={appendRedirectParams('/login', router?.query)}>
            Log In
          </Button>
        </div>
        <Button
          theme="quaternary"
          className="hidden max-lg-gutters:flex"
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
          leftSlot={
            isMenuOpen ? (
              <XIcon className="icon-md text-icon-default" />
            ) : (
              <Menu01Icon className="icon-md text-icon-default" />
            )
          }
        />
        {isMenuOpen && (
          <MobileNavigation
            onDismiss={() => {
              setIsMenuOpen(false);
            }}
            currentDateAsString={currentDateAsString}
          />
        )}
      </div>
    </nav>
  );
}
