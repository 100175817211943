import { mergeClasses } from '@expo/styleguide';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { ChevronDownIcon } from '@expo/styleguide-icons/outline/ChevronDownIcon';
import { useState } from 'react';

import { LinkBase } from '~/ui/components/LinkBase';
import { P, DEMI } from '~/ui/components/text';

type FooterLinkProps = {
  href: string;
  label: string;
  openInNewTab?: boolean;
  onClick?: () => void;
};

type Props = {
  label: string;
  links: FooterLinkProps[];
};

export function FooterLinks({ label, links }: Props) {
  const [open, setOpen] = useState(false);

  function onFooterLinkClick() {
    setOpen((prevOpen) => !prevOpen);
  }

  const inputId = `footerLinks-${label}`;

  return (
    <div aria-label={open ? 'Close Navigation Menu' : 'Open Navigation Menu'}>
      <input
        id={inputId}
        onChange={onFooterLinkClick}
        type="checkbox"
        className="invisible max-md-gutters:flex"
        checked={open}
      />
      <label
        htmlFor={inputId}
        className={mergeClasses(
          'pointer-events-none flex items-center justify-between rounded-md px-3.5 py-2',
          'max-md-gutters:pointer-events-auto max-md-gutters:cursor-pointer max-md-gutters:border max-md-gutters:border-secondary max-md-gutters:px-3.5',
          'max-md-gutters:hocus:bg-subtle'
        )}>
        <DEMI>{label}</DEMI>
        <ChevronDownIcon
          className={mergeClasses(
            'hidden text-icon-secondary transition-transform duration-300',
            'max-md-gutters:block',
            open && 'rotate-180'
          )}
        />
      </label>
      <ul
        className={mergeClasses(
          'list-none',
          'max-md-gutters:mt-2',
          !open && 'max-md-gutters:hidden'
        )}>
        {links.map(({ href, openInNewTab, label, onClick }) => (
          <li key={`footer-${label}`}>
            <LinkBase
              prefetch={false}
              href={href}
              onClick={onClick}
              openInNewTab={openInNewTab}
              isStyled
              skipNextLink={href.includes('/blog')}
              className="block rounded-md px-3.5 py-2 transition-colors hocus:bg-subtle">
              <P theme="secondary" className="inline-flex items-center gap-1">
                {label}
                {openInNewTab && <ArrowUpRightIcon className="icon-sm text-icon-secondary" />}
              </P>
            </LinkBase>
          </li>
        ))}
      </ul>
    </div>
  );
}
