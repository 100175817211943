import { CALLOUT } from '~/ui/components/text';

type SectionHeaderProps = {
  title: string;
};

export function SidebarSectionHeader({ title }: SectionHeaderProps) {
  return (
    <CALLOUT theme="secondary" className="px-3 pt-2">
      {title}
    </CALLOUT>
  );
}
