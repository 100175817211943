import { useRouter } from 'next/compat/router';
import { ReactNode, useEffect } from 'react';

import * as Analytics from '~/common/analytics';
import {
  ERROR_MESSAGE_404,
  ERROR_MESSAGE_500,
  ERROR_TITLE_404,
  ERROR_TITLE_500,
} from '~/common/errors/errorMessages';

import ErrorMessage from './ErrorMessage';

type Props = {
  statusCode?: number | string;
  title?: string;
  text?: ReactNode;
  button?: ReactNode;
};

export function ErrorPage({
  statusCode,
  title = ERROR_TITLE_500,
  text = ERROR_MESSAGE_500,
  button,
}: Props) {
  const router = useRouter();

  useEffect(function didMount() {
    Analytics.track(Analytics.events.USER_PAGE_VIEWED, {
      url: '/_error',
      statusCode,
      pathname: router?.pathname,
    });
  }, []);

  if (String(statusCode) === '404') {
    title = ERROR_TITLE_404;
    text = ERROR_MESSAGE_404;
  }

  return (
    <ErrorMessage title={title} statusCode={statusCode} button={button}>
      {text}
    </ErrorMessage>
  );
}
