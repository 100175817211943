import { mergeClasses } from '@expo/styleguide';
import {
  Arrow,
  Content,
  DropdownMenuContentProps,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import type { ReactNode } from 'react';

type Props = DropdownMenuContentProps & {
  trigger: ReactNode;
  disabled?: boolean;
};

export function Dropdown({
  children,
  trigger,
  className,
  sideOffset = 0,
  collisionPadding = { left: 16, right: 16 },
  side = 'bottom',
  disabled = false,
  ...rest
}: Props) {
  return (
    <Root>
      <Trigger disabled={disabled} asChild>
        {trigger}
      </Trigger>
      <Portal>
        <Content
          className={mergeClasses(
            'flex min-w-[220px] flex-col gap-0.5 rounded-md border border-default bg-default p-1 shadow-md',
            'will-change-[opacity,transform]',
            'data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
            'z-[605]', // to be above the dialogs (601)
            className
          )}
          side={side}
          sideOffset={sideOffset}
          collisionPadding={collisionPadding}
          {...rest}>
          <Arrow asChild>
            <div className="relative -top-1 size-2.5 rotate-45 border-b border-r border-default bg-default" />
          </Arrow>
          {children}
        </Content>
      </Portal>
    </Root>
  );
}
