import { ApolloClient } from '@apollo/client';
import invariant from 'invariant';

import * as Analytics from '~/common/analytics';
import type { APIV2Client } from '~/common/api-v2-client';
import {
  getCurrentSessionSecret,
  removeBrowserDataAsync,
  saveSessionData,
} from '~/common/auth-manager';
import { GenericError } from '~/common/errors/GenericError';
import * as Validations from '~/common/validations';
import {
  CurrentUserActorAnalyticsQuery,
  CurrentUserActorAnalyticsDocument,
} from '~/graphql/queries/CurrentUserActorAnalyticsQuery.query.generated';

export const logoutAsync = async (
  apiV2Client: APIV2Client,
  { client, source }: { client: ApolloClient<any>; source: string }
) => {
  let endSessionRedirectUrl;
  const currentSessionSecret = getCurrentSessionSecret();
  if (currentSessionSecret) {
    try {
      // delete login session stored on servers
      const result = await apiV2Client.sendAuthenticatedApiV2RequestAsync<{
        endSessionRedirectUrl?: string;
      }>('auth/logout');
      if (result.endSessionRedirectUrl) {
        endSessionRedirectUrl = result.endSessionRedirectUrl + '&state=' + source;
      }
    } catch (error) {
      console.error(error);
    }
  }

  await removeBrowserDataAsync({ client });
  return { endSessionRedirectUrl };
};

export const handleLogoutAsync = async (apiV2Client: APIV2Client, client: ApolloClient<any>) => {
  Analytics.track(Analytics.events.USER_LOGGED_OUT, {});
  return await logoutAsync(apiV2Client, { client, source: 'logout' });
};

const loginAsync = async (
  apiV2Client: APIV2Client,
  data: {
    username: string;
    password: string;
    otp?: string;
  }
) => {
  return await apiV2Client.sendUnauthenticatedApiV2RequestAsync<{ sessionSecret: string }>(
    'auth/loginAsync',
    {
      body: data,
    }
  );
};

const loginWithUsernamePasswordAsync = async (
  apiV2Client: APIV2Client,
  client: ApolloClient<any>,
  {
    username,
    password,
    otp,
  }: {
    username: string;
    password: string;
    otp?: string;
  }
): Promise<{ id: string; username: string; email: string }> => {
  if (!username) {
    throw new GenericError(Validations.messages.USERNAME_NO_EXIST);
  } else if (!password) {
    throw new GenericError(Validations.messages.PASSWORD_NO_EXIST);
  }

  const loginResult = await loginAsync(apiV2Client, { username, password, otp });

  saveSessionData(loginResult.sessionSecret);

  const currentUserActorAnalyticsResult = await client.query<CurrentUserActorAnalyticsQuery>({
    query: CurrentUserActorAnalyticsDocument,
    fetchPolicy: 'network-only',
    context: {
      headers: { 'expo-session': loginResult.sessionSecret },
    },
  });
  const currentUserActor = currentUserActorAnalyticsResult.data.meUserActor;
  if (!currentUserActor) {
    throw new Error('User fetch after sign up failed');
  }

  invariant(currentUserActor?.__typename === 'User', 'Logged in user must have type User');
  return {
    id: currentUserActor.id,
    username: currentUserActor.username,
    email: currentUserActor.email,
  };
};

export const handleLoginAsync = async (
  apiV2Client: APIV2Client,
  {
    client,
    formData,
  }: {
    client: ApolloClient<any>;
    formData: {
      username: string;
      password: string;
      otp?: string;
    };
  }
) => {
  await logoutAsync(apiV2Client, { client, source: 'login' });

  const viewer = await loginWithUsernamePasswordAsync(apiV2Client, client, {
    username: formData.username,
    password: formData.password,
    otp: formData.otp,
  });

  Analytics.identify(viewer.id, {
    username: viewer.username,
    email: viewer.email,
  });
  Analytics.track(Analytics.events.USER_LOGGED_IN, {
    id: viewer.id,
  });

  return viewer;
};

export const handleUpgradeSudoAsync = async (
  apiV2Client: APIV2Client,
  data: {
    username: string;
    password: string;
    otp?: string;
  }
) => {
  if (!data.username) {
    throw new GenericError(Validations.messages.USERNAME_NO_EXIST);
  } else if (!data.password) {
    throw new GenericError(Validations.messages.PASSWORD_NO_EXIST);
  }

  return await apiV2Client.sendAuthenticatedApiV2RequestAsync<{ status: 'success' }>(
    'auth/upgradeSudo',
    {
      body: data,
    }
  );
};

export type SignupFormDataType = {
  email: string;
  username: string;
  password: string;
  viewUpgradePlans?: string;
  recaptchaResponseToken?: string;
  withOrganization?: boolean;
};

const signupAsync = async (
  apiV2Client: APIV2Client,
  {
    client,
    formData,
  }: {
    client: ApolloClient<any>;
    formData: SignupFormDataType;
  }
) => {
  const { email, username, password, recaptchaResponseToken, withOrganization } = formData;
  const userData = {
    email,
    username,
    password,
    given_name: '',
    family_name: '',
  };

  const createUserResult = await apiV2Client.sendOptionallyAuthenticatedApiV2RequestAsync<{
    sessionSecret: string;
    organizationId?: string;
  }>('auth/create-user', {
    body: {
      userData,
      recaptchaResponseToken,
      withOrganization,
    },
  });

  saveSessionData(createUserResult.sessionSecret);

  const currentUserActorAnalyticsResult = await client.query<CurrentUserActorAnalyticsQuery>({
    query: CurrentUserActorAnalyticsDocument,
    fetchPolicy: 'network-only',
    context: {
      headers: { 'expo-session': createUserResult.sessionSecret },
    },
  });
  const currentUserActor = currentUserActorAnalyticsResult.data.meUserActor;
  if (!currentUserActor) {
    throw new Error('User fetch after sign up failed');
  }

  invariant(currentUserActor?.__typename === 'User', 'Logged in user must have type User');
  return {
    id: currentUserActor.id,
    username: currentUserActor.username,
    email: currentUserActor.email,
    organizationId: createUserResult.organizationId,
  };
};

export const handleSignupAsync = async (
  apiV2Client: APIV2Client,
  client: ApolloClient<any>,
  { formData }: { formData: SignupFormDataType }
) => {
  await logoutAsync(apiV2Client, { client, source: 'signup' });

  const viewer = await signupAsync(apiV2Client, { client, formData });

  Analytics.identify(viewer.id, {
    username: viewer.username,
    email: viewer.email,
  });
  Analytics.track(Analytics.events.USER_CREATED_ACCOUNT, { id: viewer.id });
  Analytics.gtagEvent({
    action: Analytics.googleAnalyticsEvents.SIGN_UP,
    category: 'Form Submission',
    label: 'Sign Up',
  });
  Analytics.googleAdwordsEvent({
    trackingEventId: Analytics.googleAdwordsEventIds.SIGN_UP_FORM_SUBMISSION,
    googleAdsId: Analytics.googleAdWordsTagGlobalTagId,
  });

  if (formData.viewUpgradePlans) {
    Analytics.track(Analytics.events.EAS_SHOUTOUT, {
      action: Analytics.EASShoutoutActions.SIGNUP_AND_LEARN_MORE,
      userId: viewer.id,
    });

    Analytics.track(Analytics.events.USER_CLICKED_CTA, {
      name: 'dev-services-fast-forward-signup-option',
      userId: viewer.id,
    });
  }

  return viewer;
};

export const handleSendSMSOTPAsync = async (
  apiV2Client: APIV2Client,
  {
    username,
    password,
    secondFactorDeviceID,
  }: {
    username: string;
    password: string;
    secondFactorDeviceID: string;
  }
) => {
  await apiV2Client.sendUnauthenticatedApiV2RequestAsync('auth/send-sms-otp', {
    body: { username, password, secondFactorDeviceID },
  });
};

export const handleSendVerificationEmailAsync = async (apiV2Client: APIV2Client) => {
  await apiV2Client.sendAuthenticatedApiV2RequestAsync('auth/send-verification-email');
};
