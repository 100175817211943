import { useState, useEffect } from 'react';

export function useWindowPageYOffset() {
  const [pageYOffset, setPageYOffset] = useState(0);

  useEffect(function didMount() {
    function updatePageYOffset() {
      setPageYOffset(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePageYOffset);
    updatePageYOffset();
    return function willUnmount() {
      window.removeEventListener('scroll', updatePageYOffset);
    };
  }, []);

  return pageYOffset;
}
