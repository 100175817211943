// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import { AppDataWithRepoFragmentDoc } from '../../../../../graphql/fragments/AppDataWithRepo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PinnedAppsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PinnedAppsQuery = { __typename?: 'RootQuery', meUserActor?: { __typename?: 'SSOUser', id: string, pinnedApps: Array<{ __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null, metadata: { __typename?: 'GitHubRepositoryMetadata', githubRepoName: string, githubRepoOwnerName: string } } | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string } }> } | { __typename?: 'User', id: string, pinnedApps: Array<{ __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null, metadata: { __typename?: 'GitHubRepositoryMetadata', githubRepoName: string, githubRepoOwnerName: string } } | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string } }> } | null };


export const PinnedAppsDocument = gql`
    query PinnedApps {
  meUserActor {
    id
    pinnedApps {
      ...AppDataWithRepo
    }
  }
}
    ${AppDataWithRepoFragmentDoc}`;
export function usePinnedAppsQuery(baseOptions?: Apollo.QueryHookOptions<PinnedAppsQuery, PinnedAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PinnedAppsQuery, PinnedAppsQueryVariables>(PinnedAppsDocument, options);
      }
export function usePinnedAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PinnedAppsQuery, PinnedAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PinnedAppsQuery, PinnedAppsQueryVariables>(PinnedAppsDocument, options);
        }
export function usePinnedAppsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PinnedAppsQuery, PinnedAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PinnedAppsQuery, PinnedAppsQueryVariables>(PinnedAppsDocument, options);
        }
export type PinnedAppsQueryHookResult = ReturnType<typeof usePinnedAppsQuery>;
export type PinnedAppsLazyQueryHookResult = ReturnType<typeof usePinnedAppsLazyQuery>;
export type PinnedAppsSuspenseQueryHookResult = ReturnType<typeof usePinnedAppsSuspenseQuery>;
export type PinnedAppsQueryResult = Apollo.QueryResult<PinnedAppsQuery, PinnedAppsQueryVariables>;
export function refetchPinnedAppsQuery(variables?: PinnedAppsQueryVariables) {
      return { query: PinnedAppsDocument, variables: variables }
    }