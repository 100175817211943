import { nanoid } from 'nanoid';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { useConfigureGitHubBuildTriggersForNewProject } from '~/common/github-utils';
import { IS_VALID_SLUG_REGEX, generateSlug } from '~/common/helpers';
import { useAppExistsByFullNameLazyQuery } from '~/graphql/queries/AppExistsByFullName.query.generated';
import { AppPrivacy } from '~/graphql/types.generated';
import { useCreateProjectMutation } from '~/scenes/Dashboard/Projects/AllProjectsScene/queries/CreateProject.mutation.generated';
import { useGitHubLinkingFlow } from '~/scenes/ProjectGitHubBotSettingsScene/useGitHubLinkingFlow';
import { getFormFieldData } from '~/ui/components/form/Form';
import { FormStates } from '~/ui/components/form/FormStates';

export function useProjectCreationFormState({
  accountId,
  accountName,
}: {
  accountId: string;
  accountName: string;
}) {
  const [formState, setFormState] = useState(FormStates.IDLE);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [slugError, setSlugError] = useState<string | undefined>(undefined);
  const [generatedSlug, setGeneratedSlug] = useState<string>('');
  const [shouldCreateRepository, setCreateRepository] = useState<boolean>(false);
  const [selectedInstallationIdentifier, setSelectedInstallationIdentifier] = useState<
    string | null
  >(null);

  const {
    openGitHubPopup,
    canLinkInstallation,
    githubAppInstallations,
    loading: ghLoading,
  } = useGitHubLinkingFlow({ accountName });

  useEffect(() => {
    if (githubAppInstallations?.[0]) {
      setSelectedInstallationIdentifier(githubAppInstallations[0].id.toString());
    }
  }, [ghLoading]);

  const [createProject] = useCreateProjectMutation();
  const { configureGitHubBuildTriggersForNewProjectAsync } =
    useConfigureGitHubBuildTriggersForNewProject();
  const [getAppCheck] = useAppExistsByFullNameLazyQuery();

  async function onSubmitAsync(event: FormEvent) {
    let { slug, displayName: _displayName } = getFormFieldData(event) as {
      slug: string;
      displayName: string;
    };

    slug = slug.trim();
    let displayName: string | undefined = _displayName.trim();

    setFormError(undefined);
    setSlugError(undefined);

    if (slug.length === 0) {
      setSlugError('You must provide a slug for your project.');
      return null;
    } else if (!IS_VALID_SLUG_REGEX.test(slug)) {
      setSlugError('Your can slug only contain letters, numbers and hypens (-).');
      return null;
    }

    // note(tchayen): Use the API server's default display name behavior if no name is specified during creation
    if (displayName.length === 0) {
      displayName = undefined;
    }

    setFormState(FormStates.LOADING);
    let newProjectName = slug;

    try {
      // get app by full name to check if it already exists
      const { data: existingAppData } = await getAppCheck({
        variables: {
          fullName: `@${accountName}/${newProjectName}`,
        },
        errorPolicy: 'ignore', // ignore errors, we don't want to stop the user from creating a project if the project name is already taken
      });

      // if the app name already exists, append a nanoid to the project name to make it unique
      if (existingAppData?.app.byFullName.id) {
        newProjectName = generateSlug(`${newProjectName}-${nanoid()}`);
        setGeneratedSlug(newProjectName);
      }
    } catch (error) {
      console.error(error);
    }

    try {
      const { data } = await createProject({
        variables: {
          appInput: {
            accountId,
            projectName: newProjectName,
            privacy: AppPrivacy.Unlisted,
            appInfo: { displayName },
            installationIdentifier: shouldCreateRepository
              ? selectedInstallationIdentifier
              : undefined,
          },
        },
      });

      const { app } = data?.app?.createAppAndGithubRepository ?? {};

      if (!app) {
        throw new Error('Failed to create app');
      }

      // only configure GitHub build triggers if the repository was created
      if (app.githubRepository?.id) {
        await configureGitHubBuildTriggersForNewProjectAsync({
          fullName: `@${accountName}/${newProjectName}`,
          appId: app.id,
        });
      }

      setFormState(FormStates.SUCCESS);

      return data;
    } catch (error) {
      const message = formatProjectCreationErrorMessage(error as Error);
      console.warn(error);

      setFormError(message);
      setFormState(FormStates.IDLE);
    }

    return null;
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const slug = generateSlug(event.target.value);
    setGeneratedSlug(slug);
  }

  return {
    formState,
    setFormState,
    formError,
    setFormError,
    slugError,
    generatedSlug,
    github: {
      repositoryName: generatedSlug,
      githubAppInstallations,
      shouldCreateRepository,
      selectedInstallationIdentifier,
      setSelectedInstallationIdentifier,
      setCreateRepository,
      openGitHubPopup,
      canLinkInstallation: canLinkInstallation ?? false,
      loading: ghLoading,
    },
    onSubmit: onSubmitAsync,
    onChange,
  };
}

export function formatProjectCreationErrorMessage(error: Error) {
  const message = error.message;

  if (
    message.includes('duplicate key value violates unique constraint "apps_unique_full_name_idx"')
  ) {
    return 'This slug is already in use for another project on this account.';
  }

  if (message.includes('Could not clone: Name already exists on this account')) {
    return 'This repository name is already in use on your GitHub account.';
  }

  return message;
}
