import nullthrows from 'nullthrows';

import { FeatureGateKey } from '~/common/gating/FeatureGateKey';

export const FEATURE_GATE_ENABLE_PARAM = 'fg_enable';
export const FEATURE_GATE_DISABLE_PARAM = 'fg_disable';
export const FEATURE_GATE_ITEM_KEY = 'fg';
export const FEATURE_GATE_DEFAULT_STORAGE = JSON.stringify({ fg_enable: [], fg_disable: [] });

export type FeatureGateQueryParams = {
  [FEATURE_GATE_ENABLE_PARAM]: string[];
  [FEATURE_GATE_DISABLE_PARAM]: string[];
};

export default class FeatureGateOverrides {
  private readonly map = new Map<string, boolean>();

  constructor(parsedUrlQuery: FeatureGateQueryParams) {
    const overrideEnableGateKeys = new Set(parsedUrlQuery[FEATURE_GATE_ENABLE_PARAM]);
    const overrideDisableGateKeys = new Set(parsedUrlQuery[FEATURE_GATE_DISABLE_PARAM]);

    for (const overrideEnableKey of overrideEnableGateKeys) {
      if (overrideDisableGateKeys.has(overrideEnableKey)) {
        continue;
      }
      this.map.set(overrideEnableKey, true);
    }
    for (const overrideDisableGateKey of overrideDisableGateKeys) {
      this.map.set(overrideDisableGateKey, false);
    }
  }

  public isOverridden(key: FeatureGateKey): boolean {
    return this.map.has(key) ?? false;
  }

  public getOverride(key: FeatureGateKey): boolean {
    return nullthrows(this.map.get(key));
  }
}
