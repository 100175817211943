// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GitHubUserDataFragment = { __typename?: 'GitHubUser', id: string, githubUserIdentifier: string, metadata?: { __typename?: 'GitHubUserMetadata', login: string, name?: string | null, avatarUrl: string } | null };

export type GetGitHubUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGitHubUserQuery = { __typename?: 'RootQuery', meUserActor?: { __typename?: 'SSOUser', id: string, githubUser?: { __typename?: 'GitHubUser', id: string, githubUserIdentifier: string, metadata?: { __typename?: 'GitHubUserMetadata', login: string, name?: string | null, avatarUrl: string } | null } | null } | { __typename?: 'User', id: string, githubUser?: { __typename?: 'GitHubUser', id: string, githubUserIdentifier: string, metadata?: { __typename?: 'GitHubUserMetadata', login: string, name?: string | null, avatarUrl: string } | null } | null } | null };

export const GitHubUserDataFragmentDoc = gql`
    fragment GitHubUserData on GitHubUser {
  id
  githubUserIdentifier
  metadata {
    login
    name
    avatarUrl
  }
}
    `;
export const GetGitHubUserDocument = gql`
    query GetGitHubUser {
  meUserActor {
    id
    githubUser {
      ...GitHubUserData
    }
  }
}
    ${GitHubUserDataFragmentDoc}`;
export function useGetGitHubUserQuery(baseOptions?: Apollo.QueryHookOptions<GetGitHubUserQuery, GetGitHubUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitHubUserQuery, GetGitHubUserQueryVariables>(GetGitHubUserDocument, options);
      }
export function useGetGitHubUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitHubUserQuery, GetGitHubUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitHubUserQuery, GetGitHubUserQueryVariables>(GetGitHubUserDocument, options);
        }
export function useGetGitHubUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGitHubUserQuery, GetGitHubUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitHubUserQuery, GetGitHubUserQueryVariables>(GetGitHubUserDocument, options);
        }
export type GetGitHubUserQueryHookResult = ReturnType<typeof useGetGitHubUserQuery>;
export type GetGitHubUserLazyQueryHookResult = ReturnType<typeof useGetGitHubUserLazyQuery>;
export type GetGitHubUserSuspenseQueryHookResult = ReturnType<typeof useGetGitHubUserSuspenseQuery>;
export type GetGitHubUserQueryResult = Apollo.QueryResult<GetGitHubUserQuery, GetGitHubUserQueryVariables>;
export function refetchGetGitHubUserQuery(variables?: GetGitHubUserQueryVariables) {
      return { query: GetGitHubUserDocument, variables: variables }
    }