// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { AppDataFragmentDoc } from '../fragments/AppData.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppByFullNameQueryVariables = Types.Exact<{
  fullName: Types.Scalars['String']['input'];
}>;


export type AppByFullNameQuery = { __typename?: 'RootQuery', app: { __typename?: 'AppQuery', byFullName: { __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string }, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null } | null } } };


export const AppByFullNameDocument = gql`
    query AppByFullName($fullName: String!) {
  app {
    byFullName(fullName: $fullName) {
      ...AppData
    }
  }
}
    ${AppDataFragmentDoc}`;
export function useAppByFullNameQuery(baseOptions: Apollo.QueryHookOptions<AppByFullNameQuery, AppByFullNameQueryVariables> & ({ variables: AppByFullNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppByFullNameQuery, AppByFullNameQueryVariables>(AppByFullNameDocument, options);
      }
export function useAppByFullNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppByFullNameQuery, AppByFullNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppByFullNameQuery, AppByFullNameQueryVariables>(AppByFullNameDocument, options);
        }
export function useAppByFullNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppByFullNameQuery, AppByFullNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppByFullNameQuery, AppByFullNameQueryVariables>(AppByFullNameDocument, options);
        }
export type AppByFullNameQueryHookResult = ReturnType<typeof useAppByFullNameQuery>;
export type AppByFullNameLazyQueryHookResult = ReturnType<typeof useAppByFullNameLazyQuery>;
export type AppByFullNameSuspenseQueryHookResult = ReturnType<typeof useAppByFullNameSuspenseQuery>;
export type AppByFullNameQueryResult = Apollo.QueryResult<AppByFullNameQuery, AppByFullNameQueryVariables>;
export function refetchAppByFullNameQuery(variables: AppByFullNameQueryVariables) {
      return { query: AppByFullNameDocument, variables: variables }
    }