// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
import { UserAccountsDataFragmentDoc } from '../../../graphql/fragments/UserAccountsData.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserAccountsAndProjectsQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;


export type GetUserAccountsAndProjectsQuery = { __typename?: 'RootQuery', meUserActor?: { __typename?: 'SSOUser', username: string, id: string, profilePhoto: string, firstName?: string | null, lastName?: string | null, accounts: Array<{ __typename?: 'Account', id: string, name: string, ownerUserActor?: { __typename?: 'SSOUser', id: string, profilePhoto: string, fullName?: string | null } | { __typename?: 'User', id: string, profilePhoto: string, fullName?: string | null } | null }> } | { __typename?: 'User', username: string, id: string, profilePhoto: string, firstName?: string | null, lastName?: string | null, accounts: Array<{ __typename?: 'Account', id: string, name: string, ownerUserActor?: { __typename?: 'SSOUser', id: string, profilePhoto: string, fullName?: string | null } | { __typename?: 'User', id: string, profilePhoto: string, fullName?: string | null } | null }> } | null, account: { __typename?: 'AccountQuery', byName: { __typename?: 'Account', id: string, appsPaginated: { __typename?: 'AccountAppsConnection', edges: Array<{ __typename?: 'AccountAppsEdge', node: { __typename?: 'App', id: string } }> } } } };


export const GetUserAccountsAndProjectsDocument = gql`
    query GetUserAccountsAndProjects($username: String!) {
  meUserActor {
    ...UserAccountsData
  }
  account {
    byName(accountName: $username) {
      id
      appsPaginated(first: 100) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    ${UserAccountsDataFragmentDoc}`;
export function useGetUserAccountsAndProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetUserAccountsAndProjectsQuery, GetUserAccountsAndProjectsQueryVariables> & ({ variables: GetUserAccountsAndProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAccountsAndProjectsQuery, GetUserAccountsAndProjectsQueryVariables>(GetUserAccountsAndProjectsDocument, options);
      }
export function useGetUserAccountsAndProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAccountsAndProjectsQuery, GetUserAccountsAndProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAccountsAndProjectsQuery, GetUserAccountsAndProjectsQueryVariables>(GetUserAccountsAndProjectsDocument, options);
        }
export function useGetUserAccountsAndProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserAccountsAndProjectsQuery, GetUserAccountsAndProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserAccountsAndProjectsQuery, GetUserAccountsAndProjectsQueryVariables>(GetUserAccountsAndProjectsDocument, options);
        }
export type GetUserAccountsAndProjectsQueryHookResult = ReturnType<typeof useGetUserAccountsAndProjectsQuery>;
export type GetUserAccountsAndProjectsLazyQueryHookResult = ReturnType<typeof useGetUserAccountsAndProjectsLazyQuery>;
export type GetUserAccountsAndProjectsSuspenseQueryHookResult = ReturnType<typeof useGetUserAccountsAndProjectsSuspenseQuery>;
export type GetUserAccountsAndProjectsQueryResult = Apollo.QueryResult<GetUserAccountsAndProjectsQuery, GetUserAccountsAndProjectsQueryVariables>;
export function refetchGetUserAccountsAndProjectsQuery(variables: GetUserAccountsAndProjectsQueryVariables) {
      return { query: GetUserAccountsAndProjectsDocument, variables: variables }
    }