import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';
import { Tier } from '~/common/gating/Tier';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';
import { defaultFullyRolledOutProductionAccounts } from '../defaultFullyRolledOutAccounts';

export class WorkflowsFeatureGate extends FeatureGate {
  protected getFullyRolledOutTiers(): ReadonlySet<Tier> {
    return new Set([Tier.DEVELOPMENT, Tier.STAGING]);
  }

  protected getFullyRolledOutAccounts(): ReadonlySet<string> {
    return defaultFullyRolledOutProductionAccounts;
  }

  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.WORKFLOWS;
  }
}

export function useWorkflowsFeatureGate() {
  const context = useContext(FeatureGateContext);
  return context?.[FeatureGateKey.WORKFLOWS] ?? defaultGate;
}
