import { mergeClasses } from '@expo/styleguide';
import type { HTMLAttributes } from 'react';

type Props = Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className'> & {
  withDivider?: boolean;
};

export function DialogContentContainer({ children, className }: Props) {
  return (
    <div
      className={mergeClasses(
        'flex max-h-[calc(90vh-69px-53px)] flex-col gap-2.5 overflow-y-auto p-6 pt-4 outline-0',
        className
      )}>
      {children}
    </div>
  );
}
