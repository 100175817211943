import { DiscordIcon } from '@expo/styleguide-icons/custom/DiscordIcon';
import { XLogoIcon } from '@expo/styleguide-icons/custom/XLogoIcon';
import { Mail02DuotoneIcon } from '@expo/styleguide-icons/duotone/Mail02DuotoneIcon';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';

import { accountsHaveAccessToPrioritySubscription } from '~/common/helpers';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { Button } from '~/ui/components/Button';

import { PopoverHeaderButtonGroup } from './PopoverHeaderButtonGroup';
import { FeedbackPopoverPage } from './shared';
import { Tag } from '../Tag';

type Props = {
  onDismiss: () => void;
  onSetPage: (pageNum: FeedbackPopoverPage) => void;
  hasBackOption?: boolean;
  currentUser: LoggedInProps['currentUser'];
};

export function PopoverFeedbackConnect({
  onDismiss,
  onSetPage,
  hasBackOption,
  currentUser,
}: Props) {
  const userIsSubscribed = accountsHaveAccessToPrioritySubscription(currentUser?.accounts);

  return (
    <div className="flex flex-1 flex-col">
      <PopoverHeaderButtonGroup
        hasBackOption={hasBackOption}
        previousPage={FeedbackPopoverPage.OPTIONS}
        onDismiss={onDismiss}
        onSetPage={onSetPage}
        title="Get in touch"
      />
      <div className="flex flex-col gap-2 px-4 py-3">
        {userIsSubscribed && (
          <Button
            theme="secondary"
            size="xl"
            className="px-4 py-7"
            leftSlot={<Mail02DuotoneIcon className="icon-lg text-icon-default" />}
            rightSlot={
              <Tag
                small
                title="Priority Support"
                theme="blue"
                className="ml-auto border border-palette-blue7"
              />
            }
            href="/contact">
            Contact us
          </Button>
        )}
        <Button
          theme="secondary"
          size="xl"
          className="px-4 py-7"
          leftSlot={<DiscordIcon className="icon-lg text-icon-default" />}
          href="https://chat.expo.dev"
          openInNewTab
          rightSlot={<ArrowUpRightIcon className="icon-md ml-auto text-icon-secondary" />}>
          Join the Community Discord
        </Button>
        <Button
          theme="secondary"
          size="xl"
          className="px-4 py-7"
          leftSlot={<XLogoIcon className="icon-lg text-icon-default" />}
          href="https://x.com/expo"
          openInNewTab
          rightSlot={<ArrowUpRightIcon className="icon-md ml-auto text-icon-secondary" />}>
          Follow us on X
        </Button>
      </div>
    </div>
  );
}
