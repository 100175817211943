import { createContext, useContext, PropsWithChildren } from 'react';

import { useLocalStorage } from '~/providers/useLocalStorage';

export const LogsDisplayOptionsContext = createContext({
  showLineNumbers: true,
  showTimestamp: false,
  showOutputStreamLabel: false,
  wrapLines: true,
  showBuildAnnotations: true,
  setLineNumberShown: (_: boolean) => {},
  setShowOutputStreamLabel: (_: boolean) => {},
  setTimestampShown: (_: boolean) => {},
  setWrapLines: (_: boolean) => {},
  setShowBuildAnnotations: (_: boolean) => {},
});

export function LogsDisplayOptionsProvider({ children }: PropsWithChildren) {
  const [showLineNumbers, setLineNumberShown] = useLocalStorage({
    name: 'logs.showLineNumbers',
    defaultValue: true,
  });
  const [showTimestamp, setTimestampShown] = useLocalStorage({
    name: 'logs.showTimestamp',
    defaultValue: false,
  });
  const [showOutputStreamLabel, setShowOutputStreamLabel] = useLocalStorage({
    name: 'logs.showOutputStreamLabel',
    defaultValue: false,
  });
  const [wrapLines, setWrapLines] = useLocalStorage({ name: 'logs.wrapLines', defaultValue: true });
  const [showBuildAnnotations, setShowBuildAnnotations] = useLocalStorage({
    name: 'logs.showBuildAnnotations',
    defaultValue: true,
  });

  return (
    <LogsDisplayOptionsContext.Provider
      value={{
        showLineNumbers,
        setLineNumberShown,
        showTimestamp,
        setTimestampShown,
        wrapLines,
        setWrapLines,
        showOutputStreamLabel,
        setShowOutputStreamLabel,
        showBuildAnnotations,
        setShowBuildAnnotations,
      }}>
      {children}
    </LogsDisplayOptionsContext.Provider>
  );
}

export function useLogsDisplayOptionsContext() {
  return useContext(LogsDisplayOptionsContext);
}
