// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
export type GitHubJobRunTriggerDataFragment = { __typename?: 'GitHubJobRunTrigger', id: string, isActive: boolean, triggerType: Types.GitHubJobRunTriggerType, jobType?: Types.GitHubJobRunJobType | null, createdAt: any, lastRunAt?: any | null, sourcePattern: string, targetPattern?: string | null, lastRunStatus?: Types.GitHubJobRunTriggerRunStatus | null, lastRunErrorCode?: string | null, lastRunErrorMessage?: string | null };

export const GitHubJobRunTriggerDataFragmentDoc = gql`
    fragment GitHubJobRunTriggerData on GitHubJobRunTrigger {
  id
  isActive
  triggerType
  jobType
  createdAt
  lastRunAt
  sourcePattern
  targetPattern
  lastRunStatus
  lastRunErrorCode
  lastRunErrorMessage
}
    `;