import { mergeClasses } from '@expo/styleguide';
import { PropsWithChildren, ReactNode } from 'react';

import { CALLOUT } from '~/ui/components/text';

type FormErrorProps = PropsWithChildren<{
  error?: ReactNode;
  className?: string;
  errorClassName?: string;
}>;

export function FormError({ error, children, className, errorClassName }: FormErrorProps) {
  return error || children ? (
    <div className={mergeClasses('relative flex flex-1 flex-col-reverse', className)}>
      {error && (
        <div
          className={mergeClasses(
            'relative -top-1 flex flex-col whitespace-pre-wrap rounded-b-md bg-danger p-4',
            !children && 'top-0 mb-2 rounded-md',
            errorClassName
          )}
          data-testid="form-error">
          {typeof error === 'string' ? <CALLOUT theme="danger">{error}</CALLOUT> : error}
        </div>
      )}
      {children}
    </div>
  ) : null;
}
