// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppExistsByFullNameQueryVariables = Types.Exact<{
  fullName: Types.Scalars['String']['input'];
}>;


export type AppExistsByFullNameQuery = { __typename?: 'RootQuery', app: { __typename?: 'AppQuery', byFullName: { __typename?: 'App', id: string } } };


export const AppExistsByFullNameDocument = gql`
    query AppExistsByFullName($fullName: String!) {
  app {
    byFullName(fullName: $fullName) {
      id
    }
  }
}
    `;
export function useAppExistsByFullNameQuery(baseOptions: Apollo.QueryHookOptions<AppExistsByFullNameQuery, AppExistsByFullNameQueryVariables> & ({ variables: AppExistsByFullNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppExistsByFullNameQuery, AppExistsByFullNameQueryVariables>(AppExistsByFullNameDocument, options);
      }
export function useAppExistsByFullNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppExistsByFullNameQuery, AppExistsByFullNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppExistsByFullNameQuery, AppExistsByFullNameQueryVariables>(AppExistsByFullNameDocument, options);
        }
export function useAppExistsByFullNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppExistsByFullNameQuery, AppExistsByFullNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppExistsByFullNameQuery, AppExistsByFullNameQueryVariables>(AppExistsByFullNameDocument, options);
        }
export type AppExistsByFullNameQueryHookResult = ReturnType<typeof useAppExistsByFullNameQuery>;
export type AppExistsByFullNameLazyQueryHookResult = ReturnType<typeof useAppExistsByFullNameLazyQuery>;
export type AppExistsByFullNameSuspenseQueryHookResult = ReturnType<typeof useAppExistsByFullNameSuspenseQuery>;
export type AppExistsByFullNameQueryResult = Apollo.QueryResult<AppExistsByFullNameQuery, AppExistsByFullNameQueryVariables>;
export function refetchAppExistsByFullNameQuery(variables: AppExistsByFullNameQueryVariables) {
      return { query: AppExistsByFullNameDocument, variables: variables }
    }