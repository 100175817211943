// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
import { GitHubUserDataFragmentDoc } from '../../Settings/SettingsIndexScene/PersonalSettings/Connections/queries/GetGitHubUser.query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGitHubAppInstallationsQueryVariables = Types.Exact<{
  accountName: Types.Scalars['String']['input'];
}>;


export type GetGitHubAppInstallationsQuery = { __typename?: 'RootQuery', account: { __typename?: 'AccountQuery', byName: { __typename?: 'Account', githubAppInstallations: Array<{ __typename?: 'GitHubAppInstallation', id: string, installationIdentifier: number, account: { __typename?: 'Account', id: string, name: string }, metadata: { __typename?: 'GitHubAppInstallationMetadata', githubAccountAvatarUrl?: string | null, githubAccountName?: string | null, installationStatus: Types.GitHubAppInstallationStatus } }> } }, meUserActor?: { __typename?: 'SSOUser', id: string, githubUser?: { __typename?: 'GitHubUser', id: string, githubUserIdentifier: string, metadata?: { __typename?: 'GitHubUserMetadata', login: string, name?: string | null, avatarUrl: string } | null } | null } | { __typename?: 'User', id: string, githubUser?: { __typename?: 'GitHubUser', id: string, githubUserIdentifier: string, metadata?: { __typename?: 'GitHubUserMetadata', login: string, name?: string | null, avatarUrl: string } | null } | null } | null };

export type GitHubAppInstallationDataFragment = { __typename?: 'GitHubAppInstallation', id: string, installationIdentifier: number, account: { __typename?: 'Account', id: string, name: string }, metadata: { __typename?: 'GitHubAppInstallationMetadata', githubAccountAvatarUrl?: string | null, githubAccountName?: string | null, installationStatus: Types.GitHubAppInstallationStatus } };

export const GitHubAppInstallationDataFragmentDoc = gql`
    fragment GitHubAppInstallationData on GitHubAppInstallation {
  id
  installationIdentifier
  account {
    id
    name
  }
  metadata {
    githubAccountAvatarUrl
    githubAccountName
    installationStatus
  }
}
    `;
export const GetGitHubAppInstallationsDocument = gql`
    query GetGitHubAppInstallations($accountName: String!) {
  account {
    byName(accountName: $accountName) {
      githubAppInstallations {
        ...GitHubAppInstallationData
      }
    }
  }
  meUserActor {
    id
    githubUser {
      ...GitHubUserData
    }
  }
}
    ${GitHubAppInstallationDataFragmentDoc}
${GitHubUserDataFragmentDoc}`;
export function useGetGitHubAppInstallationsQuery(baseOptions: Apollo.QueryHookOptions<GetGitHubAppInstallationsQuery, GetGitHubAppInstallationsQueryVariables> & ({ variables: GetGitHubAppInstallationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitHubAppInstallationsQuery, GetGitHubAppInstallationsQueryVariables>(GetGitHubAppInstallationsDocument, options);
      }
export function useGetGitHubAppInstallationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitHubAppInstallationsQuery, GetGitHubAppInstallationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitHubAppInstallationsQuery, GetGitHubAppInstallationsQueryVariables>(GetGitHubAppInstallationsDocument, options);
        }
export function useGetGitHubAppInstallationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGitHubAppInstallationsQuery, GetGitHubAppInstallationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitHubAppInstallationsQuery, GetGitHubAppInstallationsQueryVariables>(GetGitHubAppInstallationsDocument, options);
        }
export type GetGitHubAppInstallationsQueryHookResult = ReturnType<typeof useGetGitHubAppInstallationsQuery>;
export type GetGitHubAppInstallationsLazyQueryHookResult = ReturnType<typeof useGetGitHubAppInstallationsLazyQuery>;
export type GetGitHubAppInstallationsSuspenseQueryHookResult = ReturnType<typeof useGetGitHubAppInstallationsSuspenseQuery>;
export type GetGitHubAppInstallationsQueryResult = Apollo.QueryResult<GetGitHubAppInstallationsQuery, GetGitHubAppInstallationsQueryVariables>;
export function refetchGetGitHubAppInstallationsQuery(variables: GetGitHubAppInstallationsQueryVariables) {
      return { query: GetGitHubAppInstallationsDocument, variables: variables }
    }