import { mergeClasses } from '@expo/styleguide';
import { EyeOffIcon } from '@expo/styleguide-icons/outline/EyeOffIcon';
import isEmpty from 'lodash/isEmpty';
import type { PropsWithChildren } from 'react';

import { Figure } from '~/ui/components/Figure';
import { BOLD } from '~/ui/components/text';

import { PinWithOutline } from './PinWithOutline';

type ProjectIconProps = PropsWithChildren<{
  name: string;
  /**
   * Icons are property of classic updates projects.
   */
  iconUrl?: string | null;
  iconSize?: number;
  className?: string;
  isPinned?: boolean;
  isPrivate?: boolean;
}>;

export function ProjectIcon({
  name,
  iconUrl,
  className,
  iconSize = 32,
  isPinned,
  isPrivate,
}: ProjectIconProps) {
  const backgroundColor = getProjectBackgroundColor(name);
  const opacity =
    backgroundColor.includes('yellow') || backgroundColor.includes('lime') ? '0.25' : '0.15';

  if (iconUrl && !isEmpty(iconUrl)) {
    return (
      <span className="relative inline-flex shrink-0" style={{ height: iconSize }} role="figure">
        <span
          className={mergeClasses(
            'relative inline-flex overflow-hidden bg-hover',
            getRoundness(iconSize),
            className
          )}>
          <Figure width={iconSize} height={iconSize} src={iconUrl} alt="Project icon" />
        </span>
        {isPinned && <PinWithOutline />}
      </span>
    );
  }

  const dynamicIconSize = `min-h-[${iconSize}px] min-w-[${iconSize}px]`;

  return (
    <span className="relative inline-flex shrink-0" style={{ height: iconSize }} role="figure">
      <span
        className={mergeClasses(
          'dark-theme relative flex items-center justify-center',
          dynamicIconSize,
          getRoundness(iconSize),
          backgroundColor,
          className
        )}>
        <BOLD
          className="select-none leading-none text-palette-white drop-shadow-sm"
          style={{ fontSize: iconSize * 0.5 }}>
          {name[0]?.toUpperCase()}
        </BOLD>
        {isPrivate && (
          <EyeOffIcon
            className="absolute bottom-1 left-1 text-palette-white opacity-60"
            title="Private"
          />
        )}
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute inset-0">
          <path
            id="236964db"
            opacity={opacity}
            d="M16.0001 0V31.9999"
            stroke="white"
            strokeWidth="0.5"
          />
          <path
            id="f2d3e9b4"
            opacity={opacity}
            d="M8.29639 0V31.9999"
            stroke="white"
            strokeWidth="0.5"
          />
          <path
            id="959c405b"
            opacity={opacity}
            d="M23.7035 0V31.9999"
            stroke="white"
            strokeWidth="0.5"
          />
          <path
            id="c2b76433"
            opacity={opacity}
            d="M31.9999 16.0001L-6.4373e-06 16.0001"
            stroke="white"
            strokeWidth="0.5"
          />
          <path
            id="155bd64e"
            opacity={opacity}
            d="M31.9999 8.29642L-6.4373e-06 8.29642"
            stroke="white"
            strokeWidth="0.5"
          />
          <path
            id="8821303d"
            opacity={opacity}
            d="M31.9999 23.7035L-6.4373e-06 23.7035"
            stroke="white"
            strokeWidth="0.5"
          />
        </svg>
      </span>
      {isPinned && <PinWithOutline />}
    </span>
  );
}

const COLORS = [
  'bg-app-cyan',
  'bg-app-light-blue',
  'bg-app-dark-blue',
  'bg-app-indigo',
  'bg-app-purple',
  'bg-app-pink',
  'bg-app-orange',
  'bg-app-gold',
  'bg-app-yellow',
  'bg-app-lime',
  'bg-app-light-green',
  'bg-app-dark-green',
] as const;

function hashNameToColorIndex(name: string) {
  return Array.from(name).reduce((acc, val) => (acc * 31 + val.charCodeAt(0)) % COLORS.length, 0);
}

function getProjectBackgroundColor(name: string) {
  return COLORS[hashNameToColorIndex(name)];
}

function getRoundness(size: number) {
  if (size >= 64) {
    return 'rounded-lg';
  } else if (size < 32) {
    return 'rounded-sm';
  } else {
    return 'rounded-md';
  }
}
