import { getPlanFromPlanId, getPlanFromName, PlanName } from '~/common/plans';
import { useUserAccountQuery } from '~/graphql/queries/UserAccountQuery.query.generated';

export function useAccountIsPlan(accountName: string, planName: PlanName) {
  const { data } = useUserAccountQuery({
    variables: {
      accountName,
    },
  });

  const account = data?.account.byName;

  const currentSubscription = account?.subscription;

  const currentPlan = currentSubscription?.planId
    ? getPlanFromPlanId(currentSubscription.planId)
    : getPlanFromName(currentSubscription?.name);

  return currentPlan.title === planName;
}
