import { useRouter } from 'next/compat/router';

import { formatParams } from '~/common/formatParams';
import { categorizeAccounts } from '~/common/helpers';
import { LoggedInProps } from '~/scenes/_app/helpers';

export function useSelectedAccount(
  currentUser: LoggedInProps['currentUser'],
  accounts: LoggedInProps['currentUser']['accounts']
) {
  const router = useRouter();
  const { account: accountNameFromUrl } = formatParams(router?.query);

  if (!currentUser) {
    return {
      accountNameFromUrl: undefined,
      organizationAccounts: [],
      personalAccount: undefined,
      personalAccountOrSuperuser: undefined,
      selectedAccount: undefined,
      selectedAccountName: undefined,
      selectedAccountIcon: undefined,
      userAccounts: [],
    };
  }

  const { personalAccount, userAccounts, organizationAccounts } = categorizeAccounts(
    currentUser,
    accounts
  );

  const superuserOwnerAccount = currentUser.isExpoAdmin
    ? currentUser.accounts?.find((account) => account?.ownerUserActor?.id === currentUser.id)
    : null;

  const allAccounts = [
    personalAccount ?? superuserOwnerAccount,
    ...userAccounts,
    ...organizationAccounts,
  ];

  const selectedAccount = allAccounts.find((account) => {
    if (accountNameFromUrl) {
      return account?.name === accountNameFromUrl;
    }
    return account?.name === currentUser.username;
  });

  const personalAccountOrSuperuser = personalAccount ?? superuserOwnerAccount;
  const selectedAccountIcon = selectedAccount?.ownerUserActor?.profilePhoto;

  const selectedAccountName = currentUser.isExpoAdmin
    ? (selectedAccount?.ownerUserActor?.fullName ?? selectedAccount?.name ?? accountNameFromUrl)
    : (selectedAccount?.ownerUserActor?.fullName ?? selectedAccount?.name);

  return {
    accountNameFromUrl,
    organizationAccounts,
    personalAccount,
    personalAccountOrSuperuser,
    selectedAccount,
    selectedAccountName,
    selectedAccountIcon,
    userAccounts,
  };
}
