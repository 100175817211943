import { mergeClasses } from '@expo/styleguide';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { useEffect, useState } from 'react';

import { externalApis } from '~/common/constants';
import { Button } from '~/ui/components/Button';

type ServiceStatus = 'minor' | 'major' | 'critical' | 'none' | 'unknown';
type Status = { indicator: ServiceStatus; description: string };

const DEFAULT_STATUS: Status = {
  indicator: 'none',
  description: 'All Systems Operational',
};

export function ServiceStatusIndicator() {
  const [status, setStatus] = useState(DEFAULT_STATUS);

  useEffect(function didMount() {
    const fetchDataAsync = async () => {
      try {
        const response = await fetch(externalApis.statusPage);
        const { status } = await response.json();
        setStatus(status);
      } catch {
        setStatus({ indicator: 'unknown', description: "Can't fetch status" });
      }
    };

    void fetchDataAsync();
  }, []);

  return (
    <Button
      openInNewTab
      href="https://status.expo.dev"
      aria-label={status.description}
      theme="quaternary"
      rightSlot={<ArrowUpRightIcon />}
      leftSlot={
        <span
          className={mergeClasses('mr-1 size-2 rounded-full', getStatusClasses(status.indicator))}
        />
      }>
      {status.description}
    </Button>
  );
}

function getStatusClasses(status: ServiceStatus) {
  switch (status) {
    case 'critical':
    case 'major':
      return 'bg-palette-red10';
    case 'minor':
      return 'bg-palette-yellow11';
    case 'none':
      return 'bg-palette-green10';
    case 'unknown':
    default:
      return 'bg-palette-gray10';
  }
}
