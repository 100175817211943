import { Button, ButtonProps } from '~/ui/components/Button';

export function FormButton({ children, size = 'lg', ...rest }: ButtonProps) {
  return (
    <div className="relative mt-2 flex">
      <Button {...rest} type="submit" size={size}>
        {children}
      </Button>
    </div>
  );
}
