import { useState, useEffect } from 'react';

import { LoggedInProps } from '~/scenes/_app/helpers';

import { PopoverFeedbackConnect } from './PopoverFeedbackConnect';
import { PopoverFeedbackForm } from './PopoverFeedbackForm';
import { PopoverFeedbackIssue } from './PopoverFeedbackIssue';
import { PopoverFeedbackOptions } from './PopoverFeedbackOptions';
import { PopoverFeedbackSuccess } from './PopoverFeedbackSuccess';
import { FeedbackPopoverPage } from './shared';

type Props = {
  isVisible: boolean;
  onDismiss: () => void;
  currentUser: LoggedInProps['currentUser'];
};

export function PopoverFeedbackContents({ isVisible, onDismiss, currentUser }: Props) {
  const [page, setPage] = useState(FeedbackPopoverPage.OPTIONS);

  useEffect(
    function isVisibleDidUpdate() {
      if (isVisible) {
        setPage(FeedbackPopoverPage.OPTIONS);
      }
    },
    [isVisible]
  );

  return (
    <div className="bottom-14 right-0 flex min-h-[288px] bg-overlay">
      {page === FeedbackPopoverPage.OPTIONS && (
        <PopoverFeedbackOptions onSetPage={setPage} onDismiss={onDismiss} />
      )}
      {page === FeedbackPopoverPage.ISSUE && (
        <PopoverFeedbackIssue hasBackOption onDismiss={onDismiss} onSetPage={setPage} />
      )}
      {page === FeedbackPopoverPage.FEEDBACK && (
        <PopoverFeedbackForm
          hasBackOption
          page={page}
          onDismiss={onDismiss}
          currentUser={currentUser}
          onSetPage={setPage}
        />
      )}
      {page === FeedbackPopoverPage.CONNECT && (
        <PopoverFeedbackConnect
          hasBackOption
          onDismiss={onDismiss}
          onSetPage={setPage}
          currentUser={currentUser}
        />
      )}
      {page === FeedbackPopoverPage.SUCCESS && <PopoverFeedbackSuccess onDismiss={onDismiss} />}
    </div>
  );
}
