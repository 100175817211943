import { AccountsDataFragment } from '~/graphql/types.generated';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { Avatar } from '~/ui/components/Avatar';
import { useSelectedAccount } from '~/ui/components/SidebarNavigation/components/useSelectedAccount';

type UserAvatarProps = {
  currentUser: LoggedInProps['currentUser'];
  accounts: AccountsDataFragment[];
};

export function UserAvatar({ currentUser, accounts }: UserAvatarProps) {
  const { selectedAccount, selectedAccountIcon } = useSelectedAccount(currentUser, accounts);
  return (
    <Avatar
      profilePhoto={selectedAccountIcon}
      name={selectedAccount?.ownerUserActor?.fullName ?? selectedAccount?.name}
      isOrganization={selectedAccount?.ownerUserActor === null}
    />
  );
}
