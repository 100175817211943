import { useState, useEffect } from 'react';

import { isLocalStorageAvailable } from '~/common/sentry-utilities';

type Args<T> = {
  name: string;
  defaultValue: T;
};

export function useLocalStorage<T>(args: Args<T>): [T, (arg: T) => void, () => void] {
  const { defaultValue, name } = args;
  const [value, setValue] = useState<T | undefined>(undefined);
  const persistenceKey = `@expo/useLocalStorage/${name}`;

  useEffect(function didMount() {
    if (isLocalStorageAvailable()) {
      const persistedState = localStorage.getItem(persistenceKey);

      let parsedState;
      try {
        parsedState = persistedState ? JSON.parse(persistedState) : undefined;
      } catch (error) {
        console.error(error);
      }

      setValue(parsedState ?? defaultValue);
    }
  }, []);

  useEffect(
    function persistOnChange() {
      if (isLocalStorageAvailable() && value !== undefined) {
        localStorage.setItem(persistenceKey, JSON.stringify(value));
      }
    },
    [value]
  );

  function removeValue() {
    if (isLocalStorageAvailable()) {
      localStorage.removeItem(persistenceKey);
    }
  }

  return [value ?? defaultValue, setValue, removeValue];
}
