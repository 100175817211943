// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGitHubAppCredentialsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGitHubAppCredentialsQuery = { __typename?: 'RootQuery', githubApp: { __typename?: 'GitHubAppQuery', appIdentifier: string, clientIdentifier: string, environment: Types.GitHubAppEnvironment, name: string } };


export const GetGitHubAppCredentialsDocument = gql`
    query GetGitHubAppCredentials {
  githubApp {
    appIdentifier
    clientIdentifier
    environment
    name
  }
}
    `;
export function useGetGitHubAppCredentialsQuery(baseOptions?: Apollo.QueryHookOptions<GetGitHubAppCredentialsQuery, GetGitHubAppCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitHubAppCredentialsQuery, GetGitHubAppCredentialsQueryVariables>(GetGitHubAppCredentialsDocument, options);
      }
export function useGetGitHubAppCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitHubAppCredentialsQuery, GetGitHubAppCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitHubAppCredentialsQuery, GetGitHubAppCredentialsQueryVariables>(GetGitHubAppCredentialsDocument, options);
        }
export function useGetGitHubAppCredentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGitHubAppCredentialsQuery, GetGitHubAppCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitHubAppCredentialsQuery, GetGitHubAppCredentialsQueryVariables>(GetGitHubAppCredentialsDocument, options);
        }
export type GetGitHubAppCredentialsQueryHookResult = ReturnType<typeof useGetGitHubAppCredentialsQuery>;
export type GetGitHubAppCredentialsLazyQueryHookResult = ReturnType<typeof useGetGitHubAppCredentialsLazyQuery>;
export type GetGitHubAppCredentialsSuspenseQueryHookResult = ReturnType<typeof useGetGitHubAppCredentialsSuspenseQuery>;
export type GetGitHubAppCredentialsQueryResult = Apollo.QueryResult<GetGitHubAppCredentialsQuery, GetGitHubAppCredentialsQueryVariables>;
export function refetchGetGitHubAppCredentialsQuery(variables?: GetGitHubAppCredentialsQueryVariables) {
      return { query: GetGitHubAppCredentialsDocument, variables: variables }
    }