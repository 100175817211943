// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateGitHubUserAccessTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateGitHubUserAccessTokenMutation = { __typename?: 'RootMutation', githubUser: { __typename?: 'GitHubUserMutation', generateGitHubUserAccessToken?: string | null } };


export const GenerateGitHubUserAccessTokenDocument = gql`
    mutation GenerateGitHubUserAccessToken {
  githubUser {
    generateGitHubUserAccessToken
  }
}
    `;
export function useGenerateGitHubUserAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateGitHubUserAccessTokenMutation, GenerateGitHubUserAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateGitHubUserAccessTokenMutation, GenerateGitHubUserAccessTokenMutationVariables>(GenerateGitHubUserAccessTokenDocument, options);
      }
export type GenerateGitHubUserAccessTokenMutationHookResult = ReturnType<typeof useGenerateGitHubUserAccessTokenMutation>;
export type GenerateGitHubUserAccessTokenMutationResult = Apollo.MutationResult<GenerateGitHubUserAccessTokenMutation>;
export type GenerateGitHubUserAccessTokenMutationOptions = Apollo.BaseMutationOptions<GenerateGitHubUserAccessTokenMutation, GenerateGitHubUserAccessTokenMutationVariables>;