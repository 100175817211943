import { mergeClasses } from '@expo/styleguide';
import { HTMLAttributes } from 'react';

type Props = {
  disabled?: boolean;
  withDivider?: boolean;
  size?: 'regular' | 'header' | 'form' | 'list';
} & HTMLAttributes<HTMLDivElement>;

export function BoxContentContainer({
  className,
  children,
  disabled = false,
  withDivider = false,
  size = 'regular',
  ...rest
}: Props) {
  return (
    <div
      className={mergeClasses(
        disabled && 'pointer-events-none select-none opacity-50',
        withDivider && 'border-b border-b-secondary',
        size === 'regular' && 'px-6 py-5',
        size === 'header' && 'px-6 py-4',
        size === 'form' && 'p-6',
        size === 'list' && 'px-5 py-5',
        className
      )}
      {...rest}>
      {children}
    </div>
  );
}
