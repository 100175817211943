import { QuestionMarkIcon } from '@expo/styleguide-icons/custom/QuestionMarkIcon';
import { useState } from 'react';

import { useCurrentUserQuery } from '~/graphql/queries/CurrentUserQuery.query.generated';
import { Button } from '~/ui/components/Button';
import { Popover } from '~/ui/components/Popover';

import { PopoverFeedbackContents } from './PopoverFeedbackContents';

export const FEEDBACK_POPOVER_BUTTON_ID = 'feedback-popover-button';

export function PopoverFeedback() {
  const [isVisible, setIsVisible] = useState(false);
  const { data, error } = useCurrentUserQuery();
  const currentUser = data?.meUserActor;

  if (!currentUser || error) {
    return null;
  }

  function onToggle() {
    setIsVisible((prevIsVisible) => !prevIsVisible);
  }

  function onHide() {
    setIsVisible(false);
  }

  return (
    <Popover
      className="block min-w-[400px] max-md-gutters:hidden"
      sideOffset={12}
      trigger={
        <div className="fixed bottom-4 right-4 z-50 block select-none max-md-gutters:hidden">
          <Button
            id={FEEDBACK_POPOVER_BUTTON_ID}
            theme="secondary"
            className="size-12 !rounded-full border-secondary shadow-sm dark:bg-default hocus:dark:bg-element"
            onClick={onToggle}
            rightSlot={<QuestionMarkIcon className="icon-md dark:text-icon-default" />}
            aria-label="Share feedback"
          />
        </div>
      }>
      <div className="block max-md-gutters:hidden">
        <PopoverFeedbackContents
          isVisible={isVisible}
          onDismiss={onHide}
          currentUser={currentUser}
        />
      </div>
    </Popover>
  );
}
