import { useContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';
import FeatureGateOverrides from '~/common/gating/FeatureGateOverrides';

import { FeatureGateContext, defaultGate } from '../FeatureGateContext';

export class EnableNotificationFcmV1CredentialsFeatureGate extends FeatureGate {
  private readonly accountIds: string[];

  constructor(
    serverValue: boolean | undefined,
    featureGateURLOverrides: FeatureGateOverrides,
    { accountIds }: { accountIds: string[] }
  ) {
    super(serverValue, featureGateURLOverrides);
    this.accountIds = accountIds;
  }

  protected getAccounts(): string[] {
    return this.accountIds;
  }

  protected getExperimentKey(): FeatureGateKey {
    return FeatureGateKey.ENABLE_NOTIFICATION_FCM_V1_CREDENTIALS_FEATURE_GATE;
  }
}

export function useEnableNotificationFcmV1CredentialsFeatureGate() {
  const context = useContext(FeatureGateContext);
  return (
    context?.[FeatureGateKey.ENABLE_NOTIFICATION_FCM_V1_CREDENTIALS_FEATURE_GATE] ?? defaultGate
  );
}
