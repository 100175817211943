import { createContext } from 'react';

import FeatureGate from '~/common/gating/FeatureGate';

import { FeatureGateKey } from './FeatureGateKey';

type ContextType =
  | {
      [key in FeatureGateKey]?: FeatureGate;
    }
  | null;

export const FeatureGateContext = createContext<ContextType>(null);

FeatureGateContext.displayName = 'FeatureGateContext';

export const defaultGate = {
  isEnabled: () => false,
};
