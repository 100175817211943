import { LinkResolverScene } from '~/scenes/LinkResolverScene';
import { withAuthAndAccountRequired } from '~/scenes/_app/withAuthAndAccountRequired';
import Document from '~/ui/components/Document';

import { LoggedInProps } from './helpers';

type Props = LoggedInProps & {
  decodedPathname: string;
};

function LinkResolverPageInner({ currentUser, currentDateAsString, decodedPathname }: Props) {
  return (
    <Document meta={{ name: 'dashboard', pageName: 'Link resolver' }} className="bg-screen">
      <LinkResolverScene
        currentUser={currentUser}
        currentDateAsString={currentDateAsString}
        destinationPath={decodedPathname}
      />
    </Document>
  );
}

const LinkResolverPage = withAuthAndAccountRequired(LinkResolverPageInner);

export { LinkResolverPage };
