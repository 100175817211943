// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type AppDataShortFragment = { __typename: 'App', id: string, iconUrl?: string | null, name: string, slug: string, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null };

export const AppDataShortFragmentDoc = gql`
    fragment AppDataShort on App {
  __typename
  id
  icon {
    url
    primaryColor
  }
  iconUrl
  name
  slug
}
    `;