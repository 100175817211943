import { SnackLogo, Themes, useTheme } from '@expo/styleguide';
import { BadgeIcon } from '@expo/styleguide-icons/custom/BadgeIcon';
import { ThemeIcon } from '@expo/styleguide-icons/custom/ThemeIcon';
import { CheckIcon } from '@expo/styleguide-icons/outline/CheckIcon';
import { Flag01Icon } from '@expo/styleguide-icons/outline/Flag01Icon';
import { Home02Icon } from '@expo/styleguide-icons/outline/Home02Icon';
import { LifeBuoy01Icon } from '@expo/styleguide-icons/outline/LifeBuoy01Icon';
import { LogOut03Icon } from '@expo/styleguide-icons/outline/LogOut03Icon';
import { NotificationMessageIcon } from '@expo/styleguide-icons/outline/NotificationMessageIcon';
import { Settings01Icon } from '@expo/styleguide-icons/outline/Settings01Icon';
import { Contrast02SolidIcon } from '@expo/styleguide-icons/solid/Contrast02SolidIcon';
import { Moon01SolidIcon } from '@expo/styleguide-icons/solid/Moon01SolidIcon';
import { SunSolidIcon } from '@expo/styleguide-icons/solid/SunSolidIcon';

import { useLogout } from '~/ui/components/Navigation/LoggedInNavigation/useLogout';

import { DropdownItem } from '../../Dropdown/DropdownItem';
import { DropdownSubMenu } from '../../Dropdown/DropdownSubMenu';

type Props = {
  username: string;
  showUsername?: boolean;
  isExpoAdmin?: boolean;
};

export function UserMenuContent({ username, showUsername = false, isExpoAdmin = false }: Props) {
  const { themeName, setAutoMode, setDarkMode, setLightMode } = useTheme();

  const onLogout = useLogout();

  return (
    <>
      <DropdownItem label="Snacks" Icon={SnackLogo} href={`/accounts/${username}/snacks`} />
      <DropdownSubMenu label="Theme" Icon={ThemeIcon} className="min-w-[130px]">
        <DropdownItem
          label="Auto"
          Icon={Contrast02SolidIcon}
          onSelect={setAutoMode}
          rightSlot={themeName === Themes.AUTO && <CheckIcon />}
        />
        <DropdownItem
          label="Light"
          Icon={SunSolidIcon}
          onSelect={setLightMode}
          rightSlot={themeName === Themes.LIGHT && <CheckIcon />}
        />
        <DropdownItem
          label="Dark"
          Icon={Moon01SolidIcon}
          onSelect={setDarkMode}
          rightSlot={themeName === Themes.DARK && <CheckIcon />}
        />
      </DropdownSubMenu>
      <DropdownItem label="Homepage" Icon={Home02Icon} href="/home" />
      <DropdownItem
        label="User settings"
        Icon={Settings01Icon}
        href="/settings"
        description={showUsername ? `Signed in as ${username}` : undefined}
      />
      <DropdownItem label="Get help" Icon={LifeBuoy01Icon} href="/support" />
      {isExpoAdmin && (
        <DropdownSubMenu label="Expo Staff" Icon={BadgeIcon} className="min-w-[130px]">
          <DropdownItem label="Feature gates" Icon={Flag01Icon} href="/feature-gates" />
          <DropdownItem
            label="Manage annotations"
            Icon={NotificationMessageIcon}
            href="/annotations"
          />
        </DropdownSubMenu>
      )}
      <DropdownItem
        label="Log out"
        Icon={LogOut03Icon}
        onSelect={onLogout}
        destructive
        data-testid="logout-button"
      />
    </>
  );
}
