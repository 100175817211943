import { useLocalStorage } from '~/providers/useLocalStorage';

/**
 * Only in development and only when user manually sets localStorage entry:
 * ```
 * localStorage.setItem("@expo/useLocalStorage/show-env-banner", "true");
 * ```
 * shows a tiny banner in the top right corner of the screen
 * indicating which API server is being used.
 */
export function EnvBanner() {
  const [showBanner, setShowBanner] = useLocalStorage({
    name: 'show-env-banner',
    defaultValue: false,
  });

  if (process.env.NODE_ENV !== 'development' || !showBanner) {
    return null;
  }

  let apiUrl = '';

  if (process.env.API_SERVER_INTERNAL_URL === 'https://api.expo.dev') {
    apiUrl = 'production';
  }

  if (process.env.API_SERVER_INTERNAL_URL === 'https://staging-api.expo.dev') {
    apiUrl = 'staging';
  }

  if (process.env.API_SERVER_INTERNAL_URL === 'http://localhost:3000') {
    apiUrl = 'localhost';
  }

  return (
    <div
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 3,
        color: 'white',
        cursor: 'default',
        display: 'flex',
        fontSize: 12,
        height: 18,
        padding: '0 0 0 4px',
        position: 'fixed',
        left: 2,
        top: 2,
        zIndex: 1000,
      }}>
      <span style={{ opacity: 0.6 }}>API: </span>
      {apiUrl}
      <div
        onClick={() => {
          setShowBanner(false);
        }}
        style={{
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius: 3,
          cursor: 'pointer',
          display: 'flex',
          fontSize: 12,
          height: 18,
          justifyContent: 'center',
          marginLeft: 4,
          width: 18,
        }}>
        ×
      </div>
    </div>
  );
}
