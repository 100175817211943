import { XIcon } from '@expo/styleguide-icons/outline/XIcon';
import { Title } from '@radix-ui/react-dialog';
import { ReactNode } from 'react';

import { DialogClose } from './DialogClose';
import { Button } from '../Button';
import { H3 } from '../text';

type Props = {
  title: string;
  icon?: ReactNode;
};

export function DialogTitle({ title, icon }: Props) {
  return (
    <div className="flex items-center justify-between gap-2.5 border-b border-b-default px-6 py-4">
      <div className="flex items-center gap-2.5">
        {icon ?? null}
        <Title asChild>
          <H3 weight="medium">{title}</H3>
        </Title>
      </div>
      <DialogClose>
        <Button
          theme="quaternary"
          leftSlot={<XIcon className="icon-lg" />}
          className="-mr-2 px-1.5"
        />
      </DialogClose>
    </div>
  );
}
