import { mergeClasses } from '@expo/styleguide';
import { AlertTriangleIcon } from '@expo/styleguide-icons/outline/AlertTriangleIcon';
import { CheckCircleIcon } from '@expo/styleguide-icons/outline/CheckCircleIcon';
import { HelpCircleIcon } from '@expo/styleguide-icons/outline/HelpCircleIcon';
import { InfoCircleIcon } from '@expo/styleguide-icons/outline/InfoCircleIcon';
import { XCircleIcon } from '@expo/styleguide-icons/outline/XCircleIcon';
import { XIcon } from '@expo/styleguide-icons/outline/XIcon';
import { ReactElement } from 'react';

import { ActivityIndicator } from '~/ui/components/ActivityIndicator';
import { Button } from '~/ui/components/Button';
import { P } from '~/ui/components/text';

export type FeedbackProps = {
  message?: string;
  customMessage?: ReactElement;
  onClose?: () => void;
  theme?: FeedbackTheme;
  className?: string;
};

export type FeedbackTheme = 'info' | 'warning' | 'danger' | 'help' | 'success' | 'loading';

export function Feedback({ message, customMessage, onClose, theme, className }: FeedbackProps) {
  return (
    <div
      className={mergeClasses(
        'dark-theme flex flex-row items-center justify-between rounded-md bg-overlay shadow-sm',
        className
      )}>
      <div className="flex flex-row">
        {theme && (
          <div className="flex items-center border-r border-r-default p-4">
            {themes[theme].icon}
          </div>
        )}
        <div className="flex p-4">
          {customMessage ? customMessage : <P className="pr-4 text-default">{message}</P>}
        </div>
      </div>
      {onClose && (
        <Button
          leftSlot={<XIcon className="icon-md" />}
          onClick={onClose}
          theme="quaternary"
          testID="feedback-close-button"
          className="m-2.5 flex"
        />
      )}
    </div>
  );
}

const themes = {
  info: {
    icon: <InfoCircleIcon className="icon-lg" />,
  },
  warning: {
    icon: <AlertTriangleIcon className="icon-lg text-icon-warning" />,
  },
  danger: {
    icon: <XCircleIcon className="icon-lg text-icon-danger" />,
  },
  help: {
    icon: <HelpCircleIcon className="icon-lg text-icon-info" />,
  },
  success: {
    icon: <CheckCircleIcon className="icon-lg text-icon-success" />,
  },
  loading: {
    icon: <ActivityIndicator size="lg" className="stroke-icon-default" />,
  },
};
