import { mergeClasses } from '@expo/styleguide';
import { spacing } from '@expo/styleguide-base';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { ChevronRightIcon } from '@expo/styleguide-icons/outline/ChevronRightIcon';
import { DotsVerticalIcon } from '@expo/styleguide-icons/outline/DotsVerticalIcon';
import { Menu01Icon } from '@expo/styleguide-icons/outline/Menu01Icon';
import { XIcon } from '@expo/styleguide-icons/outline/XIcon';
import { CommandMenuTrigger } from '@expo/styleguide-search-ui';
import { useRouter } from 'next/compat/router';
import { useState } from 'react';

import { formatParams } from '~/common/formatParams';
import { userHasPrioritySubscription } from '~/common/helpers';
import { useAppByFullNameQuery } from '~/graphql/queries/AppByFullName.generated';
import { useSearchDialogContext } from '~/providers/SearchDialogContext';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { Avatar } from '~/ui/components/Avatar';
import { Button } from '~/ui/components/Button';
import { DocsButton } from '~/ui/components/Navigation/LoggedInNavigation/DocsButton';
import { FeatureAnnouncement } from '~/ui/components/Navigation/LoggedInNavigation/FeatureAnnouncement';
import { UserMenuContent } from '~/ui/components/Navigation/LoggedInNavigation/UserMenuContent';
import { Logo } from '~/ui/components/Navigation/Logo';
import { MobileNavigation } from '~/ui/components/Navigation/MobileNavigation';
import { NotificationsList } from '~/ui/components/Navigation/Notification';
import { ProjectIcon } from '~/ui/components/ProjectIcon';
import { AdminUserAvatar } from '~/ui/components/SidebarNavigation/AdminUserAvatar';
import { UserAvatar } from '~/ui/components/SidebarNavigation/UserAvatar';
import { StaffTag } from '~/ui/components/Tag/StaffTag';
import { HEADLINE } from '~/ui/components/text';

import { getPageName, hasAccountSelector, isIndeterminateRoute, isPublicRoute } from './helpers';
import { Dropdown } from '../../Dropdown';
import { DropdownItem } from '../../Dropdown/DropdownItem';

type Props = {
  currentUser: LoggedInProps['currentUser'];
  currentDateAsString: string;
};

enum NavStates {
  IDLE,
  ACCOUNT,
  MORE,
  ORGANIZATION,
  MOBILE_MENU,
  PROJECT,
}

export function LoggedInNavigation({ currentUser, currentDateAsString }: Props) {
  const [navState, setNavState] = useState(NavStates.IDLE);
  const router = useRouter();
  const { setOpen } = useSearchDialogContext();
  const userIsSubscribed = userHasPrioritySubscription(currentUser);

  const { account: urlAccountName, projectName: projectSlug } = formatParams(router?.query);
  const account = urlAccountName ?? currentUser.username;

  function onDismiss(event?: Event) {
    event && setNavState(NavStates.IDLE);
  }

  function onMoreClick() {
    setNavState(NavStates.MORE);
  }

  function onAccountClick() {
    setNavState(navState === NavStates.ACCOUNT ? NavStates.IDLE : NavStates.ACCOUNT);
  }

  function onMobileMenuClick() {
    setNavState(navState === NavStates.MOBILE_MENU ? NavStates.IDLE : NavStates.MOBILE_MENU);
  }

  // note(tchayen): If sidebar item fallbacks to home and it's not `/`, and it's
  // not account page OR it's verify page (an exception).
  const showTopBarOnDesktop =
    (router?.pathname === '/settings/verify' ||
      router?.pathname === '/accounts/[account]/projects/[projectName]/job-runs/[jobRunId]' ||
      isPublicRoute(router) ||
      isIndeterminateRoute(router)) &&
    router?.pathname !== '/';

  const currentUserIsNotAMemberOfTargetAccount = !currentUser?.accounts?.find(
    (a) => a?.name === account
  );

  const isSuperuserMode = currentUser.isExpoAdmin;
  const skipAppByFullNameQuery =
    !projectSlug || (!isSuperuserMode && currentUserIsNotAMemberOfTargetAccount);

  const { data: projectData } = useAppByFullNameQuery({
    variables: { fullName: `@${account}/${projectSlug}` },
    skip: skipAppByFullNameQuery,
  });
  const selectedProject = projectData?.app.byFullName;

  const projectName = selectedProject?.name ?? projectSlug;
  const projectIcon = selectedProject?.icon?.url;

  return (
    <nav
      className={mergeClasses(
        'flex h-15 items-center justify-between gap-2 border-b border-b-secondary bg-default px-4',
        !showTopBarOnDesktop && 'hidden max-md-gutters:flex'
      )}>
      <div className="flex items-center gap-2">
        <div className="mr-2">
          <Logo href={account && !isIndeterminateRoute(router) ? `/accounts/${account}` : '/'} />
        </div>
        {hasAccountSelector(router) &&
          (isSuperuserMode ? (
            <AdminUserAvatar currentUser={currentUser} accountName={account} />
          ) : (
            <UserAvatar currentUser={currentUser} accounts={currentUser.accounts ?? []} />
          ))}
        {hasAccountSelector(router) && account && projectSlug && (
          <>
            <ChevronRightIcon className="icon-sm text-icon-secondary" />
            <ProjectIcon
              name={projectName}
              iconUrl={projectIcon}
              iconSize={spacing[6]}
              className="min-h-[24px] min-w-[24px]"
            />
          </>
        )}
        {getPageName(currentUser, router) && !projectSlug && (
          <>
            {hasAccountSelector(router) && <ChevronRightIcon className="text-icon-secondary" />}
            <HEADLINE variant="oneLine">{getPageName(currentUser, router, account)}</HEADLINE>
          </>
        )}
      </div>
      <div className="flex items-center gap-3 max-lg-gutters:hidden">
        <CommandMenuTrigger setOpen={setOpen} className="min-w-[226px]" />
        <DocsButton />
        <NotificationsList popoverClassName="!right-4 max-sm-gutters:!right-auto" />
        <Dropdown
          className="min-w-[180px]"
          sideOffset={-4}
          trigger={
            <Button
              onClick={onMoreClick}
              leftSlot={<DotsVerticalIcon title="Additional links" className="icon-md" />}
              aria-label="Additional links"
              theme="quaternary"
            />
          }>
          <DropdownItem
            label="Get started"
            href="https://docs.expo.dev"
            rightSlot={<ArrowUpRightIcon className="icon-sm text-icon-secondary" />}
            openInNewTab
          />
          <DropdownItem label="EAS" href="/eas" />
          <DropdownItem label="Tools" href="/tools" />
          <DropdownItem label="Orbit" href="/orbit" />
          <DropdownItem label="Pricing" href="/pricing" />
          <DropdownItem label="Changelog" href="/changelog" />
          {userIsSubscribed && (
            <FeatureAnnouncement
              title="New: Job board"
              description='Have an open position? Post on our job board with code "EXPOST232".'
              href="https://jobs.expo.dev"
              className="max-w-[220px]"
            />
          )}
        </Dropdown>
        <Dropdown
          collisionPadding={{ right: 8 }}
          sideOffset={-4}
          className="min-w-[180px]"
          trigger={
            <Button
              onClick={onAccountClick}
              className="relative w-9 justify-center rounded-full px-0"
              leftSlot={
                <>
                  <Avatar
                    name={currentUser?.firstName ? currentUser.firstName : currentUser.username}
                    profilePhoto={currentUser?.profilePhoto}
                    className="size-[30px] bg-element shadow-xs hocus:shadow-none"
                    alt="User menu"
                  />
                  {currentUser.isExpoAdmin && <StaffTag className="-bottom-1" />}
                </>
              }
              theme="quaternary"
            />
          }>
          <UserMenuContent
            username={currentUser.username}
            showUsername
            isExpoAdmin={currentUser.isExpoAdmin}
          />
        </Dropdown>
      </div>
      <div className="hidden items-center gap-2 max-lg-gutters:flex">
        <NotificationsList />
        <Button
          theme="quaternary"
          id="mobile-menu"
          aria-label={navState === NavStates.MOBILE_MENU ? 'Close menu' : 'Open menu'}
          onClick={onMobileMenuClick}
          leftSlot={
            navState === NavStates.MOBILE_MENU ? (
              <XIcon className="icon-md text-icon-default" />
            ) : (
              <Menu01Icon className="icon-md text-icon-default" />
            )
          }
        />
      </div>
      {navState === NavStates.MOBILE_MENU && (
        <MobileNavigation
          onDismiss={onDismiss}
          currentUser={currentUser}
          accountName={account || currentUser.username}
          currentDateAsString={currentDateAsString}
        />
      )}
    </nav>
  );
}
