// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProjectMutationVariables = Types.Exact<{
  appInput: Types.AppWithGithubRepositoryInput;
}>;


export type CreateProjectMutation = { __typename?: 'RootMutation', app?: { __typename?: 'AppMutation', createAppAndGithubRepository: { __typename?: 'CreateAppAndGithubRepositoryResponse', cloneUrl?: string | null, app: { __typename?: 'App', id: string, slug: string, githubRepository?: { __typename?: 'GitHubRepository', id: string } | null } } } | null };


export const CreateProjectMutationDocument = gql`
    mutation CreateProjectMutation($appInput: AppWithGithubRepositoryInput!) {
  app {
    createAppAndGithubRepository(appInput: $appInput) {
      app {
        id
        slug
        githubRepository {
          id
        }
      }
      cloneUrl
    }
  }
}
    `;
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectMutationDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;