import { ButtonBase, mergeClasses } from '@expo/styleguide';
import { ChevronDownIcon } from '@expo/styleguide-icons/outline/ChevronDownIcon';
import * as Popover from '@radix-ui/react-popover';
import { ReactNode, useRef } from 'react';

import { useNavigationBlurHandler } from '~/ui/components/Navigation/LoggedInNavigation/helpers';
import { FOOTNOTE } from '~/ui/components/text';

type Props = {
  label?: ReactNode;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  triggerContent: ReactNode;
  popoverContent: ReactNode;
  triggerId?: string;
  className?: string;
  rightSlot?: ReactNode;
  disabled?: boolean;
};

export function SidebarSelector({
  isVisible,
  setIsVisible,
  label,
  triggerContent,
  triggerId,
  className,
  popoverContent,
  disabled,
  rightSlot,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useNavigationBlurHandler(ref, () => {
    setIsVisible(false);
  });

  return (
    <div className={mergeClasses('relative flex flex-col gap-1', className)}>
      <div className="flex flex-1 items-center justify-between">
        {typeof label === 'string' ? <FOOTNOTE theme="secondary">{label}</FOOTNOTE> : label}
        {rightSlot}
      </div>
      <Popover.Root
        open={isVisible}
        onOpenChange={(open) => {
          setIsVisible(open);
        }}>
        <Popover.Trigger asChild>
          <ButtonBase
            disabled={disabled}
            id={triggerId}
            testID={triggerId}
            className={mergeClasses(
              'group flex h-[50px] w-full items-center rounded-[8px] border border-default px-3 shadow-xs',
              disabled && 'cursor-default opacity-60',
              !disabled && 'hover:bg-subtle'
            )}>
            <div className="flex h-full flex-1 items-center gap-2 truncate">{triggerContent}</div>
            <ChevronDownIcon className="icon-sm shrink-0 text-icon-secondary transition-transform duration-200 group-data-[state=open]:rotate-180" />
          </ButtonBase>
        </Popover.Trigger>
        <Popover.Content
          sideOffset={8}
          // z-[605] to be above the dialogs (601)
          className="z-[605] max-h-[calc(var(--radix-popper-available-height)-12px)] w-[var(--radix-popover-trigger-width)] overflow-y-auto rounded-md border border-secondary bg-default shadow-sm data-[state=closed]:animate-fadeOut data-[state=open]:animate-slideUpAndFade">
          {popoverContent}
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}
