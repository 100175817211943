import { developmentInfoLogs } from '~/common/constants';

/* eslint-disable no-console */

export function printDevMessages(isSuperuser = false) {
  if (process.env.NODE_ENV === 'development') {
    for (const log of developmentInfoLogs) {
      console.info('%c%s', 'color: white; background: #4630EB;', log);
    }
    window.confirm = function confirm(message?: string) {
      console.info(
        '%c%s',
        'color: white; background: #4630EB;',
        'Consider using `confirmAsync` from `~/ui/components/ConfirmationDialog` for a custom styled confirm dialog UI.'
      );

      return window.confirm(message);
    };
  }
  if (isSuperuser) {
    console.info('%c%s', 'color: white; background: #E5484D;', 'Viewing as a superuser');
  }
}
