import { ApolloClient } from '@apollo/client';

import { getAuthCredentials, getAuthStorageKey } from '~/common/authentication';
import Storage from '~/common/storage';

const storageKey = getAuthStorageKey();

const cookieStorage: Storage = new Storage(storageKey, 'cookie');
const authLocalStorage: Storage | undefined =
  typeof window !== 'undefined' ? new Storage(storageKey, 'localstorage') : undefined;

export async function removeBrowserDataAsync(options?: { client: ApolloClient<any> }) {
  await options?.client.clearStore();

  if (authLocalStorage) {
    authLocalStorage.removeItem('nonce');
    authLocalStorage.removeItem('state');
  }

  clearSessionSecretData();
}

export function getCurrentSessionSecret() {
  const authCredentials = getAuthCredentials();
  const sessionSecret = authCredentials?.sessionSecret;
  if (sessionSecret) {
    if (isSessionSecretValid(sessionSecret.expires_at)) {
      return JSON.stringify(sessionSecret);
    }
    clearSessionSecretData();
    return null;
  }
  return null;
}

function isSessionSecretValid(expiresAt: number) {
  return Date.now() < expiresAt;
}

export function saveSessionData(sessionSecret: string) {
  clearSessionSecretData();
  const { expires_at } = JSON.parse(sessionSecret);
  cookieStorage.setItem('sessionSecret', sessionSecret, {
    expires: new Date(expires_at),
  });
}

export function clearSessionSecretData() {
  cookieStorage.removeItem('sessionSecret');
}

// This must match the www definition
export enum ActorType {
  PERSON = 'person',
  ROBOT = 'robot',
  SSO_USER = 'sso_user',
}

export interface UserFields {
  id: string;
  username: string;
  email: string | null;
  created_at: Date;
  updated_at: Date;
  given_name: string | null;
  family_name: string | null;
  email_verified: boolean;
  extra_data: UserExtraData | null;
  primary_account_id: string;
  user_type: ActorType;
}

export interface UserExtraData {
  last_login?: any;
  last_password_reset?: any;
}
