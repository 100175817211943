import { useEffect, useState } from 'react';

const N_SECONDS = 45;

export function useRefetchOnRefocus(refetch: () => void) {
  const [lastFetchTime, setLastFetchTime] = useState<number>(Date.now());

  function handleFocus() {
    if (document.visibilityState === 'visible' && Date.now() - lastFetchTime > N_SECONDS * 1000) {
      refetch();
    }
    setLastFetchTime(Date.now());
  }

  useEffect(() => {
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [lastFetchTime]);
}
