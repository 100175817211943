export const SECOND_IN_MS = 1000;
export const MINUTE_IN_MS = SECOND_IN_MS * 60;
export const HOUR_IN_MS = MINUTE_IN_MS * 60;
export const DAY_IN_MS = HOUR_IN_MS * 24;
export const MONTH_IN_MS = DAY_IN_MS * 30;

export const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / HOUR_IN_MS);
  const minutes = Math.floor((duration % HOUR_IN_MS) / MINUTE_IN_MS);
  const seconds = Math.floor((duration % MINUTE_IN_MS) / SECOND_IN_MS);

  if (hours) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
};
