import { mergeClasses } from '@expo/styleguide';
import { motion } from 'framer-motion';

export default function PopoverFeedbackAnimatedBackground() {
  return (
    <motion.div variants={containerVariants} initial="hidden" animate="show">
      <motion.div
        variants={smallCircleVariants}
        className={mergeClasses(CIRCLE_CLASSES, getCircleClasses('sm'))}
        transition={transition}
      />
      <motion.div
        variants={mediumCircleVariants}
        className={mergeClasses(CIRCLE_CLASSES, getCircleClasses('md'))}
        transition={transition}
      />
      <motion.div
        variants={largeCircleVariants}
        className={mergeClasses(CIRCLE_CLASSES, getCircleClasses('lg'))}
        transition={transition}
      />
    </motion.div>
  );
}

function getCircleClasses(size: 'sm' | 'md' | 'lg') {
  switch (size) {
    case 'sm':
      return 'size-[200px] left-[calc(50%-100px)]';
    case 'md':
      return 'size-[400px] left-[calc(50%-200px)]';
    case 'lg':
      return 'size-[600px] left-[calc(50%-300px)]';
  }
}

const SMALL_CIRCLE_SIZE = 200;
const MEDIUM_CIRCLE_SIZE = 400;
const LARGE_CIRCLE_SIZE = 600;

const CIRCLE_CLASSES = 'absolute bg-palette-blue5 dark:bg-palette-gray8 rounded-full';

const containerVariants = {
  show: {
    transition: {
      staggerChildren: 0.15,
    },
  },
};

const transition = {
  type: 'spring',
  duration: 1,
  bounce: 0.3,
};

const smallCircleVariants = {
  hidden: {
    bottom: -(SMALL_CIRCLE_SIZE / 2 + 100),
    opacity: 0,
  },
  show: {
    bottom: -(SMALL_CIRCLE_SIZE / 2 + 40),
    opacity: 0.5,
  },
};

const mediumCircleVariants = {
  hidden: {
    bottom: -(MEDIUM_CIRCLE_SIZE / 2 + 120),
    opacity: 0,
  },
  show: {
    bottom: -(MEDIUM_CIRCLE_SIZE / 2 + 60),
    opacity: 0.25,
  },
};

const largeCircleVariants = {
  hidden: {
    bottom: -(LARGE_CIRCLE_SIZE / 2 + 160),
    opacity: 0,
  },
  show: {
    bottom: -(LARGE_CIRCLE_SIZE / 2 + 80),
    opacity: 0.1,
  },
};
