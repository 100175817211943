import { mergeClasses } from '@expo/styleguide';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  className?: string;
};

export function DialogFooter({ children, className }: Props) {
  return (
    <div
      className={mergeClasses(
        'flex items-center justify-end gap-3 border-t border-t-default bg-subtle px-3 py-4 max-md-gutters:py-2',
        className
      )}>
      {children}
    </div>
  );
}
