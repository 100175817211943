import { mergeClasses } from '@expo/styleguide';
import { ReactNode } from 'react';

import { FOOTNOTE } from '~/ui/components/text';

type Props = {
  title: ReactNode;
  className?: string;
};

export function PopoverSectionTitle({ title, className }: Props) {
  return (
    <div className={mergeClasses('px-3 pb-1 pt-2', className)}>
      {typeof title === 'string' ? (
        <FOOTNOTE theme="secondary" weight="medium" className="truncate">
          {title}
        </FOOTNOTE>
      ) : (
        title
      )}
    </div>
  );
}
