// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteNotificationsPaginatedQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type WebsiteNotificationsPaginatedQuery = { __typename?: 'RootQuery', meUserActor?: { __typename?: 'SSOUser', id: string, websiteNotificationsPaginated: { __typename?: 'WebsiteNotificationsConnection', edges: Array<{ __typename?: 'WebsiteNotificationEdge', node: { __typename?: 'Notification', id: string, event: Types.NotificationEvent, createdAt: any, isRead: boolean, accountName: string, websiteMessage: string } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } | { __typename?: 'User', id: string, websiteNotificationsPaginated: { __typename?: 'WebsiteNotificationsConnection', edges: Array<{ __typename?: 'WebsiteNotificationEdge', node: { __typename?: 'Notification', id: string, event: Types.NotificationEvent, createdAt: any, isRead: boolean, accountName: string, websiteMessage: string } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } | null };


export const WebsiteNotificationsPaginatedDocument = gql`
    query WebsiteNotificationsPaginated($after: String, $first: Int, $before: String, $last: Int) {
  meUserActor {
    id
    websiteNotificationsPaginated(
      after: $after
      first: $first
      before: $before
      last: $last
    ) {
      edges {
        node {
          id
          event
          createdAt
          isRead
          accountName
          websiteMessage
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
    `;
export function useWebsiteNotificationsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteNotificationsPaginatedQuery, WebsiteNotificationsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteNotificationsPaginatedQuery, WebsiteNotificationsPaginatedQueryVariables>(WebsiteNotificationsPaginatedDocument, options);
      }
export function useWebsiteNotificationsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteNotificationsPaginatedQuery, WebsiteNotificationsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteNotificationsPaginatedQuery, WebsiteNotificationsPaginatedQueryVariables>(WebsiteNotificationsPaginatedDocument, options);
        }
export function useWebsiteNotificationsPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebsiteNotificationsPaginatedQuery, WebsiteNotificationsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteNotificationsPaginatedQuery, WebsiteNotificationsPaginatedQueryVariables>(WebsiteNotificationsPaginatedDocument, options);
        }
export type WebsiteNotificationsPaginatedQueryHookResult = ReturnType<typeof useWebsiteNotificationsPaginatedQuery>;
export type WebsiteNotificationsPaginatedLazyQueryHookResult = ReturnType<typeof useWebsiteNotificationsPaginatedLazyQuery>;
export type WebsiteNotificationsPaginatedSuspenseQueryHookResult = ReturnType<typeof useWebsiteNotificationsPaginatedSuspenseQuery>;
export type WebsiteNotificationsPaginatedQueryResult = Apollo.QueryResult<WebsiteNotificationsPaginatedQuery, WebsiteNotificationsPaginatedQueryVariables>;
export function refetchWebsiteNotificationsPaginatedQuery(variables?: WebsiteNotificationsPaginatedQueryVariables) {
      return { query: WebsiteNotificationsPaginatedDocument, variables: variables }
    }