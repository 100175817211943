// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountSsoConfigurationPublicDataQueryVariables = Types.Exact<{
  accountName: Types.Scalars['String']['input'];
}>;


export type AccountSsoConfigurationPublicDataQuery = { __typename?: 'RootQuery', accountSSOConfigurationPublicData: { __typename?: 'AccountSSOConfigurationPublicDataQuery', publicDataByAccountName: { __typename?: 'AccountSSOConfigurationPublicData', id: string, authorizationUrl: string } } };


export const AccountSsoConfigurationPublicDataQueryDocument = gql`
    query AccountSSOConfigurationPublicDataQuery($accountName: String!) {
  accountSSOConfigurationPublicData {
    publicDataByAccountName(accountName: $accountName) {
      id
      authorizationUrl
    }
  }
}
    `;
export function useAccountSsoConfigurationPublicDataQuery(baseOptions: Apollo.QueryHookOptions<AccountSsoConfigurationPublicDataQuery, AccountSsoConfigurationPublicDataQueryVariables> & ({ variables: AccountSsoConfigurationPublicDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSsoConfigurationPublicDataQuery, AccountSsoConfigurationPublicDataQueryVariables>(AccountSsoConfigurationPublicDataQueryDocument, options);
      }
export function useAccountSsoConfigurationPublicDataQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSsoConfigurationPublicDataQuery, AccountSsoConfigurationPublicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSsoConfigurationPublicDataQuery, AccountSsoConfigurationPublicDataQueryVariables>(AccountSsoConfigurationPublicDataQueryDocument, options);
        }
export function useAccountSsoConfigurationPublicDataQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountSsoConfigurationPublicDataQuery, AccountSsoConfigurationPublicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountSsoConfigurationPublicDataQuery, AccountSsoConfigurationPublicDataQueryVariables>(AccountSsoConfigurationPublicDataQueryDocument, options);
        }
export type AccountSsoConfigurationPublicDataQueryHookResult = ReturnType<typeof useAccountSsoConfigurationPublicDataQuery>;
export type AccountSsoConfigurationPublicDataQueryLazyQueryHookResult = ReturnType<typeof useAccountSsoConfigurationPublicDataQueryLazyQuery>;
export type AccountSsoConfigurationPublicDataQuerySuspenseQueryHookResult = ReturnType<typeof useAccountSsoConfigurationPublicDataQuerySuspenseQuery>;
export type AccountSsoConfigurationPublicDataQueryQueryResult = Apollo.QueryResult<AccountSsoConfigurationPublicDataQuery, AccountSsoConfigurationPublicDataQueryVariables>;
export function refetchAccountSsoConfigurationPublicDataQuery(variables: AccountSsoConfigurationPublicDataQueryVariables) {
      return { query: AccountSsoConfigurationPublicDataQueryDocument, variables: variables }
    }